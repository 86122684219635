/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS208: Avoid top-level this
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
//= Action Cable provides the framework to deal with WebSockets in Rails.
//= You can generate new channels where WebSocket features live using the
//= `rails generate channel` command.

function importAll(r) { r.keys().forEach(r); }
importAll(require.context('./channels', true));

(function() {
  global.App || (global.App = {});
  return App.cable = ActionCable.createConsumer();
}).call(this);
