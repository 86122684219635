/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if (!$('.listing-page').length) { return; }

  $('.nav-tabs a').on('click', function(e) {
    e.preventDefault();
    return $('html, body').animate({ scrollTop: $(window).scrollTop() }, 350);
  });

  return new ButtonSwitchEvents($('.listing-page'), 'favorite');
});
