/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  $('#signin, #signup-email, #frgtpass, #invitation, #confmodal').on('shown.bs.modal', function() {
    return auth.popUp($(this));
  });

  const $signUpLink = $('#signup .sign-by-socials a, #signup .sign-by-email a');
  $signUpLink.on('click', function(event) {
    const isChecked = $('#signup input#terms_aggree').is(":checked");
    const withParam = $(this).attr('href') + `&checked=${isChecked}`;
    return $(this).attr('href', withParam);
  });

  $signUpLink.on('ajax:success', function(event, response) {
    if (response['redirect_to']) {
      const form = $(`<form action="${response['redirect_to']}" method="POST">
                        <input name="authenticity_token" value="${response['authenticity_token']}">
                      </form>`).hide()
      return form.appendTo($(document.body)).submit();
    } else {
      $('.modal:visible').modal('hide');
      return $(response['toggle']).modal('toggle');
    }
  });

  $signUpLink.on('ajax:error', (_event, response) => toastr.warning(response.responseJSON.error_message));

  const $conversationModal = $('.conversation-modal');
  return $conversationModal.find('.conversation-new-message-form input[type=submit]').on('keypress', function(e) {
    if (e.which !== 13) { return; }

    e.preventDefault();
    e.stopPropagation();
    return $conversationModal.find('form').submit();
  });
});
