/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const sumPrices = function() {
    let sum = 0;
    $('.features-list .js-price').each(function() {
      return sum += Number($(this).text());
    });
    const currencySymbol = $('.js-sum').data('symbol');
    return $('.js-sum').text(currencySymbol + sum);
  };

  if ($('.payment-summary').length) {
    sumPrices();

    $('.f-remove-btn').on('click', function() {
      const featureContainer = $(this).parent();
      return $.ajax({
        type: 'PUT',
        url: $(this).data('resetUrl'),
        dataType: 'html',
        data: {
          removed_slot: featureContainer.find('input').attr('id')
        },
        success() {
          featureContainer.remove();
          return sumPrices();
        }
      });
  });
  }
});
