/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('#ts-spaces').length > 0) {
    const spacesFilterForm = new FilterForm($('#ts-spaces'), 'spaces');
    spacesFilterForm.build();
  }

  if ($('#list-spaces').length > 0) {
    const listingsFilterForm = new FilterForm($('#list-spaces'), 'listings');
    listingsFilterForm.build();
    const itemRenderer = new ItemRenderer($('#list-spaces'));
  }

  if ($('#fav-spaces').length > 0) {
    const favoritesFilterForm = new FilterForm($('#fav-spaces'), 'favorites');
    favoritesFilterForm.build();
  }

  if ($('#spaces-wrapper').length > 0) {
    return $('.dropdown.reviews').find('.dropdown-menu').on('click', function() {
      return $(this).parent().find('.dropdown-toggle').dropdown('toggle');
    });
  }
});
