/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const modalFilter = new Filter('modal');
  const pocketFilter = new Filter('pocket');
  
  /* Closing active modal when openig new one */
  $(document).on('show.bs.modal', '.filter-modal', function() {
    $('.filter-modal').modal('hide');
    return $(this).siblings('.filter-button').addClass('highlight');
  });

  $(document).on('hide.bs.modal', '.filter-modal', function() {
    return $(this).siblings('.filter-button').removeClass('highlight');
  });

  return $(document).on('click', function(e) {
    if (!($(e.target).hasClass('filter-button') ||
    $(e.target).closest('button').hasClass('filter-button') ||
    $(e.target).closest('.modal').hasClass('in')) &&
    $(this).find('.filter-modal').hasClass('in')) {
      return $('.filter-modal').modal('hide');
    }
  });
});
