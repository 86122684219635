/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const reviewsModalEvents = function($reviewsModal) {
  const $reviewsForm = $reviewsModal.find('#reviews-search-form');
  $reviewsModal.find('.filter-button').click(() => $reviewsModal.find('.pocket').pocket('hide'));
  $reviewsModal.find('.apply-changes').click(function() {
    return $(this).closest('.pocket').pocket('hide');
  });
  return $reviewsModal.find('form').submit();
};

$(document).on('turbolinks:load', function() {
  const $container = $('.traderspace-page');
  if (!$container.length) { return; }

  const $reviewsModal = $('.traderspace-page .reviews-modal');
  $reviewsModal.on('shown.bs.modal', function() {
    return reviewsModalEvents($(this));
  });
  $reviewsModal.on('hide.bs.modal', () => $reviewsModal.find('.results-wrapper').html(''));
  
  $('.pocket').on('show.bs.pocket', e => window.shown = true);
  
  $('.pocket').on('focus', function(e) {
    if (window.shown) {
      window.shown= false;
    } else {
      $(this).pocket('hide');
    }
    e.preventDefault();
    return $reviewsModal.scrollTop(0);
  });

  $reviewsModal.find('a.star').on('click', function(e) {
    e.preventDefault();
    $reviewsModal.find('.reviews-stars li').removeClass('clicked');
    $(this).closest('li').addClass('clicked');
    $(this).closest('.reviews-stars').siblings('#stars-field').val($(this).data('starsNumber'));
    return $(this).closest('#reviews-search-form').submit();
  });

  $container.find('.about-owner .reviews-stars li').on('click', function() {
    const stars = $(this).find('.star').data('starsNumber');
    const $userReviewModal = $container.find('#user-reviews-modal');
    $userReviewModal.find('.reviews-stars li').removeClass('clicked');
    $userReviewModal.find('#stars-field').val(stars);
    $userReviewModal
      .find(`a.star[data-stars-number='${stars}']`)
      .closest('li')
      .addClass('clicked');
    return $userReviewModal.modal('show');
  });

  const ajaxSuccessSelector = '.reviews-modal #reviews-search-form, .reviews-modal .pagination a';
  $(document).on('ajax:success', ajaxSuccessSelector, (e, response) => $reviewsModal.find('.results-wrapper').html(response.results));

  return $(document).on('click', '.reviews-modal #reviews-markets-pocket .options .option input', function(e) {
    if (!$(this).closest('.options').find('input:checked').length) { return e.preventDefault(); }
  });
});
