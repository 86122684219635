/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let DEFAULT_ZOOM = undefined;
  const Cls = (window.MapCreator = class MapCreator {
    static initClass() {
      DEFAULT_ZOOM = 15;
    }

    constructor($mapContainer) {
      this.mapContainer = $mapContainer;
      this.markers = [];
      this.clusters = [];
      this.findRecordUrl = this.mapContainer.data('findRecordUrl');
      this.viewPath = this.mapContainer.data('desktopPath');
    }

    buildSearchMap() {
      this.bounds = new (google.maps.LatLngBounds);
      this.map = new (google.maps.Map)(this.mapContainer.get(0));
      const styles = {
        hide: [{
          featureType: 'poi',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        },
        {
          featureType: 'transit',
          elementType: 'labels',
          stylers: [{ visibility: 'off' }]
        }]
      };
      return this.map.setOptions({
        minZoom: 1,
        gestureHandling: 'greedy',
        scrollwheel: false,
        disableDefaultUI: true,
        zoomControl: true,
        styles: styles['hide']
      });
    }
      // $('.map-section').on 'mouseenter', ->
      //   $(this).on 'wheel', ->
      //     $('.map-section').find('.map-scroll-info-overlay')
      //                      .addClass('map-scroll-info-overlay-visible')
      //     setTimeout(() ->
      //       $('.map-section').find('.map-scroll-info-overlay')
      //                        .removeClass('map-scroll-info-overlay-visible')
      //     , 2500)

    buildAreaMap(address, wizard) {
      let area;
      if (wizard == null) { wizard = false; }
      const bounds = new (google.maps.LatLngBounds);
      const map = new (google.maps.Map)(this.mapContainer.get(0));
      map.setOptions({
        zoom: DEFAULT_ZOOM,
        disableDefaultUI: true,
        zoomControl: true
      });
      if (wizard) {
        return area = new WizardArea(address, map, bounds, this.mapContainer);
      } else {
        return area = new Area(address, map, bounds, this.mapContainer);
      }
    }

    fitBounds(bounds, zoom) {
      this.bounds = bounds;
      this.map.fitBounds(bounds);
      return this.map.setOptions({
        zoom
      });
    }

    onMovement(callback) {
      const {
        map
      } = this;
      const that = this;
      return ['center_changed', 'zoom_changed'].forEach(val => map.addListener(val, function() {
        if (map.isMoving) { return; }
        return that.movementAnalyzer(callback);
      }));
    }

    movementAnalyzer = function(callback) {
      const {
        map
      } = this;
      const that = this;
      map.isMoving = true;
      const boundsAtBegining = map.getBounds();

      return setTimeout((function() {
        if (boundsAtBegining && boundsAtBegining.equals(map.getBounds())) {
          return callback(map.getBounds(), () => map.isMoving = false);
        } else {
          return that.movementAnalyzer(callback);
        }
      }), 200);
    };

    setMarkers(elements) {
      const mapCreator = this;
      $.each(this.markers, function() {
        return this.remove();
      });
      this.markers = [];
      $.each(elements, function() {
        return mapCreator.markers.push(mapCreator.buildMarker(this));
      });
      $.each(this.clusters, function() {
        return this.clearMarkers();
      });
      return this.initMarkerClustering();
    }

    buildMarker(element) {
      const marker = new Marker(element, this);
      return marker;
    }

    setMapOptions(options) {
      return this.map.setOptions(options);
    }

    initMarkerClustering() {
      const markerCluster = new MarkerClusterer(this.map, this.markers,
        { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m', gridSize: 100 }
      );
      return this.clusters.push(markerCluster);
    }

    radiusToZoom(radius) {
      return Math.round(14 - (Math.log(radius) / Math.LN2));
    }

    fitMapResults(mapResults) {

      const bounds = new google.maps.LatLngBounds();
      _.each(mapResults, function(result) {
        const latLng = new google.maps.LatLng(result.lat, result.lng);
        return bounds.extend(latLng);
      });
      this.map.fitBounds(bounds);
      google.maps.event.trigger(this.map, 'resize');
      if (this.map.getZoom() > DEFAULT_ZOOM) {
        return this.map.setZoom(DEFAULT_ZOOM);
      }
    }
  });
  Cls.initClass();
  return Cls;
})();
