/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.ParallelInfiniteScroll = class ParallelInfiniteScroll extends window.InfiniteScroll {
  constructor($container, $formContainer, traderspace) {
    if (traderspace == null) { traderspace = false; }
    this.$container = $container;
    if (traderspace) {
      this.$elementsContainer = this.$container.find('.listings-inner-wrapper');
      this.fetchUrl = this.$container.parent().parent().data('fetchUrl');
    } else {
      this.$elementsContainer = this.$container.find('.results-wrapper');
      this.fetchUrl = this.$container.find('[data-fetch-url]').data('fetchUrl');
      this.$container = this.$elementsContainer;
    }
    if ($formContainer) { this.$form = $formContainer.find('form'); }
    this.init();
  }

  init() {
    const that = this;
    return this.$container.on('scroll', function() {
      if (this.scrollLeft > ((this.scrollWidth - this.clientWidth) * 0.7)) {
        if (!that.$container.hasClass('wait') && (that.$container.find('.load-more').length > 0)) {
          that.$container.addClass('wait');
          return that.loadNextPage();
        }
      }
    });
  }

  initListingsOwlCarousels() {
    const left = this.$elementsContainer.find('.images-carousel').data('left');
    const right = this.$elementsContainer.find('.images-carousel').data('right');

    this.$elementsContainer
      .find('.images-carousel:not(.js-without-images-carousel) .images')
      .owlCarousel({
        loop: false,
        nav: true,
        items: 1,
        navText: [left, right]
      });
    return this.$elementsContainer.find('.images-carousel.js-without-images-carousel .images').owlCarousel({
      loop: false,
      nav: false,
      dots: false
    });
  }
};