/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('.step-2 .step-listing-type').length) {
    let lookingType, typeId;
    const $container = $('.step-2');
    const $exchangeTypeInput = $container.find('#item_base_exchange_type_id');
    const $lookingTypeInput = $container.find('#item_base_looking_type');

    const $exchangeTypes = $container.find('.listing-type');
    $exchangeTypes.find("input[name='exchange-type']").on('click', event => $exchangeTypeInput.val(event.target.value));

    if (typeId = $exchangeTypeInput.val()) {
      $container.find(`input[value=${typeId}]`).prop('checked', true);
    }

    if (lookingType = $lookingTypeInput.val()) {
      $container.find(`input[value=${lookingType}]`).prop('checked', true);
    }

    const $lookingTypesInputs = $container.find("input[name='looking-type']");
    $exchangeTypes.not('.looking').find("input[name='exchange-type']").on('click', function() {
      $lookingTypeInput.val(null);
      return $lookingTypesInputs.prop('checked', false);
    });

    return $lookingTypesInputs.on('click', function(event) {
      $exchangeTypes.find().prop('checked', false);
      const $lookingInput = $lookingTypesInputs.closest('.looking').find("input[name='exchange-type']");
      $lookingInput.prop('checked', true);
      $exchangeTypeInput.val($lookingInput.val());
      return $lookingTypeInput.val(event.target.value);
    });
  }
});
