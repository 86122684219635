/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if (!$('#trader-space').length) { return; }
  $(document).on('ajax:success', '.new_conversation', function(event, data) {
    const content = $(this.dataset.successModal)
              .filter('.modal-body')[0].outerHTML;
    return $(this).closest('.message').html(content).addClass('sent');
  });

  return $(document).on('ajax:error', '.new_conversation', function(event, jqXHR) {
    return $(this).closest('.message').html(jqXHR.responseJSON.html);
  });
});
