/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  let favorite;
  if (!$('.home').length) { return; }

  const leftArrow = $('.promoted-items').data('left');
  const rightArrow = $('.promoted-items').data('right');

  const options = {
    items: 3,
    loop: false,
    dots: true,
    nav: true,
    navText: [leftArrow, rightArrow],
    slideTransition: 'ease',
    checkVisible: false
  };

  $('.results').each(function() {
    const $this = $(this);
    if ($this.children().length > 3) {
      return $this.owlCarousel(options);
    } else {
      const newOptions = _.merge(options, { dots: false, nav: false });
      return $this.owlCarousel(newOptions);
    }
  });

  $('.for-buyers a').on('click', function(e) {
    e.preventDefault();
    return $('#search').click();
  });

  return favorite = new ButtonSwitchEvents($('.home').find('.favorite'), 'favorite');
});
