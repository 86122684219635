/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let paginationEvents = undefined;
  let progressBar = undefined;
  let carouselEvents = undefined;
  let initButtonSwitchEvents = undefined;
  const Cls = (window.FilterForm = class FilterForm {
    static initClass() {
  
      paginationEvents = ($container, $elements, $list, mainClass, filterForm) => $elements.on('ajax:success', `#${mainClass}-pagination`, function(event, data) {
        const $partial = data.html ? $(data.html) : $(data);
        $elements.find('.load-button, .load-more').remove();
        $elements.append($partial);
        $partial.each(function() {
          return new MakeFeaturedButton($(this).find('.featured'));
        });
        if (mainClass === 'spaces') {
          carouselEvents($container);
          progressBar($container);
        }
        if (mainClass === 'conversations') {
          const convActionForm = new ConversationsActionsForm($container, filterForm);
          progressBar($container);
        }
        initButtonSwitchEvents($container, mainClass);
        if (filterForm.parallelSection.horizontalScroll) { return filterForm.parallelSection.horizontalScroll.recalculateScrollbar(); }
      });
  
      progressBar = function($container) {
        let progress;
        return progress = new ProgressBar($container);
      };
  
      carouselEvents = function($container) {
        if ($(window).width() <= 1690) {
          $container.find('.badges ul').addClass('owl-carousel');
          const owl2 = $container.find('.owl-carousel');
          owl2.owlCarousel({
            loop: true,
            margin: 20,
            nav: true,
            dots: false,
            responsive: {
              1201: { items: 5
            },
              1026: { items: 3
            }
            }
          });
          $container.find('.badges .owl-prev').unbind('click');
          $container.find('.badges .owl-prev').on('click', function() {
            return $(this).siblings(owl2).trigger('prev.owl.carousel');
          });
          $container.find('.badges .owl-next').unbind('click');
          return $container.find('.badges .owl-next').on('click', function() {
            return $(this).siblings(owl2).trigger('next.owl.carousel');
          });
        }
      };
  
      initButtonSwitchEvents = function($container, mainClass) {
        if (mainClass === 'reviews') {
          return _.each($container.find('.love'), function(love_button) {
            let likeEvents;
            return likeEvents = new ButtonSwitchEvents($(love_button), 'like');
          });
        }
      };
    }
    constructor($container, mainClass) {
      this.$container = $container;
      this.mainClass = mainClass;
      this.$searchForm = $container.find(`#${mainClass}-search-form`);
      this.$reviewsDropdown = $container.find(".dropdown.reviews");
      this.$recentDropdown = $container.find(".dropdown.most-recent");
      this.$communitiesDropdown = $container.find('.dropdown.communities');
      this.$categoryDropdown = $container.find('.dropdown.category');
      this.$typesDropdown = $container.find('.dropdown.type');
      this.$spacesDropdown = $container.find('.dropdown.trader-spaces');
      this.$tsStatusDropdown = $container.find('.dropdown.trade-status');
      this.$listingDropdown = $container.find('.dropdown.listing');
      this.$inboxDropdown = $container.find('.dropdown.inbox');
      this.filterDropDowns = [this.$communitiesDropdown, this.$categoryDropdown, this.$categoryDropdown,
                          this.$typesDropdown, this.$spacesDropdown, this.$tsStatusDropdown,
                          this.$listingDropdown, this.$inboxDropdown];
      this.dropDowns = $.merge(this.filterDropDowns, [this.$reviewsDropdown, this.$recentDropdown]);
      this.$starsLinks = $container.find('a.star');
      this.$elements = $container.find('.elements');
      this.$list = $container.find(`.${mainClass}-list`);
      this.$pagination = $container.find(`.${mainClass}-pagination`);
      this.parallelSection = new ParallelSection(this.$container.find('.master-container'));
    }

    build() {
      this.parallelSection.init();
      const filterForm = this;
      _.each(this.dropDowns, dropDown => filterForm.buildDropdown(dropDown));
      paginationEvents(this.$container, this.$elements, this.$list, this.mainClass, this);
      this.filterEvents(this.$container, this.$searchForm, this.$elements, this.mainClass);
      if (this.mainClass === 'spaces') {
        carouselEvents(this.$container);
      }
      if ((this.mainClass === 'spaces') || (this.mainClass === 'conversations') || (this.mainClass === 'achievements')) {
        progressBar(this.$container);
      }
      if (this.$container.find('.load-more').length > 0) {
        this.parallelSection.$loadMore =
          $('.traderspace-page .listings').find('.load-more');
        return this.parallelSection.fetchElements();
      } else {
        if (this.parallelSection.horizontalScroll) { return this.parallelSection.horizontalScroll.recalculateScrollbar(); }
      }
    }

    buildDropdown(dropdown) {
      if (!(dropdown.length > 0)) { return; }
      dropdown = new InputDropDown(dropdown);
      return dropdown.run();
    }

    filterEvents($container, $searchForm, $elements, mainClass) {
      const filterForm = this;
      $searchForm.on('ajax:success', function(event, data) {
        if (event.target.id === `${filterForm.mainClass}-pagination`) { return; }
        const $partial = data.html ? $(data.html) : $(data);
        $elements.html($partial);
        $partial.each(function() {
          return new MakeFeaturedButton($(this).find('.featured'));
        });
        if (mainClass === 'spaces') {
          carouselEvents($container);
        }
        if ((mainClass === 'spaces') || (mainClass === 'conversations')) {
          progressBar($container);
        }
        if (mainClass === 'conversations') {
          const convActionForm = new ConversationsActionsForm($container, filterForm);
        }
        initButtonSwitchEvents($container, mainClass);
        if (filterForm.parallelSection.horizontalScroll) { return filterForm.parallelSection.horizontalScroll.recalculateScrollbar(); }
      });

      _.each(this.filterDropDowns, function(filterDropDown) {
        if (filterDropDown.length > 0) {
          return filterDropDown.find('input').on('change', event => $searchForm.submit());
        }
      });

      if (this.$recentDropdown.length > 0) {
        this.$recentDropdown.find('a').on('ajax:success', (event, html) => $elements.html(html));
      }

      if (this.$reviewsDropdown.length > 0) {
        this.$reviewsDropdown.find('a').on('ajax:success', function(event, html) {
          $elements.html(html);
          if (mainClass === 'spaces') {
            carouselEvents($container);
          }
          if ((this.mainClass === 'spaces') || (this.mainClass === 'conversations')) {
            return progressBar($container);
          }
        });
      }

      if (this.$starsLinks.length > 0) {
        if (mainClass === 'achievements') { return; }
        return this.$starsLinks.on('click', function(e) {
          e.preventDefault();
          $container.find('#stars-field').val($(this).data('starsNumber'));
          return $searchForm.submit();
        });
      }
    }
  });
  Cls.initClass();
  return Cls;
})();
