/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let POSSIBLE_ACTIONS = undefined;
  let updateInboxNumbers = undefined;
  let displayMessage = undefined;
  const Cls = (window.ConversationsActionsForm = class ConversationsActionsForm {
    static initClass() {
      POSSIBLE_ACTIONS = ['.eye', '.exclamation', '.delete', '.cross-exclamation'];
  
      updateInboxNumbers = function($inboxDropdown, $elements) {
        const data = $elements.find('span.inbox-values').data();
        if (!data) { return; }
        $inboxDropdown.find('.drafts-value').text(data.draftsValue);
        return $inboxDropdown.find('.inbox-value').text(data.inboxValue);
      };
  
      displayMessage = function(data) {
        if (data.error) {
          return toastr.error(data.error);
        } else if (data.success) {
          return toastr.success(data.success);
        } else if (data.notice) {
          return toastr.warning(data.notice);
        }
      };
    }
    constructor($container, filterForm) {
      this.$container = $container;
      this.$actionButtons = this.$container.find(POSSIBLE_ACTIONS.join(', '));
      this.$actionForm = this.$container.find('#conversations-form');
      this.$elements = this.$container.find('.elements');
      this.$showLinks = this.$container.find('.message-content');
      this.$actionCheckboxes = this.$container.find('input:checkbox');
      this.$newConversationLink = this.$container.find('.create-message');
      this.refreshHandler = new Refresh($container);
      this.filterForm = filterForm;
      if (!this.filterForm) {
        this.filterForm = new FilterForm(this.$container, 'conversations', { conversationForm: this });
        this.filterForm.build();
      }
      this.init();
    }

    init() {
      this.disableOnTrash();
      this.submitEvents();
      this.partialsEvents();
      return this.controlEvents();
    }

    controlEvents() {
      const convActionForm = this;
      return this.$newConversationLink.on('ajax:success', function(event, html) {
        let newConversation;
        convActionForm.refreshHandler.stopAutomaticRefresh();
        convActionForm.$container.html(html);
        return newConversation = new NewConversation(convActionForm.$container);
      });
    }

    submitEvents() {
      const convActionForm = this;
      this.$actionCheckboxes.off('change').on('change', function(event) {
        const important =
          convActionForm.$container.find("input:checkbox:checked[data-important='false']");
        const notimportant =
          convActionForm.$container.find("input:checkbox:checked[data-important='true']");
        if ((important.length > 0) && (notimportant.length === 0)) {
          $('.exclamation').removeClass('invisible');
          return $('.cross-exclamation').addClass('invisible');
        } else if ((important.length === 0) && (notimportant.length > 0)) {
          $('.exclamation').addClass('invisible');
          return $('.cross-exclamation').removeClass('invisible');
        } else {
          $('.exclamation').removeClass('invisible');
          return $('.cross-exclamation').addClass('invisible');
        }
      });
      return this.$actionButtons.off('click').on('click', function(e) {
        e.preventDefault();
        return $.ajax({
          type: 'PUT',
          url: $(this).data('url'),
          data: JSON.stringify(convActionForm.filterForm.$searchForm.serialize()),
          success(data) {
            if ($(e.target).attr('id') === 'conversations-pagination') { return; }
            const partial = data.html ? data.html : data;
            displayMessage(data);
            convActionForm.$elements.html(partial);
            updateInboxNumbers(convActionForm.filterForm.$inboxDropdown,
                               convActionForm.$elements);
            const progress = new ProgressBar(convActionForm.$container);
            convActionForm.$showLinks =
                convActionForm.$container.find('.message-content');
            convActionForm.partialsEvents();
            convActionForm.$actionCheckboxes =
              convActionForm.$container.find('input:checkbox');
            $('.exclamation').removeClass('invisible');
            $('.cross-exclamation').addClass('invisible');
            return convActionForm.submitEvents();
          }
        });
      });
    }

    partialsEvents() {
      const convActionForm = this;
      return this.$showLinks.on('ajax:success', function(event, data) {
        let tradeActions;
        const partial = data.html ? data.html : data;
        convActionForm.$container.html(partial);
        convActionForm.refreshHandler.stopAutomaticRefresh();
        return tradeActions = new TradeActions(convActionForm.$container);
      });
    }

    disableOnTrash() {
      if (this.filterForm.$inboxDropdown.find('input').val() === 'destroyed') {
        this.$container.find('.section-two.filters').addClass('destroyed');
        return this.$actionCheckboxes.attr('disabled', 'disabled');
      } else {
        this.$container.find('.section-two.filters').removeClass('destroyed');
        return this.$actionCheckboxes.attr('disabled', null);
      }
    }
  });
  Cls.initClass();
  return Cls;
})();
