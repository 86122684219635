/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.UriManipulator = class UriManipulator {
  constructor(uri) {
    this.removeParameter = this.removeParameter.bind(this);
    this.uri = uri;
  }

  removeParameter(parameter) {
    let url;
    const urlParts = this.uri.split('?');
    if (!(urlParts.length >= 2)) { return this.uri; }

    const urlBase = urlParts.shift();
    const queryString = urlParts.join('?');
    const prefix = encodeURIComponent(parameter) + '=';
    const parts = queryString.split(/[&;]/g);
    let i = parts.length;
    while (i-- > 0) {
      if (parts[i].lastIndexOf(prefix, 0) !== -1) {
        parts.splice(i, 1);
      }
    }
    return url = urlBase + '?' + parts.join('&');
  }
};