/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(window).on('turbolinks:load', function() {
  if ($('#settings').length > 0) {
    /* setting up height of dropdown zone */

  //  if $('.email-phone-location').length
      // height = $('.email-phone-location').height()
      // $('.picture-upload').css
      //   'height': height
      //   'width': height * 1.147
      // $('.password .form-group:first-child').css 'width': $('.picture-upload').width() + 6
      // $('.password #confirm-password').css 'width': $('.email-phone-location form').width() - 4.5

    /* setting privacy buttons */

    let locationAutocompleter;
    $('.privacy-settings .switch-public .slider').on('mousedown', function(event) {
      if (event.which === 1) {
        $('.privacy-settings .switch-private .slider').trigger('click');
        return false;
      }
    });
    $('.privacy-settings .switch-private .slider').on('mousedown', function(event) {
      if (event.which === 1) {
        $('.privacy-settings .switch-public .slider').trigger('click');
        return false;
      }
    });

    if ($('#user-settings').length > 0) {
      const userSettingsUploader = new FormImageUploader($('#user-settings'));
    }

    $('#invitation-form-email, #invitation-form-sms').on('ajax:success', function(event, data) {
      toastr.success(data.message);
      return $(this).find('input').val('');
    });

    $('#invitation-form-email, #invitation-form-sms').on('ajax:error', (event, data) => toastr.error(data.responseJSON.message));

    if ($('#sett-application').length > 0) {
      $('#sett-application').find('.save-button').on('click', () => $('#sett-application').find('#user-settings').submit());
    }

    /* MOBILE NUMBER SIZE LIMIT */
    const limitations =
      new InputLimitations($('#application-settings .email-phone-location #tel, #referrals-settings .sms #mobile_number'), true);

    /* LOCATION AUTOCOMPLETER */
    return locationAutocompleter =
      new GoogleLocationAutocompleter($('.user_location #location'));
  }
});
