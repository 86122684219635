/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.WizardCategoriesInputDropDown = class WizardCategoriesInputDropDown extends window.CategoriesInputDropDown {
  constructor($dropdown) {
    super($dropdown);
  }

  changeListener() {
    const dropdown = this;
    return this.$options.on('click', 'span', function(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
      dropdown.setValue($(this).closest('li').data('value'));
      dropdown.$dropdown.removeClass('open');
    });
  }

  setValue(value) {
    let text;
    const $element = this.$options.find('li[data-value="' + value + '"]');
    if ($element.hasClass('main')) {
      text = $element.find('.category').text();
    } else {
      const mainCategory = $element.closest('.main').find('.category').text();
      text = `${mainCategory} - ${$element.text()}`;
    }
    if (this.$input.length > 0) { this.$input.val(value).trigger('change'); }
    this.$button.find('.placeholder').text(text);
    return this.$button.parent().attr('title', text);
  }
};
