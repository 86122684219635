/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let DEFAULT_ZOOM = undefined;
  const Cls = (window.NavigationSearchForm = class NavigationSearchForm {
    static initClass() {
      DEFAULT_ZOOM = 10;
    }

    constructor($navForm) {
      this.$navForm = $navForm;
      this.$radiusDropdown = this.$navForm.find('.radius-dropdown');
      this.$categoriesDropdown = this.$navForm.find('.categories-dropdown');
      this.init();
    }

    init() {
      let locationAutocompleter;
      this.initButtonEvents();
      this.initAutocompletion();
      this.autocompleteHandler();
      const radiusDropdown = new InputDropDown(this.$radiusDropdown);
      radiusDropdown.run();
      const categories = new CategoriesInputDropDown(this.$categoriesDropdown);
      categories.run();
      this.categoryDropdownSubmit();
      return locationAutocompleter =
        new GoogleLocationAutocompleter(this.$navForm.find('#location'));
    }

    geocodeLocation(form, address, zoom, radius = null, stopSearchOnMove) {
      if (zoom == null) { zoom = DEFAULT_ZOOM; }
      if (stopSearchOnMove == null) { stopSearchOnMove = false; }
      const searchForm = this;
      const geocoder = new google.maps.Geocoder();
      return new Promise((resolve, reject) => geocoder.geocode({ 'address': address }, function(results, status) {
        let northEastLat, northEastLng, southWestLat, southWestLng;
        if (status === google.maps.GeocoderStatus.OK) {
          searchForm.map.fitBounds(results[0].geometry.viewport, searchForm.setZoomFromRadius(zoom, radius));
          const bounds = searchForm.map.map.getBounds();
          southWestLat = bounds.getSouthWest().lat();
          southWestLng = bounds.getSouthWest().lng();
          northEastLat = bounds.getNorthEast().lat();
          northEastLng = bounds.getNorthEast().lng();
          searchForm.searchWithLocation = true;
        } else {
          southWestLat = (southWestLng = (northEastLat = (northEastLng = null)));
          searchForm.searchWithLocation = false;
        }
        return resolve(searchForm.setNewSearchBounds(form, southWestLat, southWestLng, northEastLat, northEastLng));
    }));
    }

    setNewSearchBounds($form, southWestLat = null, southWestLng = null, northEastLat = null, northEastLng = null) {
      $form.find('#south-west-lat').val(southWestLat);
      $form.find('#south-west-lng').val(southWestLng);
      $form.find('#north-east-lat').val(northEastLat);
      return $form.find('#north-east-lng').val(northEastLng);
    }

    initButtonEvents() {
      const form = this.$navForm;
      form.find('.js-nav-form-traderspace-option').click(function() {
        form.find('#search_form_traderspaces').val(true);
        form.find('#search_form_listings').val(false);
        $('#acc-types-listings').val(false);
        return $('#acc-types-traderspaces').val(true);
      });
      form.find('.js-nav-form-item-option').click(function() {
        form.find('#search_form_listings').val(true);
        form.find('#search_form_traderspaces').val(false);
        $('#acc-types-listings').val(true);
        return $('#acc-types-traderspaces').val(false);
      });
      return form.find('.js-nav-form-all-option').click(function() {
        form.find('#search_form_traderspaces').val(true);
        form.find('#search_form_listings').val(true);
        $('#acc-types-listings').val(true);
        return $('#acc-types-traderspaces').val(true);
      });
    }

    initAutocompletion() {
      const searchForm = this;
      searchForm.autocompleter();
      return searchForm.$navForm.find('#search_form_text').on('keyup', function(event) {
        if (event.which === 13) {
          return searchForm.$navForm.find('#search_form_text').autocomplete('disable');
        } else {
          return searchForm.$navForm.find('#search_form_text').autocomplete('enable');
        }
      });
    }

    autocompleter() {
      const searchForm = this;
      return searchForm.$navForm.find('#search_form_text').autocomplete({
        appendTo: searchForm.$navForm.find('#search_form_text').parent(),
        source(request, response) {
          if (request.term.slice(-1) === ' ') { return; }
          return $.ajax({
            url: searchForm.$navForm.find('#search_form_text').data('autocomplete-path'),
            dataType: 'json',
            data: { text: request.term
          },
            success(data) {
              response(data);
              return searchForm.$navForm.find('#search_form_text').parent().find('li').each(function() {
                return $(this).find('div').html($(this).text());
              });
            }
          });
        }
      });
    }

    autocompleteHandler() {
      const form = this.$navForm;
      return form.on('click', '.ui-menu-item', function() {
        const $autocompleteSpan = $(this).find('span');
        if ($autocompleteSpan.hasClass('js-autocomplete-traderspace-option')) {
          form.find('#search_form_traderspaces').val(true);
          form.find('#search_form_listings').val(false);
        } else if ($autocompleteSpan.hasClass('js-autocomplete-item-option')) {
          form.find('#search_form_listings').val(true);
          form.find('#search_form_traderspaces').val(false);
        } else if ($autocompleteSpan.hasClass('js-autocomplete-people-option')) {
          form.find('#search_form_traderspaces').val(true);
          form.find('#search_form_listings').val(true);
          form.find('#search_form_owner').val(form.find('#search_form_text').val());
        } else if ($autocompleteSpan.hasClass('js-autocomplete-hint-option')) {
          form.find('#search_form_text').val($(this).text());
        }
        form.submit();
      });
    }

    setZoomFromRadius(zoom, radius = null) {
      if (radius) {
        return this.map.radiusToZoom(parseInt(radius));
      } else {
        if (radius = this.$navForm.find('#search_form_radius').val()) {
          return this.map.radiusToZoom(parseInt(radius));
        } else {
          return zoom;
        }
      }
    }

    categoryDropdownSubmit() {
      const form = this.$navForm;
      return this.$categoriesDropdown.find('input').on('change', () => form.submit());
    }
  });
  Cls.initClass();
  return Cls;
})();
