/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.ItemCreator = class ItemCreator {
  constructor($container, $itemContainer, traderSpaceEditForm) {
    this.$container = $container;
    this.$alertLink = this.$container.find('#alert-link');
    this.$exchangeTypeSelect = this.$container.find('.exchange-type-input');
    this.$itemContainer = $itemContainer;
    this.traderSpaceEditForm = traderSpaceEditForm;
    this.slotsPurchaseForm = this.traderSpaceEditForm.slotsPurchaseForm;
    this.freeListingsAmount = this.$itemContainer.data('freeListings');
    this.init();
  }

  init() {
    this.afterInsertEvent();
    this.beforeInsertEvents();
    this.exchangeTypeSelectEvents();
    return this.insertNewItem();
  }

  afterInsertEvent() {
    const itemCreator = this;
    return this.$itemContainer.on('cocoon:after-insert', function(e, insertedItem) {
      const stepTwoUploader = new FormImageUploader(insertedItem);
      const categoryInput = new InputDropDown(insertedItem.find('.listing-type .dropdown'));
      categoryInput.run();
      const $section = insertedItem.find('.listing-delivery');
      const $input = $section.find('.delivery-method-input');
      $section.find('.form-group input').change(function() {
        return $input.val($(this).attr('value'));
      });
      if (insertedItem.is('.lending, .service')) {
        const dateRangePicker = new DaterangePicker(insertedItem.find('.item-datepicker'));
        dateRangePicker.build();
      }
      return itemCreator.traderSpaceEditForm.initMakeFeaturedButton(insertedItem);
    });
  }

  beforeInsertEvents() {
    const itemCreator = this;
    return this.$itemContainer.on('cocoon:before-insert', function(event, insertedItem) {
      const currentItemsHash = itemCreator.currentItemsHash(insertedItem);
      if ((currentItemsHash[insertedItem.data('itemType')] !== undefined) &&
         (currentItemsHash[insertedItem.data('itemType')].amount > itemCreator.freeListingsAmount) &&
         (currentItemsHash.requiredEmptySlots >= itemCreator.slotsPurchaseForm.availableEmptySlots('listing'))) {
        event.preventDefault();
        return toastr.error(itemCreator.$itemContainer.data('noEmptyListingsSlotsMessage'));
      }
    });
  }

  exchangeTypeSelectEvents() {
    const itemCreator = this;
    this.$alertLink.on('click', function() {
      return alert($(this).data('alertMessage'));
    });

    return this.$exchangeTypeSelect.on('change', function() {
      itemCreator.$container.find('.active').removeClass('active');
      return itemCreator.$container.find(`#${$(this).val()}-link`).addClass('active');
    });
  }

  insertNewItem() {
    return this.$container.find('a').data('association-insertion-method', 'prepend').data('association-insertion-node', link => link.parent().siblings('.listings'));
  }

  allCurrentlyEditerdItems() {
    const items = this.$itemContainer.find('.items');
    const itemsArray = [];
    let i = 0;
    while (i < items.length) {
      const item = $(items[i]);
      itemsArray.push({id: item.data('id'), itemType: item.data('itemType')});
      i++;
    }
    return itemsArray;
  }

  sortWithNewItemsOnBottom(itemsArray) {
    itemsArray.sort(function(a, b) {
      let value1 = a.id;
      const value2 = b.id;
      if (value1 === undefined) {
        value1 = value2 + 1;
      }
      return value1 - value2;
    });
    return itemsArray;
  }

  currentItemsHash(insertedItem) {
    const itemCreator = this;
    // get all currently edited items
    let itemsArray = itemCreator.allCurrentlyEditerdItems();
    // sort items array (new items on the bottom)
    itemsArray = itemCreator.sortWithNewItemsOnBottom(itemsArray);
    // sum up required slots
    const itemsNumbersHash = {};
    let requiredEmptySlots = 0;
    let i = 0;
    while (i < itemsArray.length) {
      const item = itemsArray[i];
      if (itemsNumbersHash[item.itemType] === undefined) {
        itemsNumbersHash[item.itemType] = { amount: 0 };
      }
      itemsNumbersHash[item.itemType].amount += 1;
      if ((itemsNumbersHash[item.itemType].amount > itemCreator.freeListingsAmount) &&
         (item.id === undefined)) {
        requiredEmptySlots += 1;
      }
      i++;
    }
    itemsNumbersHash['requiredEmptySlots'] = requiredEmptySlots;
    return itemsNumbersHash;
  }
};
