/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let DATE_FORMAT = undefined;
  let TIME_FORMAT = undefined;
  let DATETIME_FORMAT = undefined;
  let getInvalidDates = undefined;
  let isInvalid = undefined;
  const Cls = (window.CalendarPicker = class CalendarPicker {
    static initClass() {
      DATE_FORMAT = 'DD/MM/YYYY';
      TIME_FORMAT = 'HH:mm';
      DATETIME_FORMAT = `${TIME_FORMAT} ${DATE_FORMAT}`;
  
      getInvalidDates = function($input) {
        const invalidDates = $input.data('invalidDates');
        if (!invalidDates) { return invalidDates; }
  
        return _.map(invalidDates, date => ({
          start_date: new Date(date.start_date),
          end_date: new Date(date.end_date)
        }));
      };
  
      isInvalid = (date, invalidDates) => _.some(invalidDates, invalidDate => (date >= invalidDate.start_date) && (date <= invalidDate.end_date));
    }

    constructor($container, callbacks) {
      if (callbacks == null) { callbacks = []; }
      this.$container = $container;
      this.callbacks = callbacks;
      this.$calendarInput = this.$container.find('input#calendar-picker');
      this.$startDateInput = this.$container.find('input[name*="start_date"]');
      this.$endDateInput = this.$container.find('input[name*="end_date"]');
      this.init();
    }

    init() {
      this.initCalendar();
      return this.calendarEvents();
    }

    calendarEvents() {
      this.$calendarInput.on('apply.daterangepicker', (event, calendar) => {
        const startDate = calendar.startDate.format(DATETIME_FORMAT);
        const endDate = calendar.endDate.format(DATETIME_FORMAT);
        this.$startDateInput.val(startDate);
        this.$endDateInput.val(endDate);
        this.$calendarInput.val(`${startDate} - ${endDate}`);
        return this.callbacks.forEach(fn => fn(startDate, endDate));
      });

      this.$calendarInput.on('cancel.daterangepicker', (ev, picker) => {
        this.$startDateInput.val('');
        this.$endDateInput.val('');
        return this.$calendarInput.val('');
      });

      return this.$calendarInput.on('keydown', e => e.preventDefault());
    }

    initCalendar() {
      const currentDate = moment();
      const invalidDates = getInvalidDates(this.$calendarInput);
      return this.$calendarInput.daterangepicker({
        minDate: currentDate,
        locale: {
          format: DATE_FORMAT,
          cancelLabel: 'Clear'
        },
        timePicker: true,
        timePicker24Hour: true,
        timePickerIncrement: 60,
        autoUpdateInput: false,
        isInvalidDate(date) {
          if (!invalidDates || !invalidDates.length) { return false; }
          return isInvalid(date, invalidDates);
        }
      });
    }
  });
  Cls.initClass();
  return Cls;
})();

