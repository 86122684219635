/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const $container = $('.profile-edit');
  if (!$container.length) { return; }

  const $form = $container.find('form.js-edit-form');
  // $('.profile-edit-payments-subscriptions .js-button').on('click', e => e.preventDefault());
  $container.find('.save-changes').click(() => $form.submit());
  $container.find('#user_avatar, #user_banner').change(function() {
    const input = this;
    if (!this.files || !this.files[0]) { return; }

    const reader = new FileReader();
    reader.onload = e => $(input.dataset.previewTarget).attr('src', e.target.result);
    return reader.readAsDataURL(this.files[0]);});
  $container.find('.provide-id-button input').change(function() {
    const {
      msg
    } = this.dataset;
    return $container.find('.provide-id-button span').html(msg);
  });
  $container.find('.send-invites .send').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    const $input = $container.find('.js-invite-input');
    const {
      url
    } = this.dataset;
    const msg = this.dataset.message;
    const value = $input.val();
    if (value === '') { return; }

    return $.ajax({
      type: 'POST',
      url,
      data: {
        email: value
      },
      success(response) {
        toastr.success(response.message);
        $input.val('');
        const pending = $container.find('.status-info .pending .number');
        pending.html(response.statusInfo.pending);
        const confirmed = $container.find('.status-info .confirmed .number');
        confirmed.html(response.statusInfo.confirmed);
      },
      error(response) {
        toastr.error(response.responseJSON.message);
        $input.val('');
      }
    });});
  $container.find('.forgot-password').on('ajax:success', function(e) {
    return toastr.success(this.dataset.msg);
  });
  new CopyLink(
      $container.find('.copy-link'),
      $container
  );
  $container.find('.js-save-form').click(() => $form.submit());

  $container.find('.advanced-settings-button').on('click', function(e) {
    e.preventDefault();
    // alert('elo')
    if (!$(this).siblings('.advanced-settings-nav').hasClass('open')) {
      $('html, body').animate({ scrollTop: $(window).scrollTop() + 315 }, 400);
    }
    return $(this).siblings('.advanced-settings-nav').slideToggle().toggleClass('open');
  });

  if ($container.hasClass('profile-edit-privacy')) {
    const $that = $('.profile-edit-privacy').find('.slider');
    const setText = $el => $el.each(function() {
      if ($(this).hasClass('on') && $(this).closest('.switch')
          .find('input[type="checkbox"]')
          .is(':checked')) {
        $(this).removeClass('on');
        return $(this).closest('.content-wrapper').find('.status').text('off');
      } else if ($(this).closest('.switch')
          .find('input[type="checkbox"]')
          .is(':checked') || $(this).hasClass('on')) {
        return $(this).closest('.content-wrapper').find('.status').text('on');
      } else {
        return $(this).closest('.content-wrapper').find('.status').text('off');
      }
    });

    setText($that);
    $that.on('click', function() {
      const $button = $(this);
      $button.addClass('on');
      return setText($button);
    });
  }

  // Remove identification
  $container.find('.js-remove-identification-btn').on('click', function(e) {
    e.preventDefault();
    const $btn = $(e.target);
    const confirmationText = $btn.data('confirmationText');
    const processingText = $btn.data('processingText');

    $btn.attr('disabled', true);
    if (confirm(confirmationText)) {
      $btn.text(processingText);

      return $.ajax({
        type: 'PUT',
        url: $btn.data('updateUrl'),
        data: {
          user: {
            remove_identification: '1'
          }
        },
        success() {
          return location.reload();
        }
      });
    } else {
      return $btn.attr('disabled', false);
    }
  });

  // Edit profile description max 80 chars long
  if (!($container.find('.bio').length > 0)) { return; }
  const $desc = $container.find('.bio textarea');
  const $desc_counter = $container.find('.counter');
  const $MAX_DESC_LENGTH = 80;
  if ($desc.val().length > $MAX_DESC_LENGTH) {
    $desc.val($desc.val().slice(0, $MAX_DESC_LENGTH));
  }
  let $CHARS_AVAILABLE = $MAX_DESC_LENGTH - $desc.val().length;
  $desc_counter.text($CHARS_AVAILABLE);

  return $desc.on('input', function(e) {
    if ($desc.val().length >= $MAX_DESC_LENGTH) {
      const $text = $desc.val();
      $desc.val($text.slice(0, $MAX_DESC_LENGTH));
    }

    $CHARS_AVAILABLE = $MAX_DESC_LENGTH - $desc.val().length;
    return $desc_counter.text($CHARS_AVAILABLE);
  });
});
