/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let whatToInsert = undefined;
  const Cls = (window.NestedJsonSerializer = class NestedJsonSerializer {
    static initClass() {
  
      whatToInsert = function(attributes, index, length, value) {
        if (index < (length - 1)) {
          return ' = {}';
        } else {
          return ` = '${value.toString()}'`;
        }
      };
    }
    constructor($form, paneType) {
      this.$form = $form;
      this.paneType = paneType;
      this.$serializedData = {};
      this.formData = this.$form.serializeArray();
      this.formData.splice(0, 3);
      this.init();
    }

    init() {
      const jsonSerializer = this;
      const {
        formData
      } = this;
      return _.each(formData, attribute => jsonSerializer.assignAttribute(attribute));
    }

    assignAttribute(element) {
      const attributes = element.name.replace(/]/g, '').split('[');
      if ($.inArray(this.paneType, attributes) === -1) { return; }
      let lastElement = this.$serializedData;
      return _.each(attributes, function(attribute, index) {
        const insertion =
            whatToInsert(attributes, index, attributes.length, element.value);
        if ($.isNumeric(attribute)) {
          if ((eval(`lastElement[${attribute}]`) === undefined) || (insertion !== ' = {}')) {
            eval(`lastElement['${attribute}']${insertion}`);
          }
          return lastElement = eval(`lastElement[${attribute}]`);
        } else {
          if ((eval(`lastElement.${attribute}`) === undefined) || (insertion !== ' = {}')) {
            eval(`lastElement.${attribute}${insertion}`);
          }
          return lastElement = eval(`lastElement.${attribute}`);
        }
      });
    }
  });
  Cls.initClass();
  return Cls;
})();
