/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.MobileMarker = class MobileMarker extends window.Marker {
  constructor(element, mapCreator) {
    this.mapCreator = mapCreator;
    this.markerId = element.id;
    this.marker = new RichMarker({ visible: true });
    this.marker.setPosition(new (google.maps.LatLng)(element.lat, element.lng));
    this.marker.setContent(element.marker_html);
    this.marker.setFlat(true);
    this.markerHTMLId = `#element-traderspace-${this.markerId}`;
    this.resultsOnClick();
  }

  resultsOnClick() {
    const that = this;
    const formData = {};
    _.each($('#search-filter').serializeArray(), obj => formData[obj.name] = obj.value);
    return $(document).on('click', this.markerHTMLId, function(e){
      e.stopPropagation();
      return $.ajax({
        url: $(that.markerHTMLId).data('fetch-listings-url'),
        data: formData,
        dataType: 'json',
        success(data) {
          $('.viewing').removeClass('viewing').addClass('viewed');
          $(that.markerHTMLId).addClass('viewing');
          const $resultsContainer = $('.search-map .results .search-results .results-wrapper');
          $resultsContainer.html(data.listings_results);
          $resultsContainer.find('.parallel-results').append(data.traderspace_result);
          const leftArrow = $resultsContainer.find('.images-carousel').data('left');
          const rightArrow = $resultsContainer.find('.images-carousel').data('right');
          $resultsContainer.find('.images-carousel .images').owlCarousel({
            loop: true,
            nav: true,
            navText: [leftArrow, rightArrow],
            items: 1,
            touchDrag: false
          });
          return $('.search-map .map .map-buttons .list').trigger('click');
        }
      });
    });
  }
};
