/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const showAvailableNumberOfCharacters = function(field, max_length) {
  const $inputElement = $(`.space-${field}`);
  const $resultElement = $(`.space-${field}-length`);
  const {
    length
  } = $inputElement.val();
  const result = max_length - length;
  $resultElement.html(result);
  return result;
};

const showAvailabilityStatus = function(status, class_name, icon_class) {
  $('.availability-status').html(status);
  $('.availability-icon').addClass(icon_class);
  return $('.availability-badge').addClass(class_name);
};

const clearAvailabilityStatus = function() {
  $('.availability-status').empty();
  // Classes names are based on the file constants.rb
  $('.availability-badge').removeClass('not-available');
  $('.availability-badge').removeClass('available');
  $('.availability-icon').removeClass('fa-times');
  return $('.availability-icon').removeClass('fa-check');
};

const availabilityButton = function() {
  if ($('.space-name').val().length === 0) {
    return $('.check-name-availability').attr("disabled", "disabled");
  } else {
    return $('.check-name-availability').removeAttr("disabled");
  }
};

const assignAndPreviewImage = function(files) {
  const reader = new FileReader();
  reader.onload = e => $('.show-image-thumb').attr('src', e.target.result);
  reader.readAsDataURL(files[0]);
  if (navigator.userAgent.indexOf('Firefox') === -1) {
    return $('.ts-wizard .step-basics input#bp-upload')[0].files = files;
  }
};

$(document).on('turbolinks:load', function() {
  const MAX_NAME_LENGTH = 20;
  const MAX_DESC_LENGTH = 200;
  const KEYS = [8, 37, 38, 39, 40]; // backspace & up/right/down/left arrows

  if (!$('.ts-wizard .step-basics').length) { return; }

  if ($('.space-name').length) {
    showAvailableNumberOfCharacters('name', MAX_NAME_LENGTH);
    availabilityButton();
    $('.space-name').on('focus keydown', function(e) {
      if ($(this).val().length >= MAX_NAME_LENGTH) {
        if (!KEYS.includes(e.which)) {
          return e.preventDefault();
        }
      }
    });
    $('.space-name').on('keyup', function() {
      availabilityButton();
      if (showAvailableNumberOfCharacters('name', MAX_NAME_LENGTH) < 0) {
        const name = $('.space-name').val();
        $('.space-name').val(name.substring(0, MAX_NAME_LENGTH));
      }
      showAvailableNumberOfCharacters('name', MAX_NAME_LENGTH);
      return clearAvailabilityStatus();
    });
  }

  if ($('.space-description').length) {
    showAvailableNumberOfCharacters('description', MAX_DESC_LENGTH);
    $('.space-description').on('focus keydown', function(e) {
      if ($(this).val().length >= MAX_DESC_LENGTH) {
        if (!KEYS.includes(e.which)) {
          return e.preventDefault();
        }
      }
    });
    $('.space-description').on('keyup', function() {
      if (showAvailableNumberOfCharacters('description', MAX_DESC_LENGTH) < 0) {
        const description = $('.space-description').val();
        $('.space-description').val(description.substring(0, MAX_DESC_LENGTH));
      }
      return showAvailableNumberOfCharacters('description', MAX_DESC_LENGTH);
    });
  }

  $('.check-name-availability').on('click', function(e) {
    e.preventDefault();
    if ($('.space-name').val().length) {
      const params = {
        trader_space: {
          name: $('.space-name').val(),
          form_step: 'basics'
        }
      };

      return $.ajax({
        type: 'GET',
        url: $('.check-name-availability').data('url'),
        dataType: 'json',
        contentType: 'application/json',
        data: params,
        success(data) {
          return showAvailabilityStatus(data.message, data.class_name, data.icon_class);
        },
        error(data) {
          return showAvailabilityStatus(data.message, data.class_name, data.icon_class);
        }
      });
    }
});

  const $draggable = $('.banner-picture-wrapper');
  const $input = $draggable.find('#bp-upload');

  $input.on('change', function(e) {
    e.stopPropagation();
    return assignAndPreviewImage(e.target.files);
  });

  return $draggable
    .on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
      e.preventDefault();
      return e.stopPropagation();
  }).on('dragover dragenter', () => $draggable.addClass('is-dragover')).on('dragleave dragend drop', () => $draggable.removeClass('is-dragover')).on('drop', e => assignAndPreviewImage(e.originalEvent.dataTransfer.files));
});
