/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.HorizontalScroll = class HorizontalScroll {
  constructor($container) {
    this.$container = $container;
    this.$resultsContainer = this.$container.find('.results-wrapper');
    this.$scrollContainer = this.$container.find('.results-move-button');
    this.$slider = this.$scrollContainer.find('#results-slider');
    this.init();
  }

  init() {
    this.createSlider();
    this.mouseScrollEvents();
    this.sliderUpdateEvent();
    return this.tooltipEvents();
  }

  createSlider() {
    return noUiSlider.create(this.$slider[0], {
      animate: false,
      start: 0,
      range: {
        'min': 0,
        'max': 100
      }
    }
    );
  }

  mouseScrollEvents() {
    const scrollObj = this;
    return this.$resultsContainer.on('wheel', function(event) {
      const current_position = parseFloat(scrollObj.$slider[0].noUiSlider.get());
      if (event.originalEvent.deltaY < 0) {
        // scroll up
        if (current_position > 0) {
          scrollObj.$slider[0].noUiSlider.set(current_position - 4);
          scrollObj.moveContent();
        }
      } else {
        // scroll down
        if (current_position < 100) {
          scrollObj.$slider[0].noUiSlider.set(current_position + 4);
          scrollObj.moveContent();
        }
      }
      event.preventDefault();
    });
  }

  sliderUpdateEvent() {
    const scrollObj = this;
    return this.$slider[0].noUiSlider.on('update', function(e) {
      scrollObj.moveContent();
      return scrollObj.$scrollContainer.find('.popover-custom').addClass('unvisible').removeClass('visible');
    });
  }

  moveContent() {
    const newButtonPos = parseFloat(this.$slider[0].noUiSlider.get());
    return this.$resultsContainer.css({'left': '-' + (((this.resultsWrapperWidth - this.resultsWidth) * newButtonPos) / 100) + 'px'});
  }

  recalculateScrollbar() {
    const results = this.$resultsContainer.children('.result').length;
    this.resultsWidth = this.$resultsContainer.parent().width();
    const result = this.$resultsContainer.find('.result').first();
    const resultWidth = result.outerWidth();
    const resultMargin = parseInt($('.result').css('margin-right'));
    return this.resultsWrapperWidth = (Math.ceil(results / 2) * (resultWidth + resultMargin)) - resultMargin;
  }

  tooltipEvents() {
    const scrollObj = this;
    return this.$scrollContainer.on({
      mouseenter() {
        const thumbPosition = parseFloat(scrollObj.$slider[0].noUiSlider.get());
        const $windowWidth = $(window).width();
        const $popover = $(this).find('.popover-custom');
        const $popoverWidth = $popover.width();
        if ($('.home').length) {
          let left;
          if ((((thumbPosition * $windowWidth) / 100) + 14) < 96) {
            $popover.css({'left': `${96 - 14}px`});
            left = ((thumbPosition * $windowWidth) / 100) + 14;
            document.documentElement.style.setProperty('--left', `${left}px`);
          } else if (((((thumbPosition / 100) * $windowWidth) - 14) + 96) > $windowWidth) {
            $popover.css({'left': "calc(100% - 96px + 14px)"});
            left = ($popoverWidth + 14) - ($windowWidth - (($windowWidth * thumbPosition) / 100));
            document.documentElement.style.setProperty('--left', `${left}px`);
          } else {
            $popover.css({'left': `${thumbPosition}%`});
            left = $popover.outerWidth() / 2;
            document.documentElement.style.setProperty('--left', `${left}px`);
          }
        } else {
          $(this).find('.popover-custom').css({'left': `${thumbPosition}%`});
        }
        return $(this).find('.popover-custom').removeClass('unvisible').addClass('visible');
      },
      mouseleave() {
        return $(this).find('.popover-custom').addClass('unvisible').removeClass('visible');
      }
    });
  }

  disableSlider(search) {
    if ((this.$resultsContainer.find('.parallel-results').length === 1) &&
       !this.$container.find('.load-more').length) {
      return this.$slider[0].noUiSlider.target.setAttribute('disabled', true);
    } else if (this.$slider[0].noUiSlider.target.getAttribute('disabled') === 'true') {
      return this.$slider[0].noUiSlider.target.removeAttribute('disabled');
    }
  }
};