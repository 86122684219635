/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(window).on('turbolinks:load', function() {
  if ($('#dash-reviews').length > 0) {
    const reviewsFilterForm = new FilterForm($('#dash-reviews'), 'reviews');
    reviewsFilterForm.build();

    $('input#search-reviews').on('keypress', function(event) {
      if (event.which === 13) {
        event.preventDefault();
        return $('#reviews-search-form').submit();
      }
    });

    $('.dropdown.most-recent').find('.dropdown-menu').on('click', function() {
      return $(this).parent().find('.dropdown-toggle').dropdown('toggle');
    });
  }

  if (($('#dash-reviews').length > 0) || $('.reviews-modal')) {
    $('textarea.review-response').on('keypress', function(event) {
      if (event.which === 13) {
        event.preventDefault();
        return $(this).parent().submit();
      }
    });

    $('.new_review_response').on('ajax:success', function(event, html) {
      return $(this).parent().html(html);
    });

    $('.row-three ul').on('click', function(event) {
      $('.row-three ul li a.star.sorted').toggleClass('sorted');
      return $(event.target).closest('a.star').toggleClass('sorted');
    });
  }

  $(document).on('click', '.helpful-vote', function() {
    return $(this).submit();
  });

  $(document).on('ajax:success', '.review-helpful', function(event, data) {
    $(this).closest('.review-result').find('.review-helpful').html(data.html);
    return toastr.success(data.message);
  });

  $(document).on('ajax:success', '.response-helpful', function(event, data) {
    $(this).html(data.html);
    return toastr.success(data.message);
  });

  $(document).on('ajax:error', '.review-helpful, .response-helpful', (event, jqXHR) => toastr.error(jqXHR.responseJSON.message));

  $(document).on('click', '.review-response .button', function() {
    return $(this).siblings('.popover-custom').toggleClass('hidden');
  });

  $(document).on('click', '.review-response .review-popover .close', function() {
    return $(this).closest('.popover-custom').toggleClass('hidden');
  });

  $(document).on('click', '.follow-options', e => e.stopPropagation());

  return $(document).on('click', '.js-review-toggle', function(e) {
    e.preventDefault();
    const url = this.dataset.reviewPath;
    return $.ajax({
      type: 'GET',
      url,
      success(data) {
        return new ModalInvoker(data.html);
      }
    });
});
});
