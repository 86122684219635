/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.MobileMapCreator = class MobileMapCreator extends window.MapCreator {
  constructor($mapContainer) {
    super($mapContainer);
  }

  buildMarker(element) {
    const marker = new MobileMarker(element, this);
    return marker;
  }

  buildSearchMap() {
    super.buildSearchMap();
    return this.map.setOptions({
      disableDefaultUI: true,
      zoomControl: false });
  }
};
