/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('#dash-overview').length > 0) {
    const overviewFilterForm = new FilterForm($('#dash-overview'), 'overview');
    overviewFilterForm.build();

    return $('.see-messages').on('click', function() {
      $('#dashboard-wrapper .nav-tabs').find('.active').removeClass('active');
      return $('#dashboard-wrapper .nav-tabs')
        .find('[aria-controls="dash-my-messages"]').parent().addClass('active');
    });
  }
});
