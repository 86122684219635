/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");

  if ((msie > 0) || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    return $('body').addClass('internet-explorer');
  } else {
    return;
  }
});