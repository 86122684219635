/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('.ts-wizard .step-listing-basics').length) {
    const tags = new Tags($('.ts-wizard .step-2 .tags'));
    const categories = new WizardCategoriesInputDropDown($('.ts-wizard .step-2 .categories-dropdown'));
    return categories.run();
  }
});
