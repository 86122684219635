/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const SHIPPING_LOCATIONS_ATTRIBUTES = ['destination', 'one_item_price',
  'additional_items_price', 'free_shipping', '_destroy'];

$(document).on('turbolinks:load', function() {
  const $container = $('.listing-shipping');
  if (!$container.length) { return; }


  $container.on('cocoon:after-insert', function(e, addedElement) {
    const dropdown = new InputDropDown($(addedElement).find('.dropdown-helper'));
    return dropdown.run();
  });

  return $(document).on('click', '.location .free-shipping label', function(event) {
    const $location = $(event.target).closest('.location');
    const $priceInputs = $location.find(
      '.one-item-price input, .additional-items-price input'
    );

    $priceInputs.prop('readonly', !$priceInputs.prop('readonly'));
    if ($priceInputs.prop('readonly')) { return $priceInputs.val(0.00); }
  });
});
