/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let scrollUpMessages = undefined;
  let initScrollbar = undefined;
  let initFormListener = undefined;
  let refreshMessagesContainer = undefined;
  let askForOlderMessages = undefined;
  let hasMoreMessages = undefined;
  const Cls = (window.MessagesBox = class MessagesBox {
    static initClass() {
  
      scrollUpMessages = $container => $container.animate({ scrollTop: $container[0].scrollHeight }, 200);
  
      initScrollbar = function($container) {
        const self = this;
        $container.on('touchstart', function() {
          if (!hasMoreMessages($container) || !!self.isLoading) { return; }
          if (this.scrollTop < 10) { return askForOlderMessages($container); }
        });
        return $container.on('mousewheel', function(event) {
          if (!hasMoreMessages($container) || !!self.isLoading) { return; }
          if ((this.scrollTop < 40) && ((event.originalEvent.wheelDelta > 0) ||
                                 (event.originalEvent.detail < 0))) {
            return askForOlderMessages($container);
          }
        });
      };
  
      initFormListener = $messageForm => $messageForm.on('ajax:success', function() {
        return $(this).find('textarea').val('');
      });
  
      // refreshing messages
      refreshMessagesContainer = function($container, content) {
        scrollUpMessages($container);
        $($container[0]).html(content);
        return scrollUpMessages($container);
      };
  
      // infinite scroll
      askForOlderMessages = function($container) {
        this.isLoading = true;
        const self = this;
        return $.ajax({
          type: 'GET',
          url: $container.data('load-more-url'),
          data: {
            last_message: $container.find('.message-wrapper').data('messageDate'),
            limit: 99999
          },
          success(data) {
            if (!hasMoreMessages($container)) { return; }
            const originalHeight = $container[0].scrollHeight;
            $('span.load-more').remove();
            $container.prepend(data.messages);
            $container.removeClass('wait');
            $($container).scrollTop($container[0].scrollHeight - originalHeight);
            return self.isLoading = false;
          }
        });
      };
  
      hasMoreMessages = $container => $container.find('span.load-more').data('isMore');
    }
    constructor($box) {
      this.$box = $box;
      // elements
      this.newMessageForm = this.$box.find('.conversation-new-message-form');
      this.messagesContainer = this.$box.find('.chat');
      this.isLoading = false;
      this.firefoxHelper = new FirefoxStylesHelper();
      this.run();
    }

    run() {
      initScrollbar(this.messagesContainer);
      return initFormListener(this.newMessageForm);
    }
  });
  Cls.initClass();
  return Cls;
})();
