/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.WizardArea = class WizardArea extends window.Area {
  constructor(address, map, bounds, $container) {
    super(address, map, bounds, $container);
    this.adjustButton();
    this.$exactAddress = this.$container.parent().parent().find('.exact-address');
  }

  addMarker() {
    const icon = { url: this.$container.data('markerIcon'), scaledSize: new google.maps.Size(25, 33) };
    return this.marker = new (google.maps.Marker)({
      map: this.map,
      position: this.location,
      icon,
      draggable: true
    });
  }

  adjustButton() {
    const that = this;
    return this.$container.parent().find('.adjust-address').on('click', function() {
      const newLocation = that.marker.getPosition();
      return that.geocoder.geocode({ 'location': newLocation }, function(results, status) {
        if ((status !== 'OK') || !results[0]) { return; }
        that.setLocationInputsValues(newLocation.lat(), newLocation.lng());
        return that.$exactAddress.html(results[0].formatted_address);
      });
    });
  }
};
