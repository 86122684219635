/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if (!$('.ts-wizard').length) { return; }

  const $container = $('.ts-wizard');
  const $filterModal = $container.find('.filter-modal');
  $filterModal.find('.options .option input[type=checkbox]').click(function(e) {
    if (!$(this).closest('.options').find('input:checked').length) { return e.preventDefault(); }
  });

  return $filterModal.find('.apply-changes').click(function() {
    return $(this).closest('.modal').modal('hide');
  });
});