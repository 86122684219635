/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.AjaxPaginator = class AjaxPaginator {
  constructor($form, selector) {
    this.init = this.init.bind(this);
    this.$form = $form;
    this.selector = selector;
    this.init();
  }

  init() {
    const self = this;
    return $(document.body).on('click', this.selector, function(e) {
      e.preventDefault();
      e.stopPropagation();
      const href = $(this).attr('href');
      // page = (new QueryExtractor(href)).get('page')
      const page = (new QueryExtractor(href, 'page')).get();
      const paginated = (new QueryExtractor(href, 'paginated')).get();
      self.$form.find('input#page').val(page);
      self.$form.find('input#paginated').val(paginated);
      return self.$form.submit();
    });
  }
};
