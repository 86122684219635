/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const onLocationSuccess = function(position) {
  const cookie_val = position.coords.latitude + ',' + position.coords.longitude;
  return Cookies.set('lat_lng', cookie_val);
};

$(document).on('turbolinks:load', function() {
  /* fix for scrollbar in ff on mac */
  const firefoxHelper = new FirefoxStylesHelper();

  /* popovers initializer */
  const popovers = new PopoversInitializer();
  popovers.init();

//  $('#selling-listing-modal, #lending-listing-modal, #service-listing-modal, #looking-listing-modal').find('.results').scrollbar()

  $('body').on('load', '#dash-search-user', function() {
    return $(this).find('.users').scrollbar();
  });

  const ua = navigator.userAgent;
  const iOS = /iPad|iPhone|iPod/.test(ua);
  const iOS11 = /OS 11_0_1|OS 11_0_2|OS 11_0_3|OS 11_1|OS 11_1_1|OS 11_1_2|OS 11_2|OS 11_2_1/.test(ua);
  // ios 11 bug caret position
  if (iOS && iOS11) {
    // Add CSS class to body
    $('body').addClass('ios');
  }

  navigator.geolocation.getCurrentPosition(
    onLocationSuccess, null, {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    }
  );

  /* smooth scrolling to top*/
  $('#to-top').on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, 350);
  });

  $(document).on('click', `.listing-result .owl-nav button, \
.listing-result .owl-dot, .listing-result .favorite`, e => e.stopPropagation());

  const languageMenu = new ListMenu($('#language-currency-modal'), 'languages');
  const currencyMenu = new ListMenu($('#language-currency-modal'), 'currencies');

  /* language and currency button stops above footer */

  const setPosition = function() {
    if ($('main.search').length > 0) { return; }
    const $currentPosition = $(this).scrollTop();
    if ($(window).width() < 1900) {
      if (($('body').height() - ($currentPosition + $(window).height())) <= 89) {
        return $('.language-and-currency-section').addClass('fixed-bottom');
      } else {
        return $('.language-and-currency-section').removeClass('fixed-bottom');
      }
    } else {
      return $('.language-and-currency-section').removeClass('fixed-bottom');
    }
  };

  setTimeout((() => setPosition()), 50);
  
  $(window).on('scroll', () => setPosition());

  $(window).on('resize', () => setPosition());

  return $('.modal').on('hidden.bs.modal', function() {
    if ($('.modal.in').length > 0) {
      return $('body').addClass('modal-open');
    }
  });
});
    