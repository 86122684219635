/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('.home').length > 0) {
    let favorite;
    const $imagesContainers = $('.home').find('.images-carousel');
    const left = $imagesContainers.data('left');
    const right = $imagesContainers.data('right');
    $imagesContainers.find('.images').owlCarousel({
      loop: false,
      nav: true,
      dots: true,
      navText: [left, right],
      items: 1,
      touchDrag: true,
      onDrag() {
        return $('.listings-wrapper, .traderspaces-wrapper').css({
          overflow: 'hidden'
        });
      },
      onDragged() {
        return $('.listings-wrapper, .traderspaces-wrapper').removeAttr('style');
      }
    });

    return favorite = new ButtonSwitchEvents($('.home').find('.favorite'), 'favorite');
  }
});
