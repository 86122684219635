/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('.traderspace-page').length > 0) {
    /* TS view - displayiny banner/map */

    let tsBannerMap;
    return tsBannerMap = new BannerMap($('.traderspace-page .banner-map-wrapper'));
  }
});
