/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('.traderspace-page').length > 0) {
    /* TSW - badge carousel */

    const $container = $('.traderspace-page').find('.badges-carousel');
    const left = $container.data('left');
    const right = $container.data('right');
    return $container.find('.owl-carousel').owlCarousel({
      loop: true,
      nav: true,
      margin: 20,
      dots: false,
      navText: [left, right],
      responsive: {
        2001: { items: 5
      },
        1691: { items: 4
      },
        1281: { items: 3
      },
        1: { items: 4
      }
      }
    });
  }
});
