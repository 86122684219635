/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const $cointainer = $('.profile-achievements');
  if (!($cointainer.length > 0)) { return; }

  const $searchForm = $cointainer.find('#reviews-search-form');
  $searchForm.find('a.star').click(function() {
    $('input#reviews_search_form_stars').val(this.dataset.starsNumber);
    return $searchForm.submit();
  });

  $cointainer.find('.points .info-icon').on('click', event => $(event.currentTarget).siblings('.more-info').toggleClass('hidden'));

  const $rankingSection = $('.ranking-section');
  new ProgressBar($rankingSection);

  return $('[rel=tooltip]').tooltip({gravity: 'n', fade: true, html:true});
});