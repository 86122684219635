/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const findInAddress = function(address, type) {
  const found = _.find(address, component => Array.from(component.types).includes(type));
  if (found) { return found.long_name; } else { return ''; }
};

$(document).on('turbolinks:load', function() {
  const $container = $('.ts-wizard .step-location');
  if (!$container.length) { return; } 
  return initAutocomplete($container);
});

var initAutocomplete = function($container) {
  const streetAutocomplete = new google.maps.places.Autocomplete($container.find('#space-address')[0]);
  streetAutocomplete.addListener('place_changed', function() {
    const place = this.getPlace();
    const components = place.address_components;
    $('[data-value="'+ findInAddress(components, 'country') + '"]').click();
    $('input#space-address').val(findInAddress(components, 'route') + ' ' + findInAddress(components, 'street_number'));
    $('input#space-town').val(findInAddress(components, 'locality'));
    $('input#space-state').val(findInAddress(components, 'administrative_area_level_1'));
    return $('input#space-post-code').val(findInAddress(components, 'postal_code'));
  });

  const townAutocomplete = new google.maps.places.Autocomplete(
    $container.find('#space-town')[0], { types: ['(cities)'] }
  );
  streetAutocomplete.addListener('place_changed', function() {
    const place = this.getPlace();
    const components = place.address_components;
    return $('input#space-town').val(findInAddress(components, 'locality'));
  });

  const stateAutocomplete = new google.maps.places.Autocomplete(
    $container.find('#space-state')[0], { types: ['(regions)'] }
  );
  return streetAutocomplete.addListener('place_changed', function() {
    const place = this.getPlace();
    const components = place.address_components;
    return $('input#space-state').val(findInAddress(components, 'administrative_area_level_1'));
  });
};