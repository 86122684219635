/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let valid = undefined;
  let addWarnings = undefined;
  let removeAlerts = undefined;
  let invalidCSRF = undefined;
  const Cls = (window.TraderSpaceWizardValidator = class TraderSpaceWizardValidator {
    static initClass() {
      valid = function(state) {
        if ((state === true) || (state === false)) {
          this.valid = state;
        }
        return this.valid;
      };
  
      addWarnings = function(response) {
        if (!response) { return; }
        $.each(response.messages, (key, message) => toastr.error(message));
  
        return $.each(response.errors, function(key) {
          key = `step-${key}`;
          const str = `${$('.step .active').attr('class')}_items.`;
          key = key.replace(str, '');
          if (key.indexOf('available') >= 0) {
            return $('.active input#date-picker').parent().addClass('validation-warning');
          } else if (key.indexOf('delivery_method') >=0) {
            return $('.active .delivery-method-section').addClass('validation-warning-radio');
          } else if (key.indexOf('sms_notifications') >= 0) {
            return $('.type.sms').addClass('validation-warning-radio');
          } else {
            if (!(key.indexOf($('.step .active').attr('class')) >= 0)) {
              return $(`.active input[name*=${key}]`).parent().addClass('validation-warning');
            }
          }
        });
      };
  
      removeAlerts = function() {
        $('body').find('.flash-messages').remove();
        $('.validation-warning').removeClass('validation-warning');
        return $('.validation-warning-radio').removeClass('validation-warning-radio');
      };
  
      invalidCSRF = function(data) {
        valid(false);
        return window.location.replace(data.url);
      };
    }

    validateForm(data) {
      $.ajax({
        type: 'PUT',
        url: $('div[class*=step]').data('path'),
        dataType: 'json',
        contentType: 'application/json',
        async: false,
        data: JSON.stringify(data),

        success(data) {
          if (data.invalid_csrf) {
            return invalidCSRF(data);
          } else {
            if (data.partial) {
              $('.content').html(data.partial);
              const secondStep = new InitSecondStep($('.step-2'), true);
            }

            valid(true);
            removeAlerts();
            return activeTab(data.substep);
          }
        },
        error(jqXHR, text, ex) {
          valid(false);
          removeAlerts();
          addWarnings(jqXHR.responseJSON);
          return activeTab(jqXHR.responseJSON.substep - 1);
        }
      });
      return valid();
    }
  });
  Cls.initClass();
  return Cls;
})();
