/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const $messagesContainer = $('.profile-messages');
  if (!$messagesContainer.length) { return; }
  const $settingsForm = $messagesContainer.find('form.edit_user');
  $settingsForm.find('input[type=checkbox]').on('change', function() {
    return $(this).closest('form').submit();
  });
  $settingsForm.on('ajax:success', event => event.stopPropagation());
  $(document).on('ajax:success', '.conversation-modal .conversation-new-message-form', () => $('.messages-refresh').click());
  const $messagesSearch = $messagesContainer.find('.messages-search');
  $messagesSearch.find('input[type=text]').on('input', function() {
    return $(this).closest('form').submit();
  });
  $messagesContainer.find('.messages-list-header').on('ajax:success', (event, body) => $('.messages-list-main').html(body.html));
  $('.messages-list-main').on('ajax:success', function(event, response) {
    if ($(response.html).find('.new')) { $(event.target).find('.message').removeClass('new'); }
    new ModalInvoker(response.html);
    return new MessagesBox($('.conversation-messenger'));
  });
  const $newMessageModal = $messagesContainer.find('.new-message-modal');
  $newMessageModal.find('.new_conversation').on('ajax:success', function(event, response) {
    $newMessageModal.hide();
    $newMessageModal.find('.new-message-recipients-list').html('');
    $newMessageModal.find('input.new-message-recipient-input').val('');
    return new ModalInvoker(response.html);
  });
  $newMessageModal.find('.new_conversation').on('ajax:error', function(event, response) {
    $newMessageModal.find('.error-container').text(response.responseJSON.error);
    return $newMessageModal.find('.search-user input').css('border-color', '#e44350');
  });
  $newMessageModal.on('show.bs.modal', function() {
    $newMessageModal.find('.error-container').text('');
    return $newMessageModal.find('.search-user input').css('border-color', '#fbe4e6');
  });
  $newMessageModal.find('input.new-message-recipient-input').on('input', function() {
    const {
      path
    } = this.dataset;
    const {
      value
    } = this;
    const $list = $newMessageModal.find('.new-message-recipients-list');
    if (!value) {
      $list.html('');
      return;
    }
    return $.ajax({
      type: 'GET',
      url: path,
      data: { q: value },
      success(data) {
        $list.html(data.html);
        return $list.find('.recipient-profile-container').click(function() {
          const $target = $(this);
          $list.find('.recipient-profile-container').removeClass('active');
          $list.find('.selected-icon').hide();
          $target.addClass('active').find('.selected-icon').show();
          return $newMessageModal.find('input#conversation_recipient_id').val(this.dataset.id);
        });
      }
    });
});
  const screenHeight = $(window).innerHeight();
  $newMessageModal.find('input.new-message-recipient-input').on('click', function() {
    setTimeout((function() {
      const newScreenHeight = $(window).innerHeight();
      if (newScreenHeight < screenHeight) {
        return $newMessageModal.find('.new-message-recipients-list').addClass('no-maxheight');
      }
    }), 250);
    return $(window).on('resize', function() {
      if ($(window).innerHeight() === screenHeight) {
        return $newMessageModal.find('.new-message-recipients-list').removeClass('no-maxheight');
      }
    });
  });

  return $('.mark-as-read-button').on('click', function() {
    return $(this).closest('.messages-list-container').find('.message.new').removeClass('new');
  });
});
