/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const initChannel = function() {
  if (!$('.nav-user').length) { return; }
  if (window.NotificationsChannelSubscribed) { return; }
  return App.messages = App.cable.subscriptions.create({ channel: 'NotificationsChannel' },
  {
    connected() {
      return window.NotificationsChannelSubscribed = true;
    },
    disconnected() {
      return window.NotificationsChannelSubscribed = false;
    },
    received(data) {
      return $('nav.navigation .nav-user').replaceWith(data.message);
    }
  });
};


$(document).on('turbolinks:load', () => initChannel());

$(document).on('show.bs.modal', () => initChannel());
