/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.CategoriesInputDropDown = class CategoriesInputDropDown {
  constructor($dropdown) {
    this.$dropdown = $dropdown;
    this.$button = $dropdown.find('button');
    this.$input = $dropdown.find('.dropdown-input');
    this.$options = $dropdown.find('.dropdown-menu');
  }

  run() {
    if (!this.consistsAllElements) { return; }
    this.setValue(this.$input.length > 0 ? this.$input.val() : '');
    this.changeListener();
    return this.initCategoriesDropdownEvents();
  }

  changeListener() {
    const dropdown = this;
    return this.$options.on('click', 'li', function(e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      dropdown.setValue($(this).data('value'));
      dropdown.$dropdown.removeClass('open');
    });
  }

  consistsAllElements() {
    return this.$button.length && this.$input.length && this.$options.length;
  }

  setValue(value) {
    let svg;
    const $element = this.$options.find('li[data-value="' + value + '"]');
    if ($element.hasClass('main')) {
      svg = $element.find('svg').clone();
    } else {
      svg = $element.parent().siblings('a').find('svg').clone();
    }
    if (this.$input.length > 0) { this.$input.val(value).trigger('change'); }
    this.$button.find('svg').remove();
    this.$button.prepend(svg);
    return this.$button.parent().attr('title', this.$button.find('svg title').text());
  }

  initCategoriesDropdownEvents() {
    const dropdown = this;
    this.$dropdown.find('div.trigger').on('click', function(e) {
      $(this).closest('.main').toggleClass('opened');
      const current = $(this).next();
      const grandparent = $(this).parent().parent();
      grandparent.find('.subcategory:visible').not(current).hide();
      grandparent.find('.main').not(this.parentElement).removeClass('opened');
      current.toggle();
      return e.stopPropagation();
    });

    return this.$dropdown.on('show.bs.dropdown', function() {
      dropdown.$options.find('.subcategory').hide();
      return dropdown.$options.find('.main').removeClass('opened');
    });
  }
};
