/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let initDatepicker = undefined;
  let getInvalidDates = undefined;
  let toDate = undefined;
  let isInvalid = undefined;
  const Cls = (window.TradeDaterangePicker = class TradeDaterangePicker extends window.DaterangePicker {
    static initClass() {
      this.prototype.FORMAT = 'YYYY-MM-DD';
  
      initDatepicker = function($datepickerField) {
        const invalidDates = getInvalidDates($datepickerField);
        return $datepickerField.daterangepicker({
          autoUpdateInput: false,
          minDate: new Date(),
          isInvalidDate(date) {
            if (invalidDates.length === 0) { return false; }
            return isInvalid(date._d.setHours(0,0,0,0), invalidDates);
          },
          opens: 'center'
        });
      };
  
      getInvalidDates = function($input) {
        const invalidDates = $input.data('unavailableRanges');
        if (invalidDates.length === 0) { return invalidDates; }
        return _.map(invalidDates, date => ({
          start_date: toDate(date.start_date),
          end_date: toDate(date.end_date)
        }));
      };
  
      toDate = function(date) {
        date = new Date(date);
        date.setHours(0,0,0,0);
        return date;
      };
  
      isInvalid = (date, invalidDates) => _.some(invalidDates, invalidDate => (date >= invalidDate.start_date) && (date <= invalidDate.end_date));
    }

    constructor($container) {
      super($container);
    }

    build() {
      initDatepicker(this.$datepickerField);
      this.setEvents();
      return this.renderDate(this.$fromInput.val(), this.$toInput.val());
    }
  });
  Cls.initClass();
  return Cls;
})();
