var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

window.SearchFormMobile = (function(superClass) {
  var DATE_FORMAT;

  extend(SearchFormMobile, superClass);

  DATE_FORMAT = 'YYYY-MM-DD';

  function SearchFormMobile($mapContainer, navContainer, filterContainer, resultsContainer) {
    this.$navForm = $(navContainer).find('form');
    this.$filterForm = $(filterContainer).find('form');
    this.$mapContainer = $mapContainer;
    this.$resultsContainer = $(resultsContainer);
    this.$dateRangePicker = this.$filterForm.find('.date.datepicker');
    this.priceModal = new PriceModal(this.$filterForm);
    this.infiniteScroll = new ParallelInfiniteScroll(this.$resultsContainer, $(navContainer + ", " + filterContainer));
    SearchFormMobile.__super__.constructor.call(this, this.$navForm);
  }

  SearchFormMobile.prototype.init = function() {
    var $screen, that;
    this.initMap();
    that = this;
    $screen = $(window).width();
    new Promise(function(resolve, reject) {
      var location, radius;
      location = that.$navForm.find('#location').val();
      if (location === void 0) {
        location = 'Australia';
        if ($screen >= 768) {
          radius = '1000';
        } else {
          radius = '1500';
        }
      }
      return that.geocodeLocation(that.$navForm, location, null, radius, true).then(function() {
        return resolve(that.searchRequest());
      });
    });
    this.filterFormSubmit();
    this.initDatepicker();
    this.unbindNavForm();
    this.aroundMeButton();
    this.redoSearchButton();
    this.currentLocationButton();
    this.clearButton();
    this.radioButtons();
    this.showResults();
    this.showFilters();
    return SearchFormMobile.__super__.init.call(this);
  };

  SearchFormMobile.prototype.addNewMapMarkers = function(elements) {
    this.map.setMarkers(elements);
    if (elements.length && !this.searchWithLocation && !this.map.map.isMoving) {
      return this.map.fitMapResults(elements);
    }
  };

  SearchFormMobile.prototype.initMap = function() {
    if (this.$mapContainer.length > 0) {
      this.map = new MobileMapCreator(this.$mapContainer);
      return this.map.buildSearchMap();
    }
  };

  SearchFormMobile.prototype.filterFormSubmit = function() {
    var that;
    that = this;
    this.$filterForm.on('submit', function(e) {
      e.preventDefault();
      return that.search();
    });
    return this.$navForm.on('submit', function(e) {
      e.preventDefault();
      return that.search();
    });
  };

  SearchFormMobile.prototype.initDatepicker = function() {
    var that;
    this.$dateRangePicker.daterangepicker({
      opens: 'left',
      autoUpdateInput: false,
      minDate: new Date(),
      locale: {
        format: DATE_FORMAT
      }
    });
    that = this;
    this.$dateRangePicker.on('apply.daterangepicker', function(event, picker) {
      if ($(this).data('cleared')) {
        $(this).find('input.from-date-input').val('');
        $(this).find('input.to-date-input').val('');
      } else {
        $(this).find('input.from-date-input').val(picker.startDate.format(DATE_FORMAT));
        $(this).find('input.to-date-input').val(picker.endDate.format(DATE_FORMAT));
        $(this).data('cleared', false);
      }
      return that.search();
    });
    this.$dateRangePicker.on('clear.daterangepicker', function(event, picker) {
      return $(this).data('cleared', true);
    });
    this.$dateRangePicker.on('showCalendar.daterangepicker', function(event, picker) {
      if (!$(this).data('initial')) {
        $(this).data('cleared', false);
      }
      return $(this).data('initial', false);
    });
    this.$dateRangePicker.on('show.daterangepicker', function(event, picker) {
      if ($(this).data('cleared')) {
        return $(this).data('daterangepicker').clickClear();
      }
    });
    return this.$dateRangePicker.on('hide.daterangepicker', function(event, picker) {
      return $(this).data('initial', true);
    });
  };

  SearchFormMobile.prototype.search = function(afterSuccess) {
    var that;
    that = this;
    if (this.$navForm.find('#location').val()) {
      return new Promise(function(resolve, reject) {
        return (this.geocodeLocation(this.$navForm, this.$navForm.find('#location').val(), null, true)).then(function() {
          return resolve(that.searchRequest(afterSuccess));
        });
      });
    } else {
      return this.searchRequest(afterSuccess);
    }
  };

  SearchFormMobile.prototype.searchRequest = function(afterSuccess) {
    var formData, that;
    that = this;
    if (this.map) {
      formData = this.$filterForm.serializeArray().concat(this.$navForm.serializeArray());
      return $.ajax({
        url: that.$filterForm.attr('action'),
        dataType: 'json',
        data: formData,
        success: function(data) {
          var favorites;
          if (data.invalid_csrf) {
            window.location.replace(data.url);
          } else {
            that.addNewMapMarkers(data.map_results);
            that.$resultsContainer.find('.results-wrapper').html(data.results);
            if (afterSuccess !== void 0) {
              afterSuccess(that.map);
            }
            that.initOwlCarousels();
            favorites = new ButtonSwitchEvents(that.$resultsContainer, 'favourite');
          }
          return $('.redo-search i').removeClass('rotate');
        }
      });
    }
  };

  SearchFormMobile.prototype.unbindNavForm = function() {
    return this.$navForm.removeAttr('action');
  };

  SearchFormMobile.prototype.aroundMeButton = function() {
    var that;
    that = this;
    return this.$filterForm.siblings('.around-me').on('ajax:success', function(event, data) {
      var favorites;
      that.addNewMapMarkers(data.map_results);
      that.$resultsContainer.find('.results-wrapper').html(data.results);
      that.initOwlCarousels();
      return favorites = new ButtonSwitchEvents(that.$resultsContainer, 'favourite');
    });
  };

  SearchFormMobile.prototype.initOwlCarousels = function() {
    var left, right;
    left = this.$resultsContainer.find('.images-carousel').data('left');
    right = this.$resultsContainer.find('.images-carousel').data('right');
    return this.$resultsContainer.find('.images-carousel .images').owlCarousel({
      loop: true,
      nav: true,
      navText: [left, right],
      items: 1,
      touchDrag: false
    });
  };

  SearchFormMobile.prototype.redoSearchButton = function() {
    var that;
    that = this;
    return this.$mapContainer.siblings('.redo-search').on('click', function() {
      $('.redo-search i').addClass('rotate');
      that.updateGeographicCoordinates();
      return that.search();
    });
  };

  SearchFormMobile.prototype.panToLocation = function() {
    var location;
    if (location = Cookies.get('lat_lng')) {
      return this.geocodeLocation(this.$navForm, location);
    }
  };

  SearchFormMobile.prototype.currentLocationButton = function() {
    var that;
    that = this;
    return this.$mapContainer.siblings('.map-buttons').find('.location').on('click', function() {
      if (Cookies.get('lat_lng')) {
        return that.panToLocation();
      } else {
        return alert('Enable your location to use this feature');
      }
    });
  };

  SearchFormMobile.prototype.clearButton = function() {
    var that;
    that = this;
    return this.$filterForm.closest('.modal-dialog').find('.clear').on('click', function() {
      that.$filterForm.trigger('reset');
      return that.priceModal.clearInputs();
    });
  };

  SearchFormMobile.prototype.radioButtons = function() {
    var that;
    that = this;
    return this.$filterForm.find('.radio').on('click', function() {
      return that.$filterForm.find('.radio').not($(this)).find('input').prop('checked', false);
    });
  };

  SearchFormMobile.prototype.showResults = function() {
    return this.$mapContainer.siblings('.map-buttons').find('.list').on('click', function() {
      var $height;
      $height = $(window).width() < 480 ? 310 : 418;
      if ($('body').hasClass('results-shown')) {
        $('body').removeClass('results-shown');
        $('.redo-search').animate({
          top: '15px'
        }, 200, 'linear');
        return $('.map-buttons').animate({
          bottom: '15px'
        }, 200, 'linear');
      } else {
        $('body').addClass('results-shown');
        $('.redo-search').animate({
          top: 15 - $height
        }, 200, 'linear');
        return $('.map-buttons').animate({
          bottom: 15 + $height
        }, 200, 'linear');
      }
    });
  };

  SearchFormMobile.prototype.showFilters = function() {
    return this.$mapContainer.siblings('.map-buttons').find('.filters button').on('click', function() {
      return setTimeout((function() {
        return $('#filter-modal').modal('toggle');
      }), 25);
    });
  };

  SearchFormMobile.prototype.updateGeographicCoordinates = function() {
    var bounds, northEastLat, northEastLng, southWestLat, southWestLng;
    bounds = this.map.map.getBounds();
    southWestLat = bounds.getSouthWest().lat();
    southWestLng = bounds.getSouthWest().lng();
    northEastLat = bounds.getNorthEast().lat();
    northEastLng = bounds.getNorthEast().lng();
    return this.setNewSearchBounds(this.$navForm, southWestLat, southWestLng, northEastLat, northEastLng);
  };

  return SearchFormMobile;

})(window.NavigationSearchForm);

// ---
// generated by coffee-script 1.9.2
