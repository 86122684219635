/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.BannerMap = class BannerMap {
  constructor($container) {
    this.$container = $container;
    this.$map = this.$container.find('#map');
    this.$image = this.$container.find('.ts-banner');
    this.mapInitialized = false;
    this.init();
  }

  init() {
    this.$map.hide();
    return this.toggleButtonAction();
  }

  toggleButtonAction() {
    const bannerMap = this;
    return bannerMap.$container.find('.banner-map').on('click', function(e) {
      bannerMap.$container.find('.banner-map .map').toggleClass('hidden');
      bannerMap.$container.find('.banner-map .banner').toggleClass('hidden');
      if (bannerMap.$image.is(':visible')) {
        return bannerMap.openMap();
      } else {
        return bannerMap.openBanner();
      }
    });
  }

  openMap() {
    this.$image.hide();
    this.$map.show();
    if (!this.mapInitialized) {
      this.mapInitialized = true;
      const address = { lat: this.$map.data('lat'), lng: this.$map.data('lng') };
      const areaMap = new MapCreator($('#map'));
      return areaMap.buildAreaMap(address);
    }
  }

  openBanner() {
    this.$image.show();
    return this.$map.hide();
  }
};
