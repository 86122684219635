/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const $container = $('.listing-page');
  if (!$container.length) { return; }

  return $(document).on('ajax:success', '#new_conversation', (e, response) => new ModalInvoker(response.html));
});