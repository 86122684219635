/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  if (!$('.delivery-status')) { return; }
  return $(document).on('ajax:success', '.js-details', function(_event, response) {
    const modalInvoker = new ModalInvoker(response.data);
    const $modal = $(modalInvoker.modal);
    return $modal.find('.single-item-summary i').click(function() {
      const $icon = $(this);
      if ($icon.hasClass('fa-plus-square')) {
        $icon.removeClass('fa-plus-square');
        $icon.addClass('fa-minus-square');
      } else {
        $icon.removeClass('fa-minus-square');
        $icon.addClass('fa-plus-square');
      }
      return $icon.siblings('.single-item-details-data').slideToggle();
    });
  });
});
