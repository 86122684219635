/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.FormImageUploader = class FormImageUploader {
  constructor($container) {
    this.$container = $container;
    this.$imageUploaders = this.$container.find('.dropzone-uploader');
    this.uploaders = [];
    this.init();
  }

  init() {
    const uploaders = [];
    return _.each(this.$imageUploaders, imageUploader => uploaders.push(new ImageUploader(imageUploader, uploaders)));
  }
};

var ImageUploader = (function() {
  let backgroundColorEvents = undefined;
  let elementsSize = undefined;
  ImageUploader = class ImageUploader {
    static initClass() {
  
      backgroundColorEvents = function($ele) {
        let counter = 0;
        return $ele.bind({
          dragenter(ev) {
            ev.preventDefault();
            counter++;
            return $(this).css({'background-color': '#a7c6e0'});
          },
          dragleave() {
            counter--;
            if (counter === 0) {
              return $(this).css({'background-color': ''});
            }
          },
          drop() {
            counter = 0;
            return $(this).css({'background-color': ''});
          }
        });
      };
  
      elementsSize = function($imageThumb, $overshadow, $ele, uploaders) {
        $ele.find('.checkbox').addClass('on-picture');
        $ele.find('.drag-drop-info span').addClass('on-picture');
        $ele.find('.size-info span').addClass('on-picture');
        const referenceEle = uploaders.length && (uploaders[0].name === 'ItemPicture') ?
                         uploaders[0].$container
                       :
                         $ele;
        $imageThumb.css('width', referenceEle.width());
        $imageThumb.css('height', referenceEle.height());
        $overshadow.css('width', referenceEle.width());
        return $overshadow.css('height', referenceEle.height());
      };
    }
    constructor(container, uploaders) {
      this.$container = $(container);
      this.$input = this.$container.find('input:file');
      this.$imageThumb = this.$container.find('.show-image-thumb');
      this.$cacheInput = this.$container.find('input.hidden');
      this.$loader = this.$container.find('.loader');
      this.$overshadow = this.$container.find('.overshadow');
      this.$errorBox = this.$container.find('.error-message');
      this.uploaders = uploaders;
      this.name = this.$input.data('className');
      this.init();
    }

    init() {
      backgroundColorEvents(this.$container);
      elementsSize(this.$imageThumb, this.$overshadow, this.$container, this.uploaders);

      const url = this.$input.data('url');
      return this.$input.fileupload({
        url,
        paramName: 'file',
        maxFileSize: this.$input.data('size'),
        acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
        formData() { let AUTH_TOKEN;
        return {
          'authenticity_token': (AUTH_TOKEN=$('meta[name="csrf-token"]').attr('content'))
        }; },
        dropZone: this.$container}).on('fileuploadadd', (e, data) => {
        this.$loader.css('display', 'block');
        this.$overshadow.css('display', 'block');
        return data.formData = { class_name: this.name };
      })
      .on('fileuploaddone', (e, data) => {
        this.$loader.css('display', 'none');
        this.$overshadow.css('display', 'none');
        this.$cacheInput.val(data.result.image_cache);
        return this.$imageThumb.attr('src', data.result.uploader_url);
    }).on('fileuploadprocessfail', (e, data) => {
        toastr.error(data.files[0].error);
        this.$loader.css('display', 'none');
        return this.$overshadow.css('display', 'none');
      });
    }
  };
  ImageUploader.initClass();
  return ImageUploader;
})();

  //#ADD CHECKBOXES EVENTS FOR CACHE COPY
