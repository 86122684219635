/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.PopoversInitializer = class PopoversInitializer {
  init() {
    const $document = $(document);
    const initializer = this;
    this.initPopovers();
    $document.ajaxComplete(() => initializer.initPopovers());
    $document.on('hide.bs.modal', function() {
      $('[data-toggle=popover]').popover('destroy');
      return initializer.initPopovers();
    });
    return $document.on('hide.bs.tab', function() {
      $('[data-toggle=popover]').popover('destroy');
      return initializer.initPopovers();
    });
  }

  initPopovers() {
    return $('[data-toggle=popover]').popover({
      html: true,
      container: 'body',
      title() {
        const title = `#${$(this).data('popover-title')}`;
        return $(title).html();
      },
      content() {
        const content = `#${$(this).data('popover-content')}`;
        return $(content).html();
      }
    }).on('inserted.bs.popover', function() {
      const divClass = $(this).data('div-class');
      const popoverClass = $(this).data('popover-class');
      return $(`.popover ${divClass}`).parent().parent().addClass(popoverClass);
    });
  }
};
