/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.ModalInvoker = class ModalInvoker {
  constructor(content) {
    this.content = content;
    this.init();
  }

  init() {
    const $visibleModals = $('.modal:visible');
    if (!$visibleModals.hasClass('reviews-modal')) {
      if ($visibleModals.length > 0) { $visibleModals.modal('hide'); }
    }

    this.appendModalNode();
    return this.modalEvents();
  }

  appendModalNode() {
    const div = document.createElement('div');
    div.innerHTML = this.content;
    this.modal = div.firstElementChild;
    return $('body')[0].appendChild(this.modal);
  }

  modalEvents() {
    const $modal = $(this.modal).modal('toggle');
    return $modal.on('hidden.bs.modal', function() {
      this.remove();
      if ($('.reviews-modal').length > 0) {
        return $('body').addClass('modal-open');
      }
    });
  }
};
