/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("turbolinks").start()
require("channels")
require("jquery")
require("@nathanvda/cocoon")
require("jquery-ui/ui/widgets/autocomplete")

import 'jquery-ujs'
import 'jquery-ui'
import 'bootstrap'
import 'turbolinks'
import 'picturefill'
import 'lodash'
import 'moment'
import 'daterangepicker'
import 'js.cookie'
import 'jquery-form'
import 'jquery-file-upload'
import '../../../vendor/assets/javascripts/jquery.fileupload-process'
import '../../../vendor/assets/javascripts/jquery.fileupload-validate'
import 'owl.carousel'

function importAll(r) { r.keys().forEach(r); }
// importAll(require.context('../../assets/javascripts/lib/daterange_pickers', true));
importAll(require.context('../lib', true))

import '../likes'
import '../reviews'
import '../profiles'
import '../reports'
import '../follows'
import '../custom'
import '../user_card'
import '../landing_page'
import '../global_modals'
import '../share_popover'
import '../filter_modals'
import '../filter_modals_desktop'
import '../share_modal'
import toastr from 'toastr/toastr'
import 'clipboard'
import 'nouislider'
import 'wnumb'
import '../cable'
import 'load-image'
import 'cropper'
import 'next-tick'
import '../../../vendor/assets/javascripts/character-counter'
import '../search/listings_filter_form'
import '../search/search'
import 'baguettebox.js'
import '../google_analytics'
import 'marker-clusterer-plus'

importAll(require.context('../../../vendor/assets/javascripts/google_maps', true))
importAll(require.context('../trader_space_wizard/new_wizard', true))
importAll(require.context('../trader_space/desktop', true))
importAll(require.context('../trader_space/shared', true))
importAll(require.context('../listing/shared', true))
importAll(require.context('../private_profile', true))
importAll(require.context('../public_profile', true))
importAll(require.context('../welcome', true))
importAll(require.context('../cart', true))
importAll(require.context('../order', true))
import '../listing_page'

window.mobileWidth = 1280

window.toastr = toastr

toastr.options = {
    'preventDuplicates': true
}

// Init Stimulus
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

window.Stimulus = Application.start();
const context = require.context('../controllers', true, /\.js$/);
window.Stimulus.load(definitionsFromContext(context));
window.Stimulus.debug = process.env.NODE_ENV === 'development';
