/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const clearPageAndSubmitForm = function($form, $announcementsList) {
  $form.find('input#page').val(null);
  return $form.submit();
};

const getFormData = function($form) {
  $form.find('input#page').val(null);
  const array = $form.serializeArray();
  const hash = {};
  $.map(array, (n, i) => hash[n['name']] = n['value']);
  return hash;
};

const bindButtonSwitchEventsToLoveButtons = () => _.each($('.js-love'), function(loveButton) {
  let likeEvents;
  return likeEvents = new ButtonSwitchEvents($(loveButton), 'like');
});

// It fixes strange issue with multiple 'click' events binded to the same element
const bindDestroyEventToDeleteButtons = ($list, buttonSelector, $navCounter) => _.each($(buttonSelector), function(destroyButton) {
  $(destroyButton).off('click');
  return $(destroyButton).bind('click', function(e) {
    e.preventDefault();
    return destroyAnnouncement($list, $(this), $navCounter);
  });
});

const postNewAnnouncement = ($form, $list, $textbox, $navCounter, counter) => $.ajax({
  dataType: 'json',
  method: 'POST',
  url: $form.data('url'),
  data: getFormData($form),
  success(data) {
    $textbox.val('');
    counter.resetCounter();
    toastr.success(data.notice);
    $list.html(data.html);
    bindDestroyEventToDeleteButtons($list, '.js-destroy-announcement', $navCounter);
    return updateCounter($navCounter, data.counter);
  },
  error(data) {
    if (data.responseJSON) { return toastr.error(data.responseJSON.notice); }
  }
});

var destroyAnnouncement = ($list, $destroyButton, $navCounter) => $.ajax({
  dataType: 'json',
  method: 'DELETE',
  url: $destroyButton.data('url'),
  data: {
    page: $list.find('.current-page').data('page')
  },
  success(data) {
    // $("#announcement-#{data.announcement_id}").remove()
    $list.html(data.html);
    updateCounter($navCounter, data.counter);
    bindDestroyEventToDeleteButtons($list, '.js-destroy-announcement', $navCounter);
    return toastr.success(data.notice);
  },
  error(data) {
    if (data.responseJSON) { return toastr.error(data.responseJSON.notice); }
  }
});

var updateCounter = ($navCounter, counter) => $navCounter.text(counter);

$(document).on('turbolinks:load', function() {
  const $container = $('#announcements');
  if (!$container.length) { return; }

  const $newAnnouncementForm = $container.find('#new-announcement');
  const $filterCheckbox = $('.announcement-filter');
  const $announcementsList = $('.announcements-list');
  const $filtersForm = $('.announcements-filters');
  const $myOwnFilterCheckbox = $('.my-own').find('input[type=checkbox]');
  const $announcementTextbox = $('#announcement_content');
  const $newAnnouncementSubmitButton = $('.js-submit-button');
  const $announcementsCounter = $('.js-private-profile-nav .js-nav-announcements-count');
  let timeout = 500;

  const $cnt = $('#announcement_content');
  const counter = $cnt.characterCounter({
    max: $cnt.data('limit'),
    textarea: true
  });

  const keys = [8, 37, 38, 39, 40]; // backspace & up/right/down/left arrows
  $cnt.on('focus keydown', function(e) {
    if ($(this).val().length >= $(this).data('limit')) {
      if (!keys.includes(e.which)) {
        return e.preventDefault();
      }
    }
  });

  // New announcement form
  $newAnnouncementSubmitButton.on('click', function(e) {
    e.preventDefault();
    if ($newAnnouncementForm.find('.over-limit').length) { return false; }

    // Before post new announcement, fetch own announcements
    $myOwnFilterCheckbox.prop('checked', true);
    return $.ajax({
      dataType: 'json',
      method: 'GET',
      url: $filtersForm.data('url'),
      data: getFormData($filtersForm),
      success(data) {
        $announcementsList.html(data.html);
        bindDestroyEventToDeleteButtons($announcementsList, '.js-destroy-announcement', $announcementsCounter);
        bindButtonSwitchEventsToLoveButtons();
        return postNewAnnouncement($newAnnouncementForm, $announcementsList, $announcementTextbox, $announcementsCounter, counter);
      }
    });
  });

  // Bind to all 'delete' buttons a destroy behaviour
  bindDestroyEventToDeleteButtons($announcementsList, '.js-destroy-announcement', $announcementsCounter);

  // Announcements filters
  $filterCheckbox.find('input[type=checkbox]').on('change', () => clearPageAndSubmitForm($filtersForm, $announcementsList));

  $filterCheckbox.find('input[type=text]').on('input', function() {
    const context = this;
    const term = $(context).val();
    clearTimeout(timeout);
    return timeout = setTimeout((() => clearPageAndSubmitForm($filtersForm, $announcementsList)), 500);
  });

  $filtersForm.on('ajax:success', function(e, response) {
    $announcementsList.html(response.html);
    bindButtonSwitchEventsToLoveButtons();
    return bindDestroyEventToDeleteButtons($announcementsList, '.js-destroy-announcement', $announcementsCounter);
  });

  // Pagination
  const paginationSelector = '#announcements .announcements-list-wrapper .pagination a';
  return new AjaxPaginator($filtersForm, paginationSelector);
});
