/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('.ts-wizard .step-location-map').length) {
    const address = $('.exact-address').text();
    const areaMap = new MapCreator($('#location-map'));
    return areaMap.buildAreaMap(address, true);
  }
});
