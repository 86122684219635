/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.MakeFeaturedButton = class MakeFeaturedButton {
  constructor($container) {
    const $button = $container.find('.make-featured');
    $button.on('ajax:success', function(event, html) {
      return $(this).parent().html(html);
    });
    $button.on('ajax:error', (event, jqXHR) => toastr.error(jqXHR.responseJSON.alert));
  }
};

$(document).on('turbolinks:load', () => $('.featured').each(function() {
  return new MakeFeaturedButton($(this));
}));
