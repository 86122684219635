/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.TradeActions = class TradeActions {
  constructor($container) {
    this.$container = $container;
    this.$changeStateButton = this.$container.find(".action-u div:not(.leave-review) .button_to");
    this.$cancelButton = this.$container.find('.action-d .button_to');
    this.$returnToMessages = this.$container.find('.return-to-messages');
    this.$reviewModalStars = this.$container.find('.modal-body .star');
    this.$reviewForm = this.$container.find('.new_review');
    this.$starNumberField = this.$reviewForm.find('.stars-number');
    this.$calendarButton = this.$container.find('.calendar');
    if (this.$calendarButton) {
      this.$tradeDatepicker = this.$container.find('.trade-datepicker');
      this.$dateForm = this.$container.find('.edit_trade');
    }
    this.$cancelLink = this.$container.find('.cancel .popover a');
    this.$changeStateElements =
      $.merge(this.$changeStateButton, $.merge(this.$reviewForm, this.$cancelLink));
    this.init();
  }

  init() {
    let conversationBox;
    if ($('#dash-buying-selling').length > 0) {
      conversationBox = new MessagesBox($('#dash-buying-selling'));
    } else if ($('#dash-lending-services').length > 0) {
      conversationBox = new MessagesBox($('#dash-lending-services'));
    } else {
      conversationBox = new MessagesBox($('#dash-new-message'));
    }
    conversationBox.run();
    const progressBar = new ProgressBar(this.$container);
    this.refreshHandler = new Refresh(this.$container);
    this.changeStateEvents();
    this.controlEvents();
    this.reviewsModalEvents();
    if (this.$calendarButton) { return this.calendarEvents(); }
  }

  changeStateEvents() {
    let tradeActions = this;
    this.$changeStateElements.on('ajax:success', function(event, data) {
      if ($('.modal-backdrop').length > 0) { $('.modal-backdrop').removeClass(); }
      tradeActions.$container.html(data.html);
      tradeActions = new TradeActions(tradeActions.$container);
      return toastr.success(data.notice);
  }).on('ajax:error', (event, jqXHR) => toastr.error(jqXHR.responseJSON.error));
    return this.$container.find('.cancel button').on('click', function() {
      return $(this).siblings('.popover').toggle();
    });
  }

  controlEvents() {
    let tradeActions = this;
    this.$returnToMessages.on('ajax:success', function(event, data) {
      let conversationActionsForm;
      tradeActions.$container.html(data.html);
      return conversationActionsForm =
          new ConversationsActionsForm(tradeActions.$container, null);
    });

    return this.refreshHandler.$refreshButton.on('ajax:success', function(event, data) {
      tradeActions.$container.html(data.html);
      return tradeActions = new TradeActions(tradeActions.$container);
    });
  }

  reviewsModalEvents() {
    const tradeActions = this;
    return this.$reviewModalStars.off('click').on('click', function(e) {
      const activeImage = $(this).parent().parent().find('a .image.active');
      activeImage.removeClass('active');
      $(this).find('.image').addClass('active');
      return tradeActions.$starNumberField.val($(this).data('starsNumber'));
    });
  }

  calendarEvents() {
    if (!(this.$tradeDatepicker.length > 0)) { return; }
    const tradeActions = this;
    const dateRangePicker = new TradeDaterangePicker(this.$tradeDatepicker);
    dateRangePicker.afterSelect(function() {
      if (!(tradeActions.$tradeDatepicker.parent().find('button').length > 0)) {
        return tradeActions.$dateForm.submit();
      }
    });
    dateRangePicker.build();
    this.$calendarButton.on('click', () => dateRangePicker.$datepickerField.trigger('click'));
    return this.$dateForm.on('ajax:success', function(event, data) {
      $(this).find('.chosen-dates span.from').text(data.start_date);
      $(this).find('.chosen-dates span.to').text(data.end_date);
      return toastr.success(data.message);
  }).on('ajax:error', (event, jqXHR) => toastr.error(data.message));
  }
};
