/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  // disabledForm = window.disableNavForm($('.nav-search-form'))
  if ($('#search-filter').length > 0) { //&& !disabledForm
    // if $(document).width() < 1281
    //   searchForm = new SearchForm($('#mobile-search-form'),
    //     $('#map'),
    //     $('#mobile-search-result-section'),
    //     true)
    // else
    const searchForm =
      new SearchForm($('#map'), '.navigation', '.filters-wrapper', '.search-results');

    const favorites = new ButtonSwitchEvents($('.results-wrapper'), 'favorite');

    $('.search-results').on('click', '.traderspace-result .type', function(event) {
      let listingsFilterForm;
      event.preventDefault();
      const $target = $(event.currentTarget);
      const exchangeType = $target.data('exchange-type');
      const traderSpaceId = $target.parent().data('trader-space');
      return listingsFilterForm = new ListingsFilterForm(traderSpaceId, exchangeType);
    });

    /* RWD CHANGE GOOGLE MAPS UI */
    // if $(document).width() < 1281
    //   options = {
    //     disableDefaultUI: true
    //     zoomControl: true,
    //     zoomControlOptions: {
    //       position: google.maps.ControlPosition.TOP_RIGHT
    //     }
    //   }
    //   searchForm.setNewMapOptions(options)

    //   ### Search page (mobile) - show/hide map ###
    //   $('.map-section').on 'click', '.show-map', ->
    //     navHeight = $('#navigation').height()
    //     mapHeight = $(window).height() - navHeight - 42
    //     $('.map-section').animate 'height': mapHeight
    //     $(this).addClass('hide-map').removeClass('show-map').text 'Hide map'

    //   $('.map-section').on 'click', '.hide-map', ->
    //     $('.map-section').animate 'height': '0'
    //     $(this).addClass('show-map').removeClass('hide-map').text 'Show map'
  } else {
    const navSearchForm = new NavigationSearchForm($('#nav-search-form'));
  }


  $('.filters-form .apply-changes').click(function() {
    return $(this).closest('.modal').modal('hide');
  });

  new ItemRenderer($('.listing-modal'));

  const showMap = function() {
    const $screenWidth = $(window).width();
    if (($screenWidth < 1281) && $('.show-map .switch input').is(':checked')) {
      $('.show-map .switch').trigger('click');
    } else if (($screenWidth >= 1281) && !$('.show-map .switch input').is(':checked')) {
      $('.show-map .switch').trigger('click');
    }
    
    if (($screenWidth < 1281) && !$('.show-map .switch input').is(':checked')) {
      return $('.map-section').hide();
    }
  };

  showMap();
  return $(window).on('resize', function() {
    if ($(window).width() <= 1281) {
      return showMap();
    }
  });
});
  