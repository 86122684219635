/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const clearPageAndSubmitForm = function($form) {
  $form.find('input#page').val(null);
  return $form.submit();
};

const followingsCount = value => '(' + value + ')';

const updateCommunityCounter = function($communityCounterElement, value) {
  if ($communityCounterElement.length) {
    return $communityCounterElement.text(value);
  }
};

$(document).on('turbolinks:load', function() {
  const $container = $('.profile-community');
  if (!$container.length) { return; }

  const $filter = $('.js-community-filter');
  const $filtersForm = $('.js-community-follows-filter-form');
  const $resultsContainer = $('.js-community-results');
  const $communityCounter = $('.js-private-profile-nav .js-community-counter');
  const $followingsCounter = $('.js-following-counter');
  const $announcementsCounter = $('.js-private-profile-nav .js-nav-announcements-count');
  let timeout = 500;

  // Community filters
  $filter.find('input[type=checkbox]').on('change', () => clearPageAndSubmitForm($filtersForm));

  $filter.find('input[type=checkbox]').on('click', function(e) {
    if (!$(this).closest('form').find('input:checked').length) { return e.preventDefault(); }
  });

  $filter.find('input[type=text]').on('input', function() {
    const context = this;
    const term = $(context).val();
    clearTimeout(timeout);
    return timeout = setTimeout((() => clearPageAndSubmitForm($filtersForm)), 500);
  });

  $filtersForm.on('ajax:success', (e, response) => $resultsContainer.html(response.results));

  // Follow/unfollow button on user card
  $(document).on('ajax:success', '.new_follow', function(e, response) {
    if (response['followings_count']) {
      $followingsCounter.html(followingsCount(response.followings_count));
    }
    if (response['announcements_count']) {
      $announcementsCounter.html(response.announcements_count);
    }
    updateCommunityCounter($communityCounter, response['community_count']);
    $(this).replaceWith(response.html);
    return clearPageAndSubmitForm($filtersForm);
  });

  $(document).on('ajax:success', '.js-unfollow', function(e, response) {
    if (response['followings_count'] !== undefined) {
      $followingsCounter.html(followingsCount(response.followings_count));
    }
    if (response['announcements_count']) {
      $announcementsCounter.html(response.announcements_count);
    }
    updateCommunityCounter($communityCounter, response['community_count']);
    $(this).parent().replaceWith(response.html);
    return clearPageAndSubmitForm($filtersForm);
  });

  // Conversation modal
  $container.on('ajax:success', '.new_conversation', function(_event, response) {
    if ($('body').hasClass('modal-open')) { return; }

    return new ModalInvoker(response.html);
  });

  const paginationSelector = '.profile-community .results .results-wrapper .pagination a';
  return new AjaxPaginator($filtersForm, paginationSelector);
});
