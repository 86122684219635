/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const $container = $('.listing-page');
  if (!$container.length) { return; }

  return $(document).on('click', ".listing-page .faq i[class$='-square']", function() {
    if ($(this).hasClass('fa-plus-square')) {
      $(this).removeClass('fa-plus-square').addClass('fa-minus-square');
    } else {
      $(this).removeClass('fa-minus-square').addClass('fa-plus-square');
    }
    return $(this).closest('.faq-item').children('.answer').toggle(100);
  });
});