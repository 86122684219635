/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
if (window.dataLayer !== undefined) {
  $(document).on('turbolinks:load', function() {
    const pathnameWithoutParams = location.pathname.split('?')[0];
    const locationWithoutParams = location.origin + pathnameWithoutParams;
    return gtag('config', window.google_analytics_tracker, {
      'page_location': locationWithoutParams,
      'page_path': pathnameWithoutParams
      });
  });
}
