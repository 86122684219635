/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.InputDropDown = class InputDropDown {
  constructor($dropdown) {
    this.$button = $dropdown.find('button');
    this.$dropdown = $dropdown;
    this.$input = $dropdown.find('.dropdown-input');
    this.$options = $dropdown.find('.dropdown-menu');
    this.$searchInput = $dropdown.find('.search-input');
  }

  run() {
    if (this.consistsAllElements) {
      this.setValue(this.$input.length > 0 ? this.$input.val() : '');
      this.changeListener();
      return this.filterDropdown();
    }
  }

  afterSelect(f) {
    return this.$afterSelect = f;
  }

  callAfterSelect() {
    if (this.$afterSelect === undefined) { return; }
    return this.$afterSelect();
  }

  changeListener() {
    const dropdown = this;
    return this.$options.on('click', 'li', function(e) {
      e.preventDefault();
      dropdown.setValue($(this).data('value'));
      dropdown.callAfterSelect();
      dropdown.clearSearchInput();
    });
  }

  consistsAllElements() {
    return this.$button.length && this.$input.length && this.$options.length;
  }

  reset() {
    this.$input.val('');
    const $labelSpan = this.$button.find('span:first-child');
    $labelSpan.addClass('placeholder');
    return $labelSpan.text($labelSpan.data('placeholder'));
  }

  setValue(value) {
    if (value === '') { return; }
    const text = this.$options.find('li[data-value="' + value + '"]').find('a').html();
    if (this.$input.length > 0) { this.$input.val(value).trigger('change'); }
    this.$button.find('i').css({'display': 'none'});
    this.$button.find('img').css({'display': 'none'});
    this.$button.find('span:first-child').html(text);
    this.$button.find('span:first-child').removeClass();
    return this.$button.find('span:first-child').addClass(value);
  }

  filterDropdown() {
    if (this.$searchInput.length) { // when dropdown is searchable
      const dropdown = this;

      this.$dropdown.on('shown.bs.dropdown', () => dropdown.$searchInput.focus());
      return this.$searchInput.on('input', function() {
        let resultsPresent = false;
        const noResultsTextElement = dropdown.$options.find('.no-results-text');
        if (noResultsTextElement.length) { noResultsTextElement.remove(); }

        const searchValue = this.value.toUpperCase();
        const dropdownElements = dropdown.$options.find('li');
        $.map(dropdownElements, function(element) {
          const elementText = (element.textContent || element.innerText).toUpperCase();
          if (elementText.indexOf(searchValue) > -1) {
            element.style.display = 'block';
            return resultsPresent = true;
          } else {
            return element.style.display = 'none';
          }
        });

        if (!resultsPresent) {
          const noResultsText = $(this).data('noResultsText');
          return dropdown.$options.append(
              `<span class='no-results-text'>${noResultsText}</span>`
          );
        }
      });
    } else {
      return this.$dropdown.keypress(function(event) {
        const expression = new RegExp('^' + event.key, 'i');
        let filtered = false;

        return $(this).find('li').filter(function() {
          if (!filtered) {
            if (expression.test($.trim($(this).text()))) {
              const position = $(this).index() * 26;
              $(this).parent().scrollTop(position);
              return filtered = true;
            }
          }
        });
      });
    }
  }

  clearSearchInput() {
    if (this.$searchInput.length) { return this.$searchInput.val('').trigger('input'); }
  }
};

$(document).on('turbolinks:load', () => $('.dropdown-helper').each(function() {
  const dropdown = new InputDropDown($(this));
  return dropdown.run();
}));
