/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  let uploader;
  if (!$('.step-photos').length) { return; }

  return uploader = new ListingsImageUploader($('.step-photos'));
});
