/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const $feedback = $('.feedback-container');
  if (!$('.feedback-container')) { return; }
  const $stars = $feedback.find('a.star');
  $stars.click(function() {
    $stars.removeClass('js-active');
    const $element = $(this);
    $element.addClass('js-active');
    const starsNumber = $element.data('stars-number');
    $feedback.find('.new_review input#review_stars').val(starsNumber);
    return $feedback.find('.new_review input[type=submit]').prop('disabled', false);
  });
  const starsNumber = $feedback.find('.review_stars input').val();
  return $feedback.find('a[data-stars-number="' + starsNumber + '"]')
    .addClass('js-active');
});
