/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  $('#report-modal').on('show.bs.modal', event => $.ajax({
    type: 'GET',
    url: $(event.relatedTarget).data('url'),
    dataType: 'json',
    success(data){
      return $('#report-modal').find('.modal-content').html(data.html);
    }
  }));

  $(document).on('change', '#report-form input', function() {
    return $(this).closest('form').find("input[type='submit']").removeAttr('disabled');
  });

  $(document).on('ajax:success', '#report-form', function(event, jqXHR) {
    const $modalContent = $(this).closest('.modal-content');
    $modalContent.html(jqXHR.html).addClass('sent');
    if (jqXHR.reportable_type === 'TraderSpace') {
      return $modalContent.find('.submit-button').on('click', () => window.location.replace(jqXHR.redirect));
    } else {
      return $(`#reportable-${jqXHR.reportable_id}`).remove();
    }
  });

  $(document).on('ajax:error', '#report-form', function(event, jqXHR) {
    return $(this).closest('.modal-content').html(jqXHR.responseJSON.html);
  });

  return $(document).on('click', '#report-modal .submit-button', () => location.reload());
});
