/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.ListingsImageUploader = class ListingsImageUploader {
  constructor($container) {
    this.$container = $container;
    this.$inputs = this.$container.find('input:file').not('.file-cache');
    this.$uploadButton = this.$container.find('.uploader');
    this.init();
  }

  init() {
    this.findActiveImageFields();
    this.clickInput();
    this.uploadButtonClick();
    this.destroyImageEvent();
    return this.enableInputsOnSubmit();
  }
  
  clickInput() {
    const that = this;
    return this.$activeInput.on('click', function(e) {
      $(this).val('');
      return $(this).on('change', function(event) {
        event.preventDefault();
        const $activeInput = $(event.target);
        const $activeImageTag = $activeInput.closest('.content').find('img');
        const $activeLoader = $activeInput.closest('.content').find('.loader');
        event.stopPropagation();
        const reader = new FileReader();
        reader.onload = function(e) {
          $activeImageTag.css('width', '100%');
          $activeImageTag.attr('src', e.target.result);
          that.destroyImageEvent();
          $(event.target).closest('.content').find('.destroy').show();
          $(event.target).closest('.content').find('.js-picture-destroy').val('0');
          that.$uploadButton.addClass('upload-disabled');
          return $activeLoader.addClass('hidden');
        };
        reader.readAsDataURL(event.target.files[0]);
        if (navigator.userAgent.indexOf('Firefox') === -1) {
          return $activeInput[0].files = event.target.files;
        }
      });
    });
  }

  uploadButtonClick() {
    const that = this;
    return this.$uploadButton.on('click', e => {
      e.preventDefault();
      this.findActiveImageFields();
      this.$activeInput.off('click');
      this.clickInput();
      return this.$activeInput.trigger('click');
    });
  }

  findActiveImageFields() {
    const that = this;
    this.$inputs = this.$container.find('input:file');
    const emptyInputs = _.filter(this.$inputs, function(input) {
      const img = $(input).closest('.content').find('img');
      return (img.width() === 0) || (img.attr('src') === '');
    });
    this.$inputs.attr('disabled', 'disabled');
    $(emptyInputs[0]).removeAttr('disabled');
    this.$activeInput = $(emptyInputs[0]);
    this.$activeImageTag = this.$activeInput.closest('.content').find('img');
    return this.$activeLoader = this.$activeInput.closest('.content').find('.loader');
  }

  destroyImageEvent() {
    const that = this;
    return this.$container.find('.destroy').on('click', function(event) {
      const $destroyButton = $(event.currentTarget);
      const $activeImageContainer = $destroyButton.closest('.content');
      $activeImageContainer.find('img').width(0);
      $activeImageContainer.find('img').attr('alt', '');
      $activeImageContainer.find('.file').removeAttr('value');
      $activeImageContainer.find('.js-picture-destroy').val('1');
      $activeImageContainer.find('.destroy').hide();
      that.$inputs.off('change');
      that.$uploadButton.off('click');
      that.uploadButtonClick();
      if (navigator.userAgent.indexOf('Firefox') === -1) {
        return $destroyButton.closest('.content').find('input:file')[0].files[0] = null;
      }
    });
  }

  enableInputsOnSubmit() {
    this.$form = this.$container.closest('form');
    const that = this;
    return this.$form.on('submit', () => that.$inputs.removeAttr('disabled'));
  }
};

