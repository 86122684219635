/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const $container = $('#share-modal');
  if (!$container.length) { return; }

  const copyLink =
    new CopyLink($container.find('.copy-link'), $container);

  $container.find('.messenger').on('click', () => FB.ui({
    method: 'send',
    link: window.location.href
  }));

  return $(document).on('ajax:success', '#share-by-email-form', function(event, data) {
    $('#email-share-modal').modal('hide');
    $('#email-share-modal input').val('');
    $('#email-share-modal textarea').val('');
    return toastr.success(data.message, data.title);
  });
});
