/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.Marker = class Marker {
  constructor(element, mapCreator) {
    this.mapCreator = mapCreator;
    this.markerId = element.id;
    this.initGoogleMapsRichMarker(element);
    this.fetchListingsUrl = $(element.marker_html).find('.types').data('fetch-url');
    this.initInfoBubbles();
    this.closeInfoBubble(this.infoBubble);
  }

  initGoogleMapsRichMarker(element) {
    this.marker = new RichMarker({ visible: true });
    this.marker.setPosition(new (google.maps.LatLng)(element.lat, element.lng));
    this.marker.setContent(element.marker_html);
    this.marker.setFlat(true);
    this.infoBubble = new InfoBubble({
      content: element.info_bubble_html,
      disableAutoPan: true,
      shadowStyle: 'none',
      hideCloseButton: true,
      maxHeight: 300,
      maxWidth: 320,
      minWidth: 320,
      minHeight: 340,
      padding: 0,
      borderWidth: 0,
      borderRadius: 0
    });
    this.infoBubble.setBackgroundClassName('map-popover traderspace traderspace-popover');
    const that = this;
    return this.infoBubble.addListener('domready', function() {
      let favorite;
      return favorite = new ButtonSwitchEvents($(that.infoBubble.content_).find('.favorite'), 'favorite');
    });
  }

  closeInfoBubble(infoBubble) {
    return $(document).off(infoBubble).on('click', () => infoBubble.close());
  }

  initInfoBubbles() {
    const that = this;
    $(document).on('click', `#element-traderspace-${this.markerId} .name`, () => that.infoBubble.open(that.mapCreator.map, that.marker));
    return $(document).on('click', `#element-traderspace-${this.markerId} .type`, event => that.showListings($(event.currentTarget)));
  }

  remove() {
    $(document).off('click', `#element-traderspace-${this.markerId}`);
    $(document).off('click', `#element-traderspace-${this.markerId} .name`);
    $(document).off('click', `#element-traderspace-${this.markerId} .type`);
    this.marker.setMap(null);
    return delete this;
  }
  showListings($element) {
    const that = this;
    const type = $element.data('exchange-type');
    const formData = {};
    _.each($('#search-filter').serializeArray(), obj => formData[obj.name] = obj.value);
    const nsfw = formData['search_form[nsfw]'];
    return $.ajax({
      url: that.fetchListingsUrl,
      dataType: 'json',
      async: false,
      data: {
        search_form: {
          trader_space_id: that.markerId,
          [type]: true,
          listings: true,
          nsfw
        }
      },
      success(data) {
        that.infoBubbleListings = new InfoBubble({
          content: data.results,
          disableAutoPan: true,
          shadowStyle: 'none',
          hideCloseButton: true,
          maxHeight: 340,
          maxWidth: 320,
          minWidth: 320,
          minHeight: 340,
          padding: 0,
          borderWidth: 0,
          borderRadius: 0
        });
        that.infoBubbleListings.setBackgroundClassName('map-popover listing listing-popover');
        that.infoBubbleListings.open(that.mapCreator.map, that.marker);
        that.infoBubbleListings.addListener('domready', function() {
          const $container = $(that.infoBubbleListings.content_);
          const favorite = new ButtonSwitchEvents($container.find('.favorite'), 'favorite');
          if ($container.find('.listing-popover-result').length > 1) {
            const leftArrow = $container.find('.items-carousel').data('left');
            const rightArrow = $container.find('.items-carousel').data('right');
            that.$new_obj = $container.find('.items-carousel').owlCarousel({
              loop: true,
              nav: true,
              dots: false,
              mouseDrag: false,
              touchDrag: false,
              navText: [leftArrow, rightArrow],
              items: 1
            });
            return that.infoBubbleListings.setContent(that.$new_obj);
          }
        });

        return that.closeInfoBubble(that.infoBubbleListings);
      }
    });
  }

  getDraggable() {
    return this.marker.getDraggable();
  }

  getPosition() {
    return this.marker.getPosition();
  }

  getMap() {
    return this.marker.getMap();
  }

  setMap(map) {
    return this.marker.setMap(map);
  }
};
