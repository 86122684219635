/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.LandingHorizontalScroll = class LandingHorizontalScroll extends window.HorizontalScroll {
  constructor($container) {
    super($container);
  }

  recalculateScrollbar() {
    this.resultsWrapperWidth = this.$resultsContainer.width();
    return this.resultsWidth = $(this.$container).width();
  }

  recalculateThumbPosition() {
    const oldWidth = this.resultsWrapperWidth - this.resultsWidth;
    const buttonPosition = (oldWidth / this.resultsWrapperWidth) * 100;
    return this.$slider[0].noUiSlider.set(buttonPosition - 5);
  }
};
