/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(window).on('turbolinks:load', function() {
  if ($('.profile-edit-payments-subscriptions').length > 0) {
    const $container = $('.profile-edit-payments-subscriptions');
    const $newPaymentMethodModal = $container.find('.new-payment-method-modal');

    // $container.find('.payments .payment-method .default').on('click', function(event) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   const $target = $(event.currentTarget);
    //   return $.ajax({
    //     method: 'PUT',
    //     url: $target.data('url'),
    //     dataType: 'json',
    //     success(data, status, jqXHR) {
    //       toastr.success(data.message);
    //       $container.find('.payments .payment-method').removeClass('default');
    //       return $(event.currentTarget).closest('.payment-method').addClass('default');
    //     },
    //     error(jqXHR, ex) {
    //       return toastr.error(jqXHR.responseJSON.message);
    //     }
    //   });
    // });

    // $container.find('.payments .payment-method .delete').on('click', function(event) {
    //   event.preventDefault();
    //   event.stopPropagation();
    //   const $target = $(event.currentTarget);
    //   return $.ajax({
    //     method: 'DELETE',
    //     url: $target.data('url'),
    //     dataType: 'json',
    //     success(data, status, jqXHR) {
    //       return $container.find('.payments-methods').html(data.html);
    //     }
    //   });
    // });

    $container.find('.modal button.close').on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();
      return $(event.currentTarget).closest('.modal').modal('hide');
    });

    const $removeSubscriptionModal = $container.find('.remove-subscriptions-modal');
    return $container.find('.js-remove-subscriptions').on('click', function(event) {
      event.preventDefault();
      event.stopPropagation();
      $removeSubscriptionModal.modal('toggle');
      if (($removeSubscriptionModal.data('bs.modal') || {}).isShown) {
        return $.ajax({
          type: 'GET',
          url: $container.find('.subscriptions-list').data('url'),
          data: {
            slot_type: $(event.currentTarget).closest('tr').data('slot-type')
          },
          success(data) {
            return $removeSubscriptionModal.find('.slots-subscriptions-list').html(data.html);
          },
          error(jqXHR, ex) {}
        });
      }
    });
  }
});
