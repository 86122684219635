/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('.profile-feed').length > 0) {
    const $mainContainer = $('.profile-feed');
    const adminFeedFilterForm = new NormalFilterForm($mainContainer, 'admin-feed');
    adminFeedFilterForm.build();

    const $filterArea = $mainContainer.find('.filters-area');
    $filterArea.find('.radio').on('click', function() {
      return $filterArea.find('.radio').not($(this)).find('input').prop('checked', false);
    });
    $mainContainer.find('form#admin-feed-search-form').on('ajax:success', () => $mainContainer.find('.js-filter-modal').modal('hide'));

    return $mainContainer.find('form.notification-form').find('.feeds-setting').on('change', function() {
      return $(this).closest('form').submit();
    });
  }
});
