/*
 * decaffeinate suggestions:
 * DS002: Fix invalid constructor
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let DATE_FORMAT = undefined;
  let DEFAULT_LOCATION = undefined;
  const Cls = (window.SearchMobileFiltersForm = class SearchMobileFiltersForm extends window.NavigationSearchForm {
    static initClass() {
      DATE_FORMAT = 'YYYY-MM-DD';
      DEFAULT_LOCATION = '51.919438, 19.145136';
    }

    constructor($navContainer, $filterContainer, $resultsContainer) {
      this.$navForm = $navContainer.find('form');
      this.$filterForm = $filterContainer.find('form');
      this.$resultsContainer = $resultsContainer;
      this.$dateRangePicker = this.$filterForm.find('.date.datepicker');
      this.$dateResult = this.$dateRangePicker.find('.date-result span');
      this.datePlaceholder = this.$dateResult.data('placeholder');
      this.priceModal = new PriceModal(this.$filterForm);
      super(this.$navForm);
    }

    init() {
      this.filterFormSubmit();
      this.sortEvents();
      this.initDatepicker();
      this.switchButtonControl();
      return super.init();
    }

    filterFormSubmit() {
      const that = this;

      return this.$navForm.on('submit', function(e) {
        e.preventDefault();
        return that.search();
      });
    }

    initDatepicker() {
      this.$dateRangePicker.daterangepicker({
        parentEl: '.date.datepicker',
        opens: 'center',
        autoUpdateInput: false,
        minDate: new Date(),
        locale: {
          format: DATE_FORMAT
        }
      });

      const that = this;
      this.$dateRangePicker.on('apply.daterangepicker', function(event, picker) {
        if ($(this).data('cleared')) {
          $(this).find('input.from-date-input').val('');
          $(this).find('input.to-date-input').val('');
        } else {
          const startDate = picker.startDate.format(DATE_FORMAT);
          $(this).find('input.from-date-input').val(startDate);
          const endDate = picker.endDate.format(DATE_FORMAT);
          $(this).find('input.to-date-input').val(endDate);
          $(this).data('cleared', false);
          that.$dateResult.html(`${startDate} - ${endDate}`);
        }
        return that.$dateRangePicker.daterangepicker('hide');
      });

      this.$dateRangePicker.on('clear.daterangepicker', function(event, picker) {
        $(this).data('cleared', true);
        return that.$dateResult.html(that.datePlaceholder);
      });

      this.$dateRangePicker.on('showCalendar.daterangepicker', function(event, picker) {
        if (!$(this).data('initial')) { $(this).data('cleared', false); }
        return $(this).data('initial', false);
      });

      this.$dateRangePicker.on('show.daterangepicker', function(event, picker) {
        if ($(this).data('cleared')) { return $(this).data('daterangepicker').clickClear(); }
      });

      return this.$dateRangePicker.on('hide.daterangepicker', function(event, picker) {
        return $(this).data('initial', true);
      });
    }

    search(afterSuccess) {
      const that = this;
      const formData = this.$filterForm.serializeArray().concat(this.$navForm.serializeArray());

      return $.ajax({
        url: that.$filterForm.attr('action'),
        dataType: 'json',
        data: formData,
        success(data) {
          if (data.invalid_csrf) {
            return window.location.replace(data.url);
          } else {
            return that.initOwlCarousels();
          }
        },
        error(jqXHR, text, ex) {}
      });
    }

    sortEvents() {
      const that = this;
      return this.$filterForm.find('.sort .radio').on('click', function() {
        return that.$filterForm.find('.radio').not($(this)).find('input').prop('checked', false);
      });
    }

    initOwlCarousels() {
      const left = this.$resultsContainer.find('.images-carousel').data('left');
      const right = this.$resultsContainer.find('.images-carousel').data('right');
      return this.$resultsContainer.find('.listing-result .images').owlCarousel({
        loop: true,
        nav: true,
        navText: [left, right],
        items: 1
      });
    }

    switchButtonControl() {
      return this.$filterForm.find('input[type=checkbox]').click(function(event) {
        const klasses = '.types, .shipping, .options';
        if ($(this).closest(klasses).find('input[type=checkbox]:checked').length > 0) { return; }

        const $checkboxes = $(this).closest(klasses).find('input[type=checkbox]');
        if ($checkboxes.length === 2) {
          return $checkboxes.not(this).prop('checked', true);
        } else {
          return event.preventDefault();
        }
      });
    }
  });
  Cls.initClass();
  return Cls;
})();
