/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const listEvents = function($listContainer) {
  $listContainer.find('.switch').click(function(e) {
    const $target = $(this);
    if ($target.closest('.modal-body').find('input:checked').length) { return; }
    return e.preventDefault();
  });
  return $listContainer.find('.orders-table .orders-row-more i').click(function() {
    const orderId = this.dataset.id;
    const $orderItems = $(`.orders-show-more[data-order-id=${orderId}]`);
    if ($(this).hasClass('fa-plus-square')) {
      $orderItems.show();
      return $(this).removeClass('fa-plus-square').addClass('fa-minus-square');
    } else {
      $orderItems.hide();
      return $(this).removeClass('fa-minus-square').addClass('fa-plus-square');
    }
  });
};

const clearPageAndSubmitForm = function($form) {
  $form.find('input#page').val(null);
  return $form.submit();
};

$(document).on('turbolinks:load', function() {
  const $listContainer = $('.orders-list');
  if (!$listContainer.length) { return; }
  const $searchForm = $listContainer.find('form.order-search-form');
  let timeout = 500;

  $listContainer.find('.fa-sync-alt').click(() => $searchForm.submit());
  $listContainer.find('.js-mark-as-important').click(function() {
    const ids = $('input.js-checkbox-important:checked')
      .map(function() { return this.dataset.id; }).toArray().join(',');
    return $.ajax({
      type: 'PUT',
      url: this.dataset.path,
      data: {
        ids
      },
      success(data) {
        $('.orders-table-container').replaceWith(data.html);
        return listEvents($listContainer);
      }
    });});
  $searchForm.find('input[type=text]').on('change paste keyup', function() {
    const context = this;
    const term = $(context).val();
    clearTimeout(timeout);
    return timeout = setTimeout((() => clearPageAndSubmitForm($searchForm)), 500);
  });
  $(document).on('ajax:success', '.order-search-form', function(event, body) {
    $('.orders-table-container').replaceWith(body.html);
    return listEvents($listContainer);
  });
  $searchForm.find('.buttons button.apply').click(function(e) {
    e.preventDefault();
    clearPageAndSubmitForm($searchForm);
    return $listContainer.find('.modal').hide();
  });
  $searchForm.find('.filter-button').click(e => e.preventDefault());

  const paginationSelector = ".orders-list .orders-table-container.results .pagination a";
  new AjaxPaginator($searchForm, paginationSelector);

  return listEvents($listContainer);
});
