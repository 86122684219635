/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const paginationEvents = function() {
  const loadingHTML = "<div class='loading'>Loading...</div>";
  const paginationSelector = '.traderspace-page .listings .pagination a';
  const resultsSelector = '.traderspace-page .listings .listings-results .results .results-wrapper';
  const $form = $('form#listings-filter');
  return $(document.body).on('click', paginationSelector, function(e) {
    e.preventDefault();
    e.stopPropagation();
    const href = $(this).attr('href');
    // page = (new QueryExtractor(href)).get('page')
    const page = (new QueryExtractor(href, 'page')).get();
    $form.find('input#page').val(page);
    return $form.submit();
  });
};

const formEvents = () => $(document.body).on('ajax:success', 'form#listings-filter', (e, response) => $('.listings-results').html(response.results));


$(document).on('turbolinks:load', function() {
  const $container = $('.traderspace-page');
  if (!$container.length) { return; }

  const $filter = $container.find('#listings-filter');
  const priceModal = new PriceModal($filter);
  new ButtonSwitchEvents($('.traderspace-page .listings'), 'favorite');

  const leftArrow = $container.find('.images-carousel').data('left');
  const rightArrow = $container.find('.images-carousel').data('right');
  $container.find('.images-carousel:not(.js-without-images-carousel) .images').owlCarousel({
    loop: true,
    nav: true,
    items: 1,
    touchDrag: false,
    navText: [leftArrow, rightArrow]
  });

  paginationEvents();
  return formEvents();
});