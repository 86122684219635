/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let PREVENTED_INPUTS = undefined;
  let NUMERIC_INPUTS = undefined;
  const Cls = (window.InputLimitations = class InputLimitations {
    static initClass() {
      //### CHARACTERS -> [*, +, `,`, -, =, E, e] ###
      PREVENTED_INPUTS = [42, 43, 44, 45, 61, 69, 101];
  
      /* CHARACTERS -> [0, 1, 2, 3, 4, 5, 6, 7, 8, 9] */
      NUMERIC_INPUTS = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
    }

    constructor($inputs, numeric) {
      this.$inputs = $inputs;
      if (numeric) {
        this.onlyNumericOptions();
      } else {
        this.limitInputOptions();
      }
    }

    limitInputOptions() {
      return this.$inputs.on('keypress', function(e) {
        if (PREVENTED_INPUTS.includes(e.which)) {
          return event.preventDefault();
        }
      });
    }

    onlyNumericOptions() {
      return this.$inputs.on('keypress', function(e) {
        let disabled = [];
        if ($(this).attr('type') === 'tel') {
          disabled = NUMERIC_INPUTS.concat(43); // +
        }
        if ($(this).attr('type') === 'number') {
          disabled = NUMERIC_INPUTS.concat(46); // .
        }
        if (!disabled.includes(e.which)) {
          return event.preventDefault();
        }
      });
    }
  });
  Cls.initClass();
  return Cls;
})();
