/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let initDatepicker = undefined;
  const Cls = (window.DaterangePicker = class DaterangePicker {
    static initClass() {
      this.prototype.FORMAT = 'YYYY-MM-DD';
  
      initDatepicker = function($datepickerField) {
        this.$datepickerField = $datepickerField;
        return $datepickerField.daterangepicker({
          autoUpdateInput: false,
          minDate: new Date(),
          locale: {
            format: 'YYYY-MM-DD'
          }
        });
      };
    }

    constructor($container) {
      this.$container = $container;
      this.$fromInput = this.$container.find('.from-date-input');
      this.$toInput = this.$container.find('.to-date-input');
      this.$datepickerField = this.$container.find('input[name="datefilter"]');
      if (!(this.$datepickerField.length > 0)) {
        this.$datepickerField = this.$container.find('textarea[name="datefilter"]');
      }
    }

    build() {
      initDatepicker(this.$datepickerField);
      this.setEvents();
      return this.renderDate(this.$fromInput.val(), this.$toInput.val());
    }

    renderDate(from_date, to_date) {
      if ((from_date !== '') || (to_date !== '')) {
        this.$datepickerField.val(from_date + '\n - \n' + to_date);
        this.$fromInput.val(from_date);
        return this.$toInput.val(to_date);
      }
    }

    afterSelect(f) {
      return this.$afterSelect = f;
    }

    callAfterSelect() {
      if (this.$afterSelect === undefined) { return; }
      return this.$afterSelect();
    }

    setEvents() {
      const objectVar = this;
      const format = this.FORMAT;
      this.$datepickerField.on('apply.daterangepicker', function(ev, picker) {
        objectVar.renderDate(picker.startDate.format(format),
                             picker.endDate.format(format));
        return objectVar.callAfterSelect();
      });

      return this.$datepickerField.on('cancel.daterangepicker', function(ev, picker) {
        return this.$container.val('');
      });
    }
  });
  Cls.initClass();
  return Cls;
})();
