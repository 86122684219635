/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let updateCounter = undefined;
  let errorCallback = undefined;
  let successCallback = undefined;
  let updateTraderSpaceFavoriteCounter = undefined;
  const Cls = (window.ButtonSwitchEvents = class ButtonSwitchEvents {
    static initClass() {
  
      updateCounter = function($container) {
        if (!$container.siblings('.js-love-this')) { return; }
        return $container.siblings('.js-love-this').children('.js-number').text($container.find('a').
          data('likesCounter'));
      };
  
      errorCallback = function(jqXHR) {
        if (jqXHR.status === 401) {
          return window.location = $('#signup').data('failUrl');
        } else {
          return toastr.error(jqXHR.responseJSON.message);
        }
      };
  
      successCallback = function(type, $container, data) {
        const partial = data.html ? data.html : data;
        $container.html(partial);
        if (type === 'favorite-traderspace') { return updateTraderSpaceFavoriteCounter($container); }
        return updateCounter($container);
      };
  
      updateTraderSpaceFavoriteCounter = function($container) {
        const amount = $container.find('a').data('favorites');
        return $container.parent().find('.saved-times').html(amount);
      };
    }
    constructor($container, type) {
      this.$container = $container;
      this.type = type;
      this.init();
    }

    init() {
      const {
        type
      } = this;
      const {
        $container
      } = this;

      this.$container.on('ajax:success', `.remove-${this.type}`, (event, html) => successCallback(type, $container, html));

      this.$container.on('ajax:success', `.add-${this.type}`, (event, html) => successCallback(type, $container, html));

      this.$container.on('ajax:error', `.remove-${this.type}`, (event, jqXHR) => errorCallback(jqXHR));

      this.$container.on('ajax:error', `.add-${this.type}`, function(event, jqXHR) {
        if ($(this).attr('disabled')) { $(this).removeAttr('disabled'); }
        return errorCallback(jqXHR);
      });

      if (this.type === 'like') {
        this.$container.on('click', `.add-${this.type}`, function(event) {
          if ($(this).attr('disabled')) { return false; }
          event.preventDefault();
          $(this).submit();
          return $(this).attr('disabled', true);
        });
      }

      if (this.type === 'favorite') {
        this.$container.on('click', '.add-favorite, .remove-favorite', function(event) {
          event.preventDefault();
          return $.ajax({
            method: $(this).data('method'),
            url: $(this).data('url'),
            context: this,
            dataType: 'json',
            success(data, status, jqXHR) {
              return successCallback(type, $(this).parent(), data);
            },
            error(jqXHR, ex) {
              return errorCallback(jqXHR);
            }
          });
        });
        return this.$container.on('mouseenter', '.add-favorite', function() {
          return $(this).find('i').removeClass('far fa-heart').addClass('fas fa-heart');
      }).on('mouseleave', '.add-favorite', function() {
          return $(this).find('i').removeClass('fas fa-heart').addClass('far fa-heart');
        });
      }
    }
  });
  Cls.initClass();
  return Cls;
})();
