/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let checkForChange = undefined;
  const Cls = (window.Refresh = class Refresh {
    static initClass() {
  
      checkForChange = function(refreshObj) {
        if (refreshObj.currentlyChecking) { return; }
        refreshObj.currentlyChecking = true;
        return $.ajax({
          dataType: 'json',
          type: 'GET',
          url: refreshObj.$refreshButton.data('checkUrl'),
          data: {
            change_since: refreshObj.sinceTime
          },
          success(data) {
            refreshObj.currentlyChecking = false;
            refreshObj.sinceTime = data.new_time;
            refreshObj.$refreshButton.data('initDate', refreshObj.sinceTime);
            if (data.should_refresh) {
              return refreshObj.$refreshButton.trigger('click');
            }
          }
        });
      };
    }
    constructor($container) {
      this.$container = $container;
      this.$refreshButton = this.$container.find('.refresh');
      this.sinceTime = this.$refreshButton.data('initDate');
      this.currentlyChecking = false;
      this.init();
    }

    init() {
      this.stopAutomaticRefresh();
      this.startAutomaticRefresh();
      return this.refreshButtonEvents();
    }

    startAutomaticRefresh() {
      const refreshObj = this;
      return window.refreshHandler = setInterval(checkForChange, 10000, refreshObj);
    }

    stopAutomaticRefresh() {
      if (window.refreshHandler) {
        clearInterval(window.refreshHandler);
        return window.refreshHandler = null;
      }
    }

    refreshButtonEvents() {
      this.$refreshButton.on('click', function(e) {
        return $(this).addClass('disabled');
      });
      return this.$refreshButton.on('ajax:success', function(event, data) {
        return $(this).removeClass('disabled');
      });
    }
  });
  Cls.initClass();
  return Cls;
})();

$(document).on('turbolinks:load', function() {
  if (window.refreshHandler && ($('.refresh').length === 0)) {
    clearInterval(window.refreshHandler);
    return window.refreshHandler = null;
  }
});
