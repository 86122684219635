/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.SlotsPurchaseForm = class SlotsPurchaseForm {
  constructor($form) {
    this.$form = $form;
  }

  afterSuccess(callback) {
    return this.afterSuccess = callback;
  }

  run() {
    const inputLimitations = new InputLimitations(this.$form.find('input'), true);
    return this.submitListener();
  }

  send() {
    if (this.checkFieldsValues()) {
      return this.$form.submit();
    } else {
      return this.afterSuccess();
    }
  }

  availableEmptySlots(slotsType) {
    return this.$form.find('.value[data-slot="' + slotsType + '"]').text();
  }

  clearInputs() {
    return this.$form.find('input').val(null);
  }

  clearValidationsErrors() {
    return this.$form.find('.error').hide();
  }

  renderValidationsErrors(errors) {
    const form = this;
    return $.each(errors, function(key, value) {
      const $errorBox = form.$form.find('.error[data-slot="' + key + '"]');
      $errorBox.show();
      return $errorBox.text(value[0]);
    });
  }

  showNewElements(new_elements_hash) {
    const form = this;
    return $.each(new_elements_hash, function(key, value) {
      if ((value === null) || (value === 0)) { return; }
      const $value = form.$form.find('.value[data-slot="' + key + '"]');
      const currentValue = parseInt($value.text());
      if (isNaN(currentValue)) {
        return $value.text(value);
      } else {
        return $value.text(currentValue + value);
      }
    });
  }


  submitListener() {
    const form = this;
    this.$form.find('#slots-purchase-submit').on('click', function(e) {
      e.preventDefault();
      if ($(this).attr('disabled') === 'disabled') { return false; }
      $(this).attr('disabled', true);
      $(this).find('.enabled').hide();
      $(this).find('.disabled').show();
      return form.$form.submit();
    });

    this.$form.on('ajax:success', function(event, data) {
      if (data.new_elements_hash) {
        form.clearValidationsErrors();
        if (data.message) { toastr.success(data.message); }
        form.showNewElements(data.new_elements_hash);
        form.clearInputs();
        $('#slots-purchase-submit').attr('disabled', false);
        $('#slots-purchase-submit').find('.enabled').show();
        $('#slots-purchase-submit').find('.disabled').hide();
        return form.afterSuccess();
      }
    });


    return this.$form.on('ajax:error', function(event, jqXHR) {
      form.clearValidationsErrors();
      if (jqXHR.responseJSON.message) { toastr.error(jqXHR.responseJSON.message); }
      form.renderValidationsErrors(jqXHR.responseJSON.validations_errors);
      $('#slots-purchase-submit').attr('disabled', false);
      $('#slots-purchase-submit').find('.enabled').show();
      return $('#slots-purchase-submit').find('.disabled').hide();
    });
  }

  checkFieldsValues() {
    return _.some(this.$form.find("input[name^=slots_purchase]").not('input[type=hidden]'), function(input) {
      if ($(input).is(':checkbox')) {
        return input.checked;
      } else {
        return $(input).val() !== '';
      }
    });
  }
};
