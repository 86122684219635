/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.Tags = class Tags {
  constructor($container) {
    this.$container = $container;
    this.$tagsList = this.$container.find('.tags-list');
    this.$initialTagInput = this.$container.find('.current-tags');
    this.$tagsCount = this.$container.find('.number');
    this.$newTagInput = this.$container.find('.tag-input');
    this.tagNumber = 1;
    // @$tagsInputs = @$container.find('.current-tags')
    this.init();
  }

  init() {
    this.splitTagsInput();
    this.onAddTagClick();
    return this.onRemoveTagClick();
  }

  splitTagsInput() {
    const that = this;
    _.each(this.$initialTagInput.val().split('<<split>>'), (tag, index) => {
      const $tagInput = this.$initialTagInput.clone();
      $tagInput.val(tag);
      if (tag !== '') { $tagInput.attr('data-number', that.tagNumber); }
      that.incrementTagNumber();
      return this.$initialTagInput.parent().append($tagInput);
    });
    this.$initialTagInput.remove();
    return this.$tagsInputs = this.$container.find('.current-tags');
  }

  onAddTagClick() {
    return this.$container.find('.add-tag').on('click', event => {
      event.stopPropagation();
      event.preventDefault();
      if (this.$tagsCount.text() === '0') { return; }
      if (!(this.newTags = this.$newTagInput.val())) { return; }
      this.$newTagInput.val('');
      return (() => {
        const result = [];
        const object = this.newTags.split(',');
        for (let _idx in object) {
          this.newTag = object[_idx];
          if (this.canInsertNewTag()) {
            this.incrementTagNumber();
            this.insertNewTagBlock(event);
            this.insertNewTagInput();
            result.push(this.updateTagsCount(-1));
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    });
  }

  onRemoveTagClick() {
    const that = this;
    return this.$container.on('click', '.delete', event => {
      const $target = $(event.target);
      const tagNumber = $target.closest('.tag').find('.delete').data('number');
      that.$tagsInputs.parent().find(`input[data-number='${tagNumber}']`).remove();
      $target.closest('.tag').remove();
      this.$tagsInputs = this.$container.find('.current-tags');
      return this.updateTagsCount(1);
    });
  }

  insertNewTagBlock(event) {
    const $newTagBlock = $($(event.target).closest('.add-tag').find('.tag-html').html());
    this.$tagsList.prepend($newTagBlock);
    $newTagBlock.removeClass('hidden').find('.name').text(this.newTag);
    return $newTagBlock.find('.delete').attr('data-number', this.tagNumber);
  }

  insertNewTagInput() {
    const $newTagInput = this.$tagsInputs.first().clone();
    $newTagInput.val(this.newTag);
    $newTagInput.attr('data-number', this.tagNumber);
    this.$tagsInputs.parent().append($newTagInput);
    return this.$tagsInputs = this.$container.find('.current-tags');
  }

  updateTagsCount(variable) {
    const oldCount = parseInt(this.$tagsCount.text());
    return this.$tagsCount.text(oldCount + variable);
  }

  canInsertNewTag() {
    let needle;
    return (this.newTag.trim() !== '') && (parseInt(this.$tagsCount.text()) > 0) &&
      !((needle = this.newTag, Array.from(this.$tagsList.find('.tag .name').map(function() { return this.innerText; })).includes(needle)));
  }

  incrementTagNumber() {
    return this.tagNumber++;
  }
};
