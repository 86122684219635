/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.FormSearch = class FormSearch {
  constructor($container, $itemsContainer) {
    this.$container = $container;
    this.$itemContainer = $itemsContainer;
    this.$nameInput = this.$container.find('#listing-name-cont-input');
    this.$exchangeTypeInput = this.$container.find('#listing-exchange-type-input');
    this.searchEvents();
  }

  searchEvents() {
    const formSearch = this;
    this.$nameInput.on('keypress', function(e) {
      if (e.which === 13) {
        e.preventDefault();
        return formSearch.searchItemForms();
      }
    });

    return this.$exchangeTypeInput.on('change', e => formSearch.searchItemForms());
  }

  searchItemForms() {
    const formSearch = this;
    return $.ajax({
      url: this.$container.data('url'),
      dataType: 'json',
      data: {
        q: {
          name_cont: this.$nameInput.val(),
          exchange_type_id_eq: this.$exchangeTypeInput.val()
        }
      },
      success(data){
        formSearch.$itemContainer.find('.nested-fields.items').hide();
        _.each(data.ids, id => formSearch.$itemContainer.find(`[data-id='${id}']`).show());
        return formSearch.$itemContainer.find('.nested-fields.items').not("[data-id]").show();
      }
    });
  }
};
