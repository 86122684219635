/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let DATE_FORMAT = undefined;
  const Cls = (window.ItemRenderer = class ItemRenderer {
    static initClass() {
      DATE_FORMAT = 'DD/MM/YYYY';
    }

    constructor($container) {
      this.$container = $container;
      this.initModal();
    }

    initModal() {
      const that = this;
      return this.$container.on('show.bs.modal', function(e) {
        if ($(e.target).hasClass('share-modal')) { return; }
        that.$modalBody = $(this).find('.modal-dialog');
        $(this).find('.loader').removeClass('unactive');
        that.$modalBody.find('*').remove();
        if (e.relatedTarget) {
          that.renderClickItem(e);
          return;
        }
        const url = new URL(location);
        if (url.searchParams.has('modal') && url.searchParams.has('item_id') && url.searchParams.has('trader_space_id')) {
          that.renderLinkItem(url.searchParams);
          return;
        }
      });
    }

    renderClickItem(showEvent) {
      const that = this;
      return $.ajax({
        type: 'GET',
        url: $(showEvent.relatedTarget).data('itemShowUrl'),
        success(html) {
          return that.itemRenderedEvents(html, that.$modalBody);
        }
      });
    }

    renderLinkItem(params) {
      const trader_space_id = params.get('trader_space_id');
      const item_id = params.get('item_id');
      const fetch_url = this.find('.loader').data('itemUrl').replace('-1', trader_space_id).replace('-2', item_id);
      const that = this;
      return $.ajax({
        type: 'GET',
        url: fetch_url,
        data: {
          trader_space_id,
          id: item_id
        },
        success(html) {
          return that.itemRenderedEvents(html);
        }
      });
    }

    itemRenderedEvents(html) {
      this.$modalBody.find('*').remove();
      const $loader = this.$container.find('.loader');
      $loader.addClass('unactive');
      this.$modalBody.append(`<div class='modal-content'>${html}</div>`);
      this.$starsLinks = this.$modalBody.find('a.star');
      this.parallelSection = new ParallelSection(this.$modalBody.find('.reviews.tab-pane'));
      const that = this;
      this.parallelSection.adjustContainersWidth = function() {
        let amount;
        if ((amount = this.$container.find('.parallel-results .review-result').length) > 2) {
          const resultsWidth = (amount / 2) * this.$container.find('.parallel-results .review-result').width();
          this.$container.find('.parallel-results').width(resultsWidth);
        }
        const width = this.$resultsWrapperContainer.width();
        return this.$resultsContainer.width(width * this.$container.find('.parallel-results').length);
      };
      this.parallelSection.horizontalScroll.recalculateScrollbar = function() {
        this.resultsWrapperWidth = this.$resultsContainer.width();
        return this.resultsWidth = $(this.$container.find('.parallel-results')).width();
      };
      this.parallelSection.horizontalScroll.moveContent = function() {
        const newButtonPos = parseFloat(this.$slider[0].noUiSlider.get());
        return this.$resultsContainer.css({'left': (((this.resultsWrapperWidth - this.resultsWidth) * newButtonPos) / 100) + 'px'});
      };
      this.$modalBody.find('#reviews').on('shown.bs.tab', () => that.parallelSection.init());
      this.reviewsStarsSortEvents();
      this.initTradeDatepicker();
      this.modalBackdropHandle();
      this.faqQuestionsEvents();
      this.buyNowEvents();
      this.initOwlCarousels();
      this.shareEvents();
      this.reviewResponseEvents();
      this.faqAnswerToggle();
      return this.availabilityDates();
    }

    initLoveEvents() {
      return _.each(this.$modalBody.find('.love'), function(loveButton) {
        let likeEvents;
        return likeEvents = new ButtonSwitchEvents($(loveButton), 'like');
      });
    }

    reviewsStarsSortEvents() {
      const that = this;
      const $reviewsForm = this.$modalBody.find('#listing-reviews-search-form');
      this.$starsLinks.on('click', function(e) {
        e.preventDefault();
        $reviewsForm.find('.reviews-stars li').removeClass('clicked');
        $(this).closest('li').addClass('clicked');
        that.$modalBody.find('#stars-field').val($(this).data('starsNumber'));
        return $reviewsForm.submit();
      });

      return $reviewsForm.on('ajax:success', function(event, data) {
        $reviewsForm.parent().find('.results-wrapper').html(data.results);
        return that.initLoveEvents();
      });
    }

    initTradeDatepicker() {
      const $itemForm = this.$modalBody.find('.item-show-form');
      const $tradeDatePicker = $itemForm.find('.trade-datepicker');
      if ($tradeDatePicker.length > 0) {
        const dateRangePicker = new TradeDaterangePicker($tradeDatePicker);
        dateRangePicker.afterSelect(function() {
          if (!($itemForm.find('button').length > 0)) {
            return $itemForm.submit();
          }
        });
        return dateRangePicker.build();
      }
    }

    modalBackdropHandle() {
      return this.$modalBody.find('.share-modal').on('show.bs.modal', function(e) {
        $('.modal-backdrop').css('visibility', 'hidden');
        $('.modal.in').css('background-color', 'rgba(0,0,0,0.5)');
        return $(this).css('background-color', 'rgba(0,0,0,0.5)');
      });
    }

    faqQuestionsEvents() {
      return this.$modalBody.on('click', '.faq .row-one', function() {
        $(this).children('.fa-plus-square-o').toggle();
        $(this).children('.fa-minus-square-o').toggle();
        $(this).parent().parent().siblings('.answer').toggle();
      });
    }

    buyNowEvents() {
      const that = this;

      $(document).on('ajax:success', '.listing-modal .add-to-cart', function(_event, data) {
        if ($('.cart-modal').length) { return; }
        const $cartContainer = that.$modalBody.closest('.listing-modal').find('.cart-modal-container');
        const $pocket = $cartContainer.html(data.html).find('.cart-modal');
        $pocket.pocket('show');
        return that.cartEvents($pocket, $cartContainer);
      });

      return $(document).on('ajax:success', '.js-modify-cart-item', function(_event, data) {
        const $cartContainer = that.$modalBody.closest('.listing-modal').find('.cart-modal-container');
        const div = document.createElement('div');
        div.innerHTML = data.html;
        const html = $(div).find('.cart-modal')[0].firstElementChild.outerHTML;
        const $pocket = that.$modalBody.closest('.listing-modal').find('.cart-modal');
        $pocket.html(html);
        return that.cartEvents($pocket, $cartContainer);
      });
    }

    cartEvents($pocket, $cartContainer) {
      $pocket.find('button.close').click(() => this.closePocket($pocket, $cartContainer));
      $pocket.find('input[submit]').click(() => $pocket.pocket('toggle'));
      $pocket.on('hidden.bs.pocket', () => this.closePocket($pocket, $cartContainer));
      new CalendarPicker($('.cart-modal'), [this.updateDates.bind(this)]);
      $(document).on('ajax:success', '.js-add-to-cart-form', function(_event, data) {
        $(this).closest('.cart-modal-container').html('');
        return $(this).closest('.cart-modal').pocket('hide');
      });
      return $(document).on('ajax:success', '.js-buy-now-form', function(_event, data) {
        return window.location.href = this.dataset.redirectPath;
      });
    }

    closePocket($pocket, $cartContainer) {
      $pocket.pocket('hide');
      return $cartContainer.html('');
    }

    updateDates(startDate, endDate) {
      const that = this;
      const startDateParam = `&start_date=${encodeURIComponent(startDate)}`;
      const endDateParam = `&end_date=${encodeURIComponent(endDate)}`;
      const url = $('#calendar-picker').data('modificationUrl') + startDateParam + endDateParam;
      return $.ajax({
        type: 'GET',
        url,
        success(data) {
          const $pocket = that.$modalBody.closest('.listing-modal').find('.cart-modal');
          const $cartContainer = that.$modalBody.closest('.listing-modal').find('.cart-modal-container');
          const div = document.createElement('div');
          div.innerHTML = data.html;
          const html = $(div).find('.cart-modal')[0].firstElementChild.outerHTML;
          $pocket.html(html);
          return that.cartEvents($pocket, $cartContainer);
        }
      });
    }

    initOwlCarousels() {
      const left = this.$modalBody.find('.images-carousel').data('left');
      const right = this.$modalBody.find('.images-carousel').data('right');
      return this.$modalBody.find('.images-carousel').owlCarousel({
        loop: false,
        nav: false,
        dots: false,
        items: 1
      });
    }

    shareEvents() {
      const copyLink =
        new CopyLink(this.$modalBody.find('.copy-link'), this.$modalBody);
      return this.$modalBody.find('.messenger').on('click', () => FB.ui({
        method: 'send',
        link: window.location.href
      }));
    }


    reviewResponseEvents() {
      return this.$modalBody.find('.reviews .review-response .button').on('click', function() {
        return $(this).siblings('.popover-custom').toggleClass('hidden');
      });
    }

    faqAnswerToggle() {
      return this.$modalBody.find('.question-title').on('click', function() {
        $(this).siblings('.answer').toggleClass('visible');
        return $(this).find('i').toggleClass('fa-plus-square').toggleClass('fa-minus-square');
      });
    }

    availabilityDates() {
      const $calendarButton = this.$modalBody.find('#calendar-picker');
      const invalidDates = $calendarButton.data('value');
      return $calendarButton.daterangepicker({
        minDate: moment(),
        locale: { format: DATE_FORMAT },
        autoUpdateInput: false,
        timePicker: false,
        isInvalidDate(date) {
          if (!invalidDates || !invalidDates.length) { return false; }
          return _.some(invalidDates, invalidDate => (date >= invalidDate.start_date) && (date <= invalidDate.end_date));
        }
      });
    }
  });
  Cls.initClass();
  return Cls;
})();
