/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let DATE_FORMAT = undefined;
  const Cls = (window.Filter = class Filter {
    static initClass() {
      DATE_FORMAT = 'YYYY-MM-DD';
    }

    constructor(klass) {
      this.klass = klass;
      this.filterKlass = `.js-filter-${this.klass}`;
      this.hideFunctions = {
        modal() { return $('.js-filter-modal').modal('hide'); },
        pocket() { return $('.js-filter-pocket').pocket('hide'); }
      };
      this.$dateRangePicker = $(this.filterKlass).find('.date.datepicker');
      this.init();
    }

    init() {
      this.addHighlight();
      this.removeHighlight();
      this.saveInputs();
      this.closeEvents();
      return this.initDateRangePicker();
    }

    addHighlight() {
      const that = this;
      return $(document).on(`shown.bs.${this.klass}`, this.filterKlass, function() {
        $(this).siblings().addClass('highlight');
        return that.radioButtonsEvents($(this));
      });
    }

    removeHighlight() {
      return $(document).on(`hidden.bs.${this.klass}`, this.filterKlass, function() {
        return $(this).siblings().removeClass('highlight');
      });
    }

    closeEvents() {
      const that = this;
      $(`.${this.klass}-open`).click(function(event) {
        if (!$(event.target).closest(`.${that.klass}`).length) {
          return that.hideFunctions[that.klass]();
        }
      });
      return $(this.filterKlass).find('form').on('ajax:success', () => that.hideFunctions[that.klass]());
    }

    radioButtonsEvents($modal) {
      if ($modal.prop('id').indexOf('sort') > 0) {
        return $modal.find('.radio').on('click', function() {
          return $modal.find('.radio').not($(this)).find('input').prop('checked', false);
        });
      }
    }

    saveInputs() {
      return $(this.filterKlass).find('.apply-changes').on('click', function() {
        $(this).closest('form').find('input').each(function() {
          return $(this).data('selected', this.checked);
        });
        return $(this).closest('.modal').hide();
      });
    }

    initDateRangePicker() {
      const that = this;

      this.$dateRangePicker.daterangepicker({
        parentEl: '.date.datepicker',
        opens: 'center',
        minDate: new Date(),
        locale: {
          format: DATE_FORMAT
        }
      });

      this.$dateRangePicker.on('apply.daterangepicker', function(event, picker) {
        if ($(this).data('cleared')) {
          $(this).find('input.from-date-input').val('');
          $(this).find('input.to-date-input').val('');
        } else {
          const startDate = picker.startDate.format(DATE_FORMAT);
          $(this).find('input.from-date-input').val(startDate);
          const endDate = picker.endDate.format(DATE_FORMAT);
          $(this).find('input.to-date-input').val(endDate);
          $(this).data('cleared', false);
        }
        return picker.toggle();
      });

      this.$dateRangePicker.on('clear.daterangepicker', function(event, picker) {
        $(this).data('cleared', true);
        return that.$dateResult.html(that.datePlaceholder);
      });

      this.$dateRangePicker.on('showCalendar.daterangepicker', function(event, picker) {
        if (!$(this).data('initial')) { $(this).data('cleared', false); }
        return $(this).data('initial', false);
      });

      this.$dateRangePicker.on('show.daterangepicker', function(event, picker) {
        if ($(this).data('cleared')) { return $(this).data('daterangepicker').clickClear(); }
      });

      return this.$dateRangePicker.on('hide.daterangepicker', function(event, picker) {
        return $(this).data('initial', true);
      });
    }
  });
  Cls.initClass();
  return Cls;
})();