/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  $.getScript('https://connect.facebook.net/en_US/sdk.js', () => FB.init({
    appId: $('main').data('appId'),
    xfbml: true,
    status: true,
    version: 'v2.7'
  }));
  $.ajaxSetup({ cache: true });

  return $(document).on('click', '.share-button', function() {
    return $(this).siblings('.popover-custom').toggleClass('hidden');
  });
});
