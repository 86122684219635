/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let pushRange = undefined;
  let generateWhitelist = undefined;
  const Cls = (window.PreviewCreator = class PreviewCreator {
    static initClass() {
  
      pushRange = (arr, lowEnd, highEnd) => (() => {
        const result = [];
        while (lowEnd <= highEnd) {
          result.push(arr.push(lowEnd++));
        }
        return result;
      })();
  
      generateWhitelist = function() {
        const whitelist = [];
        whitelist.push(95); // _
        pushRange(whitelist, 44, 46); // , . -
        pushRange(whitelist, 48, 57); // 0 - 9
        pushRange(whitelist, 65, 90); // A - Z
        pushRange(whitelist, 97, 122); // a - z
        return whitelist;
      };
    }
    constructor($container) {
      this.$container = $container;
      this.$preview = this.$container.find('.preview');
      this.$input = this.$container.find('input');
      this.whitelist = generateWhitelist();
      this.init();
    }

    init() {
      this.fetchUsernamePart();
      this.previewReadyText = this.$preview.text();
      this.fetchTraderSpaceName();
      return this.previewUpdateEvents();
    }

    previewUpdateEvents() {
      const previewCreator = this;
      const {
        $preview
      } = this;
      const {
        previewReadyText
      } = this;
      this.$input.on('keypress', function(event) {
        if (!previewCreator.whitelist.includes(event.which)) {
          return event.preventDefault();
        }
      });

      return this.$input.on('input', function() {
        const address = $(this).val().toLowerCase();
        return $preview.text(previewReadyText + address);
      });
    }

    fetchUsernamePart() {
      return this.$preview.text(this.$preview.text() + this.$container.data('username') + '/');
    }

    fetchTraderSpaceName() {
      const blanks_regexp = new RegExp(' ', 'g');
      if (Number.isInteger(this.$container.data('webAddress'))) { return; }
      const name = this.$container.data('webAddress').toLowerCase().replace(blanks_regexp, '_');
      this.$input.val(name);
      return this.$preview.text(this.$preview.text() + name);
    }
  });
  Cls.initClass();
  return Cls;
})();
