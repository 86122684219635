/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const initChannel = function() {
  if (window.CartsChannelSubscribed) { return; }
  return App.messages = App.cable.subscriptions.create({ channel: 'CartsChannel' },
  {
    connected() {
      return window.CartsChannelSubscribed = true;
    },
    disconnected() {
      return window.CartsChannelSubscribed = false;
    },
    received(data) {
      return $('.navigation .nav-cart span')[0].innerHTML = data.message.cart_items_count;
    }
  });
};

$(document).on('turbolinks:load', () => initChannel());

$(document).on('show.bs.modal', () => initChannel());
