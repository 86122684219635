/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  /* Profile - showing/hiding user panel */
  if ($('.main-content .left-panel').length > 0) {
    $('.main-content .left-panel').on('click', '.show-panel.off', function() {
      $('.main-content .left-panel').css({transform: 'translateX(0)'});
      $(this).addClass('on').removeClass('off');
    });
    $('.main-content .left-panel').on('click', '.show-panel.on', function() {
      $('.main-content .left-panel').css({transform: 'translateX(-100%)'});
      $(this).addClass('off').removeClass('on');
    });

    /* profile page - displaying additional info when clicked on username/email/etc in trader information panel */
    $('.main-content .left-panel .tab-content li').on('click', function() {
      $(this).find('i').toggleClass('fa-minus-square-o').toggleClass('fa-plus-square-o');
      $(this).find('.info').slideToggle(250);
    });

    /* Profile - showing/hiding user stats */
  }
  if ($('#banner .user-numbers').length > 0) {
    $('#banner .user-numbers').on('click', '.show-stats.off', function() {
      $('#banner .user-numbers').css({transform: 'translate(0, -50%)'});
      $(this).addClass('on').removeClass('off');
    });
    return $('#banner .user-numbers').on('click', '.show-stats.on', function() {
      $('#banner .user-numbers').css({transform: 'translate(100%, -50%)'});
      $(this).addClass('off').removeClass('on');
    });
  }
});
