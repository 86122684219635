/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if (!$('.traderspace-page').length) { return; }
  return $('.listings .filter-modal').on('show.bs.modal', function() {
    const $resultsHeight = $('.listings-results').outerHeight();
    // $(this).css({ height: $resultsHeight + 35 })
    const $modalHeight = $(this).find('.modal-content').height();
    const $availableHeight = $('.language-and-currency-section').outerHeight() + $('footer').height() + 35; // 35 - results margin bottom
    if (($resultsHeight === 0) && ($modalHeight < $availableHeight)) {
      return $(this).find('.modal-content').addClass('set-height');
    } else {
      return $(this).find('.modal-content').removeClass('set-height');
    }
  });
});
