/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const clearPageAndSubmitForm = function($form) {
  $form.find('input#page').val(null);
  return $form.submit();
};

const filtersEvents = $filtersContainer => $filtersContainer.find('.switch').click(function(e) {
  const $target = $(this);
  if ($target.closest('.modal-body').find('input:checked').length) { return; }
  return e.preventDefault();
});

$(document).on('turbolinks:load', function() {
  const $container = $('.profile-feedback');
  if (!$container.length) { return; }

  const $searchForm = $container.find('form.feedback-search-form');
  const $feedbackFiltersSection = $('.feedback-filter-section');
  let timeout = 500;

  $container.find('.filter-reload').click(() => $searchForm.submit());
  $searchForm.find('input[type=text]').on('input', function() {
    const context = this;
    const term = $(context).val();
    clearTimeout(timeout);
    return timeout = setTimeout((() => clearPageAndSubmitForm($searchForm)), 500);
  });
  $searchForm.find('input[type=radio]').on('change', function() {
    return $searchForm.find('.feedback-sort-modal input[type=radio]')
               .not(`#${this.getAttribute('id')}`)
               .prop('checked', false);
  });
  $searchForm.find('.buttons button.apply').on('click', function(e) {
    e.preventDefault();
    clearPageAndSubmitForm($searchForm);
    return $container.find('.modal').hide();
  });
  $searchForm.find('.filter-button').click(e => e.preventDefault());

  const $starsContainer = $container.find('.reviews-stars-wrapper');
  $starsContainer.find('a.star').click(function() {
    $starsContainer.find('a.star').removeClass('clicked');
    $(this).addClass('clicked');
    $('input#stars-field').val(this.dataset.starsNumber);
    return $searchForm.submit();
  });
  $(document).on('ajax:success', '.new_follow', function(e, response) {
    return $(this).replaceWith(response.html);
  });
  $(document).on('ajax:success', '.js-unfollow', function(e, response) {
    return $(this).parent().replaceWith(response.html);
  });
  $container.on('ajax:success', '.new_conversation', (_event, response) => new ModalInvoker(response.html));

  $(document).on('ajax:success', '.feedback-search-form', function(e, response) {
    const $resultsContainer = $container.find('.feedbacks-container .results .results-wrapper');
    return $resultsContainer[0].innerHTML = response.results;
  });
  $(document).on('click', '.user-card-container', function(e) {
    if (this.dataset.reviewPath) { return e.preventDefault(); }
  });

  const paginationSelector = '.profile-feedback .results .results-wrapper .pagination a';
  new AjaxPaginator($searchForm, paginationSelector);

  $('.review-modal').modal('toggle');

  return filtersEvents($feedbackFiltersSection);
});
