/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.GoogleLocationAutocompleter = class GoogleLocationAutocompleter {
  constructor($input) {
    this.$input = $input;
    this.geocoder = new google.maps.Geocoder();
    this.init();
  }

  init() {
    const autocompleter = this;
    autocompleter.$input.autocomplete({
      appendTo: autocompleter.$input.parent(),
      source(request, response) {
        return autocompleter.geocoder.geocode({ 'address': request.term }, (results, status) => response($.map(results, item => ({
          label: item.formatted_address,
          value: item.formatted_address,
          geocode: item
        }))
        ));
      }
    });

    return autocompleter.$input.on('keydown', function(event) {
      if (event.which === 13) {
        return autocompleter.$input.autocomplete('disable');
      } else {
        return autocompleter.$input.autocomplete('enable');
      }
    });
  }
};
