/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('.ts-wizard').length) {
    const $resultsContainer = $('.ts-wizard').find('.wizard-results');
    return $('.ts-wizard').find('form').on('ajax:success', function(event, data) {
      $resultsContainer.find('div').not('.add-new-traderspace').remove();
      return $resultsContainer.append(data.results);
    });
  }
});
