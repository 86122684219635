/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.NumericInput = class NumericInput {
  constructor($container, max) {
    this.max = max;
    this.$container = $container;
    this.$input = this.$container.find('input');
    this.$plusButton = this.$container.find('.plus');
    this.$minusButton = this.$container.find('.minus');
    this.$amountElement = this.$container.find('.amount');
    this.amount = this.$input.val() || 0;
    this.init();
  }

  init() {
    this.onPlusButtonClicked();
    this.onMinusButtonClicked();
    this.updateInputWidth();
    this.onInput();
    return this.onKeyDown();
  }

  onPlusButtonClicked() {
    const that = this;
    return this.$plusButton.on('click', function() {
      if ((that.amount >= that.max) && (that.max !== null)) { return; }

      const newAmount = ++that.amount;
      return that.updateElementsAfterChange(newAmount);
    });
  }

  onMinusButtonClicked() {
    const that = this;
    return this.$minusButton.on('click', function() {
      if (!(that.amount > 0)) { return; }

      const newAmount = --that.amount;
      return that.updateElementsAfterChange(newAmount);
    });
  }

  updateElementsAfterChange(newAmount) {
    this.$input.val(newAmount);
    this.amount = newAmount;
    return this.updateInputWidth();
  }

  onInput() {
    const that = this;
    return this.$input.on('input', function() {
      const newAmount = that.$input.val() || 0;

      if ((parseInt(newAmount) > that.max) && (that.max !== null)) {
        that.$input.val(that.amount);
      } else {
        that.amount = newAmount;
      }

      return that.updateInputWidth();
    });
  }

  onKeyDown() {
    return this.$input.on('keydown', function(e) {
      if ((e.keyCode > 31) && ((e.keyCode < 48) || (e.keyCode > 57))) {
        return false;
      }

      return true;
    });
  }

  updateInputWidth() {
    const width = (() => { switch (false) {
      case !(this.amount >= 1000): return '90px';
      case !(this.amount >= 100): return '80px';
      case !(this.amount >= 10): return '70px';
      default: return '60px';
    } })();

    return this.$input.css('width', width);
  }
};
