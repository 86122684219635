/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {

  // Show Traderspace announcement text area
  if ($('#activate-announcement').length) {
    const input = $('.announcement');

    $("#activate-announcement").on('change', () => input.toggleClass('hidden'));

    const activated = $("#activate-announcement").is(':checked');
    if (activated) { input.removeClass('hidden'); } else { input.addClass('hidden'); }

    const $cnt = $('.js-ts-announcement-content');
    const counter = $cnt.characterCounter({
      max: $cnt.data('limit'),
      textarea: true
    });

    const keys = [8, 37, 38, 39, 40]; // backspace & up/right/down/left arrows
    $cnt.on('focus keydown', function(e) {
      if ($(this).val().length >= $(this).data('limit')) {
        if (!keys.includes(e.which)) {
          return e.preventDefault();
        }
      }
    });
  }

  // Number field
  // if $('.number-input').length
  //   $('.increment').on 'click', ->
  //     $('.js-more-listings')[0].stepUp(1)
  //   $('.decrement').on 'click', ->
  //     $('.js-more-listings')[0].stepDown(1)
  
  // need to be this way beacuse .setpUp()/.stepDown() isn't supported in ie
  if ($('.number-input').length) {
    $('.increment').on('click', function() {
      let $val = $('.js-more-listings').val();
      $val++;
      return $('.js-more-listings').val($val);
    });
    return $('.decrement').on('click', function() {
      let $val = $('.js-more-listings').val();
      if ($val <= 0) { return; }
      $val--;
      return $('.js-more-listings').val($val);
    });
  }
});
    
