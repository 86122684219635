/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('.traderspace-page').length > 0) {
    return _.each($('.favorite-trader-space'), function(favorite_button) {
      let trader_space_favorites;
      return trader_space_favorites = new ButtonSwitchEvents($(favorite_button), 'favorite-traderspace');
    });
  }
});
