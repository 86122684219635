/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const cartEvents = function($modal) {
  $modal.find('input[submit]').click(() => $modal.modal('hide'));
  // TODO fix the calendar picker in cart
  // new CalendarPicker($('.cart-modal'), [@updateDates.bind(this)])
  $(document).on('ajax:success', '.js-add-to-cart-form', function(_event, data) {
    return $(this).closest('.cart-modal').modal('hide');
  });
  return $(document).on('ajax:success', '.js-buy-now-form', function(_event, data) {
    return window.location.href = this.dataset.redirectPath;
  });
};

const closePocket = function($pocket, $cartContainer) {
  $pocket.pocket('hide');
  return $cartContainer.html('');
};

$(document).on('turbolinks:load', function() {
  const $container = $('.listing-page');
  if (!$container.length) { return; }

  $container.find('.add-to-cart').on('ajax:success', function(_event, data) {
    const modalInvoker = new ModalInvoker(data.html);
    const $modal = $(modalInvoker.modal);
    return cartEvents($modal);
  });

  $container.find('.add-to-cart').on('ajax:error', function(_event, data) {
    if (data.status === 401) { // unauthorized
      toastr.error(data.responseText);
      return $('#signin').modal('show');
    }
  });

  return $(document).on('ajax:success', '.js-modify-cart-item', function(_event, data) {
    const $modal = $('.cart-modal');
    $modal.html($(data.html)[0].firstElementChild.outerHTML);
    return cartEvents($modal);
  });
});
