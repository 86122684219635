/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.QueryExtractor = class QueryExtractor {
  constructor(href, attr) {
    // url = new URL('https://example.com' + href)
    // @params = new URLSearchParams(url.search.slice(1))

    // Stupid solution because of IE:
    // cut everything before page param
    let c;
    const a = href.slice(href.indexOf(attr));
    // check if there are other params and if so return only page param
    const b = a.indexOf('&');
    if (b !== -1) {
      c = a.slice(0, b);
    } else {
      c = a.slice(0, a.length);
    }
    // return only page number
    if (attr === 'page') {
      this.page = Number(c.slice(c.indexOf('=') + 1));
    } else {
      this.page = c.slice(c.indexOf('=') + 1);
    }
  }
  // get: (param) ->
  //   @params.get(param)
  get() {
    return this.page;
  }
};
