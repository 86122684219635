/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  if (!$('.payment-container')) { return; }
  return $(document).on('ajax:success', '.js-payment-button', function(_event, response) {
    const modalInvoker = new ModalInvoker(response.data);
    const $modal = $(modalInvoker.modal);
    modalEvents($modal);
    return pocketEvents($modal);
  });
});

var modalEvents = function($modal) {
  $modal.find('.js-copy-to-clipboard').click(function() {
    $(this).siblings('.js-text-to-copy').select();
    document.execCommand('copy');
    return window.getSelection().removeAllRanges();
  });
  $modal.find('i.fa.fas.fa-info-circle').click(() => $modal.find('.crypto-info-modal').pocket('show'));
  $modal.on('shown.bs.modal', () => $modal.find('.single-item-summary i').click(function(e) {
    e.stopImmediatePropagation();
    const $icon = $(this);
    if ($icon.hasClass('fa-plus-square')) {
      $icon.removeClass('fa-plus-square');
      $icon.addClass('fa-minus-square');
    } else {
      $icon.removeClass('fa-minus-square');
      $icon.addClass('fa-plus-square');
    }
    return $icon.siblings('.single-item-details-data').slideToggle();
  }));
  return $modal.find('form a').click(function(e) {
    e.preventDefault();
    $modal.find('input[name="order[cash_delivery_option]"]')[0].setAttribute(
      'value', this.parentElement.dataset.value
    );
    $modal.find('.dropdown.dropdown-helper span.placeholder span')[0].
      innerHTML = this.innerHTML;
    return $modal.find('form.js-cash-delivery-option').submit();
  });
};

var pocketEvents = function($modal) {
  const $pocket = $modal.find('.complete-payment-modal');
  $modal.on('ajax:success', '.payment-complete, .payment-confirmation', () => window.location.reload());
  return $pocket.find('.cancel-modal').click(() => location.reload());
};
