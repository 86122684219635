/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
(function() {
  let initScrollbar = undefined;
  const Cls = (window.NewConversation = class NewConversation {
    static initClass() {
  
      initScrollbar = function($container, $searchForm) {
        let infiniteScroll;
        return infiniteScroll = new InfiniteScroll($container.find('.scrollbar-dynamic'), $searchForm);
      };
    }
    constructor($container) {
      this.$container = $container;
      this.$usersContainer = this.$container.find('.results');
      this.$usersSearchForm = this.$container.find('#users-search-form');
      this.$userLinks = this.$container.find('.user-link');
      this.$recipientInput = this.$container.find('#recipient-input');
      this.$returnToMessages = this.$container.find('.return-to-messages');
      this.$newMessageForm = this.$container.find('.new-message-form');
      this.init();
    }

    init() {
      this.filterUsers();
      this.userSelection();
      this.controlEvents();
      this.$container.find('.user-link').first().trigger('click');
      return initScrollbar(this.$container, this.$usersSearchForm);
    }

    controlEvents() {
      const newConversation = this;
      this.$returnToMessages.on('ajax:success', function(event, data) {
        let conversationActionsForm;
        newConversation.$container.html(data.html);
        return conversationActionsForm =
            new ConversationsActionsForm(newConversation.$container, null);
      });
      this.$newMessageForm.on('ajax:success', function(event, data) {
        let tradeActions;
        newConversation.$container.html(data.html);
        return tradeActions = new TradeActions(newConversation.$container);
      });
      return this.$newMessageForm.on('ajax:error', (event, jqXHR) => toastr.error(jqXHR.responseJSON.error));
    }

    userSelection() {
      const newConversation = this;
      return this.$usersContainer.on('click', '.user-link', function() {
        newConversation.$container.find('a.user-link.active').
          toggleClass('active');
        $(this).toggleClass('active');
        return newConversation.$recipientInput.val($(this).data('userId'));
      });
    }

    filterUsers() {
      let newConversation = this;
      return this.$usersSearchForm.on('ajax:success', function(event, html) {
        newConversation.$usersContainer.html(html);
        return newConversation = new NewConversation(newConversation.$container);
      });
    }
  });
  Cls.initClass();
  return Cls;
})();
