/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  let $rightPanel;
  if ($('.traderspace-page').length > 0) {
    $rightPanel = $('.traderspace-page .about-owner');
    const $aboutTs = $('.traderspace-page .about-ts');
    const $defaultPosition = $rightPanel.offset().top;
    const panelPos = function() {
      if ($(window).width() > 1900) { return 90; } else { return 120; }
    };
    $(window).on('scroll', function() {
      const $currentPosition = $(this).scrollTop();
      if ($(window).width() > 1500) {
        if (($currentPosition + panelPos()) > $defaultPosition) {
          $rightPanel.addClass('fixed-menu');
          $aboutTs.css({ 'margin-right': 'auto' });
        } else {
          $rightPanel.removeClass('fixed-menu');
          $aboutTs.removeAttr('style');
        }

        // when user panel reach language and currency section its stops
        if (($currentPosition + $rightPanel.outerHeight() + $('nav').outerHeight()) >
        $('footer').position().top) {
          $rightPanel.css({
            position: 'absolute',
            top: '100%',
            right: '15px',
            transform: "translateY(-100%)"
          });
        } else {
          $rightPanel.removeAttr('style');
        }
      }

      if ($currentPosition >= ($('body').height() - $(window).height() - 89)) {
        return $('.mobile-panel-open').addClass('on-bottom');
      } else {
        return $('.mobile-panel-open').removeClass('on-bottom');
      }
    });
  }

  $('.traderspace-page .mobile-panel-open').on('click', function() {
    $rightPanel.addClass('onscreen');
    return $(this).hide();
  });

  return $('.traderspace-page .mobile-panel-close').on('click', function() {
    $rightPanel.removeClass('onscreen');
    return setTimeout((() => $('.mobile-panel-open').show()), 200);
  });
});
    