import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['button', 'label', 'loadingIcon']

  static classes = ['hidden']

  buttonClick() {
    this.labelTarget.classList.add(this.hiddenClass);
    this.loadingIconTarget.classList.remove(this.hiddenClass);
    this.buttonTarget.style.pointerEvents = 'none';
  }
}
