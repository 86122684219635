/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if (!$('.listing-page').length) { return; }
  // hover on main image
  $('.images-carousel').on('mouseenter', function() {
    return $(this).siblings('.items').find('.image').addClass('darker');
  });
  $('.images-carousel').on('mouseleave', function() {
    return $(this).siblings('.items').find('.image').removeClass('darker');
  });

  // hover on other image
  $('.items .image').on('mouseenter', function() {
    $(this).siblings('.image').addClass('darker');
    return $(this).parent().siblings('.images-carousel').addClass('darker');
  });
  $('.items .image').on('mouseleave', function() {
    $(this).siblings('.image').removeClass('darker');
    return $(this).parent().siblings('.images-carousel').removeClass('darker');
  });

  return baguetteBox.run('.js-baguettebox-preview', {
    captions(element) {
      return element.getElementsByTagName('img')[0].alt;
    }
  });
});
