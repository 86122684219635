/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const checkSubmitButton = function() {
  const active = $('.ts-wizard .payment-methods-icons .active').length > 0;
  return $('.js-basic-payments-next').attr('disabled', !active);
};

$(document).on('turbolinks:load', function() {
  if (!$('.ts-wizard .step-basics-payment').length) { return; }

  checkSubmitButton();
  const $paymentMethodsForms = $('#payment-methods-forms');

  // Show tips of active payment methods after refresh
  // $nestedFields = $paymentMethodsForms.find('.nested-fields')
  // for el in $nestedFields
  //   tip = $(el).find('.tip')
  //   $('.tips').append(tip)

  // Cocoon events
  $paymentMethodsForms.on('cocoon:before-insert', function(e, $addedElement) {
    const linkId = $addedElement.find('.field').data('linkId');
    const $element = $('.payment-methods-icons').find(`${linkId}`);
    if ($element.hasClass('active')) {
      return e.preventDefault();
    } else {
      $element.addClass('active');
      return $element.removeClass('disable');
    }
  });
      // tip = $addedElement.find('.tip')
      // $('.tips').append(tip)

  $paymentMethodsForms.on('cocoon:before-remove', function(e, $removedElement) {
    const linkId = $removedElement.find('.field').data('linkId');
    const $element = $('.payment-methods-icons').find(`${linkId}`);
    $element.removeClass('active');
    return $element.addClass('disable');
  });

    // tipId = "##{$removedElement.attr('id')}-tip"
    // $('.tips').find(tipId).remove()
  $paymentMethodsForms.on('cocoon:before-remove', e => checkSubmitButton());

  return $paymentMethodsForms.on('cocoon:after-insert', function(e, addedElement) {
    $('.dropdown-helper').each(function() {
      const dropdown = new InputDropDown($(this));
      return dropdown.run();
    });
    return checkSubmitButton();
  });
});
