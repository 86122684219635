/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const markAsCompleted = (statesElementsContainer, stateElementSelector) => statesElementsContainer.find(stateElementSelector).
                       removeClass('state-in-progress').
                       addClass('state-completed');

const markAsInProgrees = (statesElementsContainer, stateElementSelector) => statesElementsContainer.find(stateElementSelector).addClass('state-in-progress');

const removeOrderButtonsContainer = function(orderId) {
  const $orderButtonsContainer = $(`.js-order-buttons-container#order-${orderId}-buttons-container`);
  return $orderButtonsContainer.hide();
};

const showButton = (statesElementsContainer, stateElementSelector) => statesElementsContainer.find(stateElementSelector).removeClass('hidden');

const hideButton = (statesElementsContainer, stateElementSelector) => statesElementsContainer.find(stateElementSelector).addClass('hidden');

const initChannel = function() {
  if (!$('.order-process').length) { return; }
  if (window.OrdersChannelSubscribed) { return; }
  return App.messages = App.cable.subscriptions.create({ channel: 'OrdersChannel' },
  {
    connected() {
      return window.OrdersChannelSubscribed = true;
    },
    disconnected() {
      return window.OrdersChannelSubscribed = false;
    },
    received(data) {
      const orderId = data.order['id'];
      const $orderDeliveryStatusButtons = $(`#order-${orderId}-states-buttons`);
      const $orderDeliveryStatusIcons = $(`#order-${orderId}-states-icons`);
      if (data.order['state'] === 'order_payed') {
        markAsCompleted($orderDeliveryStatusButtons, '.js-marked-as-paid');
        markAsCompleted($orderDeliveryStatusButtons, '.js-mark-delivery-as-complete');
        showButton($orderDeliveryStatusButtons, '.js-mark-delivery-as-complete.js-active-button');
        hideButton($orderDeliveryStatusButtons, '.js-mark-delivery-as-complete.js-disabled-button');
        markAsCompleted($orderDeliveryStatusIcons, '.js-payed');
        markAsInProgrees($orderDeliveryStatusIcons, '.js-delivered');
        return removeOrderButtonsContainer(orderId);
      } else {
        markAsCompleted($orderDeliveryStatusButtons, '.js-marked-as-delivered');
        markAsCompleted($orderDeliveryStatusButtons, '.js-leave-feedback');
        showButton($orderDeliveryStatusButtons, '.js-leave-feedback.js-active-button');
        hideButton($orderDeliveryStatusButtons, '.js-leave-feedback.js-disabled-button');
        markAsCompleted($orderDeliveryStatusIcons, '.js-delivered');
        return markAsInProgrees($orderDeliveryStatusIcons, '.js-feedback-needed');
      }
    }
  });
};

$(document).on('turbolinks:load', () => initChannel());

$(document).on('show.bs.modal', () => initChannel());
