/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if (!$('.about-traderspace').length) { return; }

  return $('.buyer a').on('click', function(e) {
    e.preventDefault();
    return $('#search').click();
  });
});
