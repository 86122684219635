/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', () => $('.filters-area .filter-modal').on('shown.bs.modal', function() {
  const $resultsHeight = $('.results').outerHeight();
  $(this).css({ height: $resultsHeight, opacity: 0 });
  
  const $modalDialog = $(this).find('.modal-dialog');
  const $position = ($modalDialog.offset().top + $modalDialog.height()) - $('body').height();
  if ($position > 0) {
    $modalDialog.offset({ top: $modalDialog.offset().top - $position - 5 });
  }
  return $(this).css({ opacity: 1 });
}));
