/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const mark_as_read = () => $.ajax({
  type: 'GET',
  url: $('#notifications-bell').data('url'),
  success(data){
    return $('#notifications-bell').find('span').hide();
  }
});

$(document).on('turbolinks:load', function() {
  $('.profile-aside-nav .notifications ').on('click', () => mark_as_read());

  if (window.location.href.indexOf('notifications').length > 0) {
    mark_as_read();
  }

  if ($('.profile-notifications').length > 0) {
    const $mainContainer = $('.profile-notifications');
    const NotificationsFilterForm = new NormalFilterForm($mainContainer, 'notifications');
    NotificationsFilterForm.build();

    const $filterArea = $mainContainer.find('.filters-area');
    $filterArea.find('.radio').on('click', function() {
      return $filterArea.find('.radio').not($(this)).find('input').prop('checked', false);
    });
    $mainContainer.find('form#notifications-search-form').on('ajax:success', () => $mainContainer.find('.filter-modal').modal('hide'));

    return $mainContainer.find('form.notification-form').find('.notifications-setting').on('change', function() {
      return $(this).closest('form').submit();
    });
  }
});
