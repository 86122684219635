/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.ListMenu = class ListMenu {
  constructor($menu, type, mobile) {
    if (mobile == null) { mobile = false; }
    this.$menu = $menu;
    this.$form = this.$menu.find('form');
    this.$list = this.$menu.find(`.${type}`);
    this.$listHeader = this.$list.find('.list-header');
    this.currentOption = this.$list.data('current');
    this.mobile = mobile;
    this.init();
  }

  init() {
    this.selectCurrent();
    this.selectList();
    return this.changeListener();
  }

  selectCurrent() {
    this.$menu.find(`[data-value='${this.currentOption}']`).addClass('selected');
    return this.$list.find('input').val(this.currentOption);
  }

  selectList() {
    return this.$listHeader.on("click", function() {
      $(this).parent().addClass("open");
      return $(this).parent().siblings().removeClass("open");
    });
  }

  changeListener() {
    const that = this;
    return this.$list.on('click', 'li', function(e) {
      e.preventDefault();
      const $target = $(e.currentTarget);
      const $targetValue = $target.data('value');
      that.$list.find('input').val($targetValue);
      that.$list.find('li').removeClass('selected');
      $target.addClass('selected');
      if (that.mobile) { return that.$form.submit(); }
    });
  }
};
