document.addEventListener('turbolinks:load', () => {
  const cardNumberElement = document.querySelector('#card-number-element');

  if (cardNumberElement !== null) { setupStripe(); }
});

window.setupStripe = function setupStripe() {
  // stripe is defined globally via stripe_javascript_tag in head
  const elements = stripe.elements();
  const style = {
    base: {
      fontWeight: 400,
      fontSize: '18px',
      fontFamily: 'DM Sans, sans-serif',
      color: '#000',
    },
    invalid: {
      iconColor: '#f00',
      color: '#f00',
    }
  };
  const cardNumber = elements.create('cardNumber', { showIcon: true, style });
  const cardExpiry = elements.create('cardExpiry', { style });
  const cardCvc = elements.create('cardCvc', { style });
  cardNumber.mount('#card-number-element');
  cardExpiry.mount('#card-expiry-element');
  cardCvc.mount('#card-cvc-element');

  const displayError = document.getElementById('card-errors');

  cardNumber.addEventListener('change', (e) => {
    if (e.error) {
      displayError.textContent = e.error.message;
    } else {
      displayError.textContent = '';
    }
  });
  // TODO: when on stimulus add error listener to all card elements

  const form = document.querySelector('#payment-form');
  const paymentIntentId = form.dataset.paymentIntent;
  const setupIntentId = form.dataset.setupIntent;

  if (paymentIntentId) {
    if (form.dataset.status == 'requires_action') {
      stripe.confirmCardPayment(
        paymentIntentId, { setup_future_usage: 'off_session' }
      ).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message;
          form.querySelector('#card-details').classList.remove('d-none');
        } else {
          form.submit();
        }
      });
    }
  }

  form.addEventListener('submit', (e) => {
    e.preventDefault();

    const name = form.querySelector('#name-on-card').value;
    const data = {
      payment_method_data: {
        // when we pass cardNumber element instance here Stripe.js
        // automagically tokenizes all card fields together
        card: cardNumber,
        billing_details: {
          name,
        }
      }
    };

    if (paymentIntentId) {
      // user had to confirm his payment method during SCA
      let pay_btn = e.target.querySelector('.pay-btn');
      pay_btn.disabled = true;
      stripe.confirmCardPayment(paymentIntentId, {
        payment_method: data.payment_method_data,
        setup_future_usage: 'off_session',
        save_payment_method: true
      }).then((result) => {
        if (result.error) {
          pay_btn.disabled = false;
          displayError.textContent = result.error.message;
          form.querySelector('#card-details').classList.remove('d-none');
        } else {
          // possible handle assigning of default card data also in payments controller!
          // addHiddenField(form, 'payment_method_id', result.paymentMethod.id);
          form.submit();
        }
      });
    } else if (setupIntentId) {
      // user adds Credit card without subscribing
      let add_card = e.target.querySelector('.add-card');
      add_card.innerText = add_card.dataset.disabled;
      add_card.disabled = true;
      stripe.confirmCardSetup(setupIntentId, {
        payment_method: data.payment_method_data
      }).then((result) => {
        if (result.error) {
          let add_card = e.target.querySelector('.add-card');
          add_card.innerText = add_card.dataset.enabled;
          add_card.disabled = false;
          displayError.textContent = result.error.message;
        } else {
          addHiddenField(form, 'payment_method_id', result.setupIntent.payment_method);
          form.submit();
        }
      });
    } else {
      // subscribe
      let subscribe_btn = e.target.querySelector('.subscribe-btn');
      subscribe_btn.disabled = true;
      data.payment_method_data.type = 'card';
      stripe.createPaymentMethod(data.payment_method_data).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message;
          subscribe_btn.disabled = false;
        } else {
          addHiddenField(form, 'payment_method_id', result.paymentMethod.id);
          form.submit();
        }
      });
    }
  });
}

function addHiddenField(form, name, value) {
  const hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', name);
  hiddenInput.setAttribute('value', value);
  form.appendChild(hiddenInput);
}
