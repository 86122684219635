/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.InfiniteScroll = class InfiniteScroll {
  constructor($container, $formContainer) {
    this.$container = $container;
    this.$elementsContainer = this.$container.find('.results-wrapper');
    this.fetchUrl = this.$container.find('[data-fetch-url]').data('fetchUrl');
    if ($formContainer) { this.$form = $formContainer.find('form'); }
    this.init();
  }

  init() {
    const that = this;
    return this.$container.on('scroll', function() {
      if (this.scrollTop > ((this.scrollHeight - this.clientHeight) * 0.7)) {
        if (!that.$container.hasClass('wait') && (that.$container.find('.load-more').length > 0)) {
          that.$container.addClass('wait');
          return that.loadNextPage();
        }
      }
    });
  }

  loadNextPage() {
    const that = this;
    const dataTable = this.$form ? this.$form.serializeArray() : [];
    dataTable.push({ name: 'page', value: this.$container.find('.load-more').data('page') });

    return $.ajax({
      url: that.fetchUrl,
      data: dataTable,
      success(data){
        that.$container.find('.load-more').remove();
        const newElements = $(data.results).appendTo(that.$elementsContainer);
        that.bindLikeEvents(newElements);
        that.initListingsOwlCarousels();
        return that.$container.removeClass('wait');
      }
    });
  }

  bindLikeEvents(newElements) {
    return _.each(newElements.find('.love'), function(loveButton) {
      let likeEvents;
      return likeEvents = new ButtonSwitchEvents($(loveButton), 'like');
    });
  }

  initListingsOwlCarousels() {
    const left = this.$elementsContainer.find('.images-carousel').data('left');
    const right = this.$elementsContainer.find('.images-carousel').data('right');
    return this.$elementsContainer.find('.images-carousel .images').owlCarousel({
      loop: true,
      nav: true,
      navText: [left, right],
      items: 1
    });
  }
};
