/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.ListingsFilterForm = class ListingsFilterForm {
  constructor(traderSpaceId, exchangeType) {
    this.traderSpaceId = traderSpaceId;
    this.exchangeType = exchangeType;
    this.$modalDiv = $('.listings-modal-wrapper');
    this.init();
  }

  init() {
    return this.initialModalFetch();
  }

  initialModalFetch() {
    const filterForm = this;
    return $.ajax({
      url: filterForm.$modalDiv.data('fetch-url'),
      dataType: 'json',
      data: {
        search_form: {
          load_histogram: true,
          trader_space_id: filterForm.traderSpaceId,
          [filterForm.exchangeType]: true,
          listings: true,
          per: 3
        }
      },
      success(data) {
        filterForm.$modalDiv.html(data.html);
        filterForm.$modalDiv.children('.modal').modal();
        filterForm.$modalDiv.find('.listings-results').html(data.results);
        filterForm.$form = filterForm.$modalDiv.find('form#listings-filter');
        filterForm.initOwlCarousels();
        filterForm.$form.removeAttr('action');
        filterForm.formSubmitEvents();
        filterForm.$form.find('.price .modal-content').html(data.price_modal);
        filterForm.priceModal = new PriceModal(filterForm.$form);
        return filterForm.initializePaginator();
      }
    });
  }
        // filterForm.horizontalScroll =
        //   new HorizontalScroll(filterForm.$modalDiv.find('.listings-results'))
        // filterForm.$modalDiv.on 'shown.bs.modal', ->
        //   filterForm.horizontalScroll.recalculateScrollbar()

  initializePaginator() {
    const paginationSelector = '.listings-modal .pagination a';
    const $filterForm = $('.listings-modal .filters-form');
    return new AjaxPaginator($filterForm, paginationSelector);
  }

  formSubmitEvents() {
    const filterForm = this;
    return filterForm.$form.on('submit', function(e) {
      e.preventDefault();
      return filterForm.filter();
    });
  }

  filter() {
    const filterForm = this;
    const data = filterForm.$form.serializeArray();
    data.push({ name: 'search_form[trader_space_id]', value: filterForm.traderSpaceId });
    data.push({ name: `search_form[${filterForm.exchangeType}]`, value: true });
    data.push({ name: 'search_form[listings]', value: true });
    return $.ajax({
      url: filterForm.$modalDiv.data('fetch-url'),
      dataType: 'json',
      data,
      success(data) {
        filterForm.$modalDiv.find('.listings-results').html(data.results);
        filterForm.$form.find('.price .modal-content').html(data.price_modal);
        return filterForm.initializePaginator();
      }
    });
  }

  initOwlCarousels() {
    const left = this.$modalDiv.find('.images-carousel').data('left');
    const right = this.$modalDiv.find('.images-carousel').data('right');
    return this.$modalDiv.find('.listing-result .images').owlCarousel({
      loop: true,
      nav: true,
      navText: [left, right],
      items: 1
    });
  }
};
