/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('.step-2 .step-listing-calendar').length) {
    let calendar;
    return calendar = new CalendarPicker($('.step-listing-calendar-picker'));
  }
});