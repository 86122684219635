import { Controller } from '@hotwired/stimulus'

// User selects payment methods for new subscription
export default class extends Controller {
  static targets = [
    'paymentMethodInput', 'paymentMethod', 'newCardFormWrapper', 'existingCardFormWrapper',
    'submitButton'
  ];

  static classes = ['hidden', 'default'];

  selectedPaymentMethod(e) {
    this.paymentMethodInputTarget.value = e.currentTarget.dataset.id;
    Array.from(this.paymentMethodTargets).forEach(t => t.classList.remove(this.defaultClass));
    e.currentTarget.classList.add(this.defaultClass);
    this.submitButtonTarget.disabled = false;
  }

  useNewCard() {
    this.newCardFormWrapperTarget.classList.remove(this.hiddenClass);
    this.existingCardFormWrapperTarget.classList.add(this.hiddenClass);
  }
}
