/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  $(document).on('ajax:success', '.edit-profile', function(event, data) {
    toastr.success(data.message);
    $(this).parent().html(data.button_html);

    if ($('#social #social-wrapper').length > 0) {
      $('#social #social-wrapper #following .elements').html(data.followings_html);
      $('#social #social-wrapper #followers .elements').html(data.followers_html);
    }

    if ($('.public-profile').length > 0) {
      if (data.user_id === $('#banner .user-info').data('user-id')) {
        return $('.public-profile #social-followers .elements').html(data.followers_html);
      }
    }
  });

  return $(document).on('ajax:error', '.edit-profile', (event, jqXHR) => toastr.error(jqXHR.responseJSON.message));
});
