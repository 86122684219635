/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.FirefoxStylesHelper = class FirefoxStylesHelper {
  constructor() {
    this.browser = navigator.appCodeName;
    this.sys = navigator.platform;
    this.styles();
  }

  styles() {
    if ((this.browser === 'Mozilla') && (navigator.userAgent.indexOf('Firefox') !== -1) && ([
      'Mac68K',
      'MacPPC',
      'MacIntel'
    ].indexOf(this.sys) !== -1)) {
      return $('body').addClass('mozilla-firefox-mac');
    } else if ((this.browser === 'Mozilla') && (navigator.userAgent.indexOf('Firefox') !== -1)) {
      return $('body').addClass('mozilla-firefox');
    }
  }
};
