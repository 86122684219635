/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.ProgressBar = class ProgressBar {
  constructor($container) {
    this.$container = $container;
    this.$progresses = this.$container.find('.progress');
    this.init();
  }

  init() {
    return _.each(this.$progresses, function(progress) {
      const progressBar = $(progress).find('.progress-bar');
      return progressBar.css({'width': progressBar.data('value') + '%'});
    });
  }
};
