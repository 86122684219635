/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const initChannel = function() {
  if (!$('div.messenger').length) { return; }
  const chatroomId = $('div.messenger').data('conversationId');
  if (App['room' + chatroomId]) { return; }
  return App['room' + chatroomId] = App.cable.subscriptions.create({ channel: 'MessagesChannel', room: chatroomId },
  {
    connected() {
      return window.MessagesChannelSubscribed = true;
    },
    disconnected() {
      return window.MessagesChannelSubscribed = false;
    },
    received(data) {
      const msg = document.createElement('div');
      const $messageContainer = $("[data-conversation-id='" + this.chatroomId + "']");
      const chatContainer = $messageContainer.find('.chat')[0];
      const userId = $("[data-conversation-id='" + this.chatroomId + "']").data('userId');
      if (userId === data.sender_id) {
        msg.innerHTML = data.message.sender;
      } else if (userId === data.recipient_id) {
        msg.innerHTML = data.message.recipient;
      }
      if (data.user_id === userId) {
        _.each($(chatContainer).find('.new'), element => element.remove());
      }
      chatContainer.appendChild($(msg).find('.message-wrapper')[0]);
      chatContainer.scrollTop = chatContainer.scrollHeight;
      return $.ajax({
        type: 'PUT',
        url: $(chatContainer).find('.load-more').data('messageSeenUrl')
      });
    },
    setChatroomId(chatroomId) {
      return this.chatroomId = chatroomId;
    }
  });
};

const sendMessage = function() {
  if (!$('div.messenger').length) { return; }
  const $chat = $('div.messenger');
  const chatroomId = $chat.data('conversationId');
  if (!App['room' + chatroomId]) { return; }
  App['room' + chatroomId].setChatroomId(chatroomId);
  const $sendButton = $chat.find('input[type="submit"]');
  const $textField = $chat.find('#message_content');
  $textField.on('focus keydown', function(e) {
    if (($(this).val().trim() === '') && !e.shiftKey && (e.which === 13)) { // 13 = enter 
      return e.preventDefault();
    } else if (($(this).val().trim() !== '') && !e.shiftKey && (e.which === 13)) {
      e.preventDefault();
      $sendButton.trigger('click');
      return $textField.val('');
    }
  });
  return $sendButton.on('click', function(e) {
    e.preventDefault();
    $(this).closest('form').submit();
    return $textField.val('');
  });
};

$(document).on('turbolinks:load', function() {
  if (!$('.trader-messenger').length) { return; }

  initChannel();
  return sendMessage();
});

$(document).on('show.bs.modal', function() {
  initChannel();
  return sendMessage();
});
