/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('.ts-wizard .step-drop-off, .ts-wizard .step-on-site').length) {
    let numericInput;
    return numericInput = new NumericInput($('.ts-wizard .proximity'), 21000);
  }
});
