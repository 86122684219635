/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.FeaturedSection = class FeaturedSection {
  constructor($container) {
    this.$container = $container;
    this.$resultsContainer = this.$container.find('.results-wrapper');
    this.$fetchUrl = this.$container.find('.filters .active').data('fetch-url');
    this.$fetchPage = 1;
    this.$loadMore = this.$container.find('.load-more');
    this.$isFetching = true;
    this.init();
  }

  init() {
    const that = this;
    if (!(this.$fetchPage > 0)) { return; }
    this.fetchFeaturedElements();
    this.fetchNewElementsEvent();
    return this.changeFetchedElementsType();
  }

  fetchFeaturedElements() {
    const featuredSectionObj = this;
    if (!(featuredSectionObj.$fetchPage > 0)) { return; }
    const fullUrl = `${featuredSectionObj.$fetchUrl}/${featuredSectionObj.$fetchPage}`;
    featuredSectionObj.$container.find('.placeholder-wrapper').show();
    return $.ajax({
      type: 'GET',
      url: fullUrl,
      dataType: 'json',
      async: true,
      success(data) {
        featuredSectionObj.$fetchPage = data.next_page;
        featuredSectionObj.$resultsContainer.append(data.results);
        featuredSectionObj.$isFetching = false;
        return featuredSectionObj.$container.find('.placeholder-wrapper').hide();
      },
      error(data) {
        featuredSectionObj.$container.find('.placeholder-wrapper').hide();
        return console.log(`%c ${data.statusText} `, 'background: red; color: #fff; font-size: 16px;');
      }
    });
  }

  fetchNewElementsEvent() {
    const featuredSectionObj = this;
    let $prevPosition = 0;
    return $(window).on('scroll', function() {
      if (featuredSectionObj.$isFetching) { return; }
      const $currentPosition = $(window).scrollTop();
      const $position = $('body').height() - ($currentPosition + $(window).height());
      const $isScrollingDown = $currentPosition > $prevPosition;
      if ($isScrollingDown && ($position <= 60) &&
         (featuredSectionObj.$fetchPage > 0)) {
        featuredSectionObj.$isFetching = true;
        featuredSectionObj.fetchFeaturedElements();
      }

      return $prevPosition = $currentPosition;
    });
  }

  changeFetchedElementsType() {
    const featuredSectionObj = this;
    return this.$container.find('.filters a').on('click', function(e) {
      e.preventDefault();
      featuredSectionObj.$fetchPage = 1;
      featuredSectionObj.$fetchUrl = $(this).data('fetchUrl');
      $(this).parent().find('.active').removeClass('active');
      $(this).addClass('active');
      featuredSectionObj.$resultsContainer.children().remove();
      return featuredSectionObj.fetchFeaturedElements();
    });
  }
};
