/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const $container = $('.ts-wizard .create-content-wrapper');
  if (!$container.length) { return; }

  return new ButtonSwitchEvents($container, 'favorite');
});