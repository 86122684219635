/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.MobileItemRenderer = class MobileItemRenderer extends ItemRenderer {
  constructor($container) {
    super($container);
  }

  itemRenderedEvents(html) {
    this.$modalBody.find('*').remove();
    const $loader = this.$container.find('.loader');
    $loader.addClass('unactive');
    this.$modalBody.append(html);
    this.$starsLinks = this.$modalBody.find('a.star');
    this.infiniteScrollReview = new InfiniteScroll(
      this.$modalBody.find('.reviews.tab-pane'),
      this.$modalBody.find('.reviews.tab-pane')
    );
    this.infiniteScrollReview.loadNextPage();
    this.reviewsStarsSortEvents();
    this.initTradeDatepicker();
    this.modalBackdropHandle();
    this.faqQuestionsEvents();
    this.buyNowEvents();
    this.initOwlCarousels();
    this.shareEvents();
    this.reviewResponseEvents();
    this.faqAnswerToggle();
    return this.availabilityDates();
  }

  initOwlCarousels() {
    const left = this.$modalBody.find('.images-carousel').data('left');
    const right = this.$modalBody.find('.images-carousel').data('right');

    this.$modalBody.find('.images-carousel:not(.js-without-images-carousel)').owlCarousel({
      loop: true,
      nav: true,
      navText: [left, right],
      items: 1
    });
    return this.$modalBody.find('.images-carousel.js-without-images-carousel').owlCarousel({
      loop: false,
      nav: false,
      dots: false,
      items: 1
    });
  }
};
