/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if ($('.ts-wizard .step-inventory').length) {
    const $container = $('.ts-wizard .step-inventory');
    const numericInput = new NumericInput($container.find('.step-inventory-discount'), 100);
    const dropdown = new InputDropDown($('.ts-wizard .step-inventory').find('.dropdown'));
    dropdown.run();
    return $container.find('.step-inventory-payment-options .payment-options img').click(function(e) {
      const {
        id
      } = this.dataset;
      const $element = $(this);
      if ($element.hasClass('active')) {
        $container.find(`.nested-fields[data-parent-id='${id}'] a.remove_fields`).click();
        return $element.removeClass('active').addClass('inactive');
      } else {
        $element.siblings('a.add_fields').click();
        return $element.removeClass('inactive').addClass('active');
      }
    });
  }
});
