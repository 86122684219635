/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.PriceModal = class PriceModal {
  constructor($form) {
    this.$form = $form;
    this.$slider = this.$form.find('.price .slider')[0];
    this.maxPrice = parseFloat($(this.$slider).data('max-price'));
    this.init();
  }

  init() {
    this.createSlider();
    this.sliderChangeEvent();
    this.setInputsMaxValue();
    return this.saveInputs();
  }

  createSlider() {
    let sliderFormat;
    const currentCurrency = $('#language-currency-modal .currencies').data('current');
    if (['BTC', 'ETH'].includes(currentCurrency)) {
      sliderFormat = wNumb({ decimals: 7 });
    } else {
      sliderFormat = wNumb({ decimals: 2 });
      this.maxPrice = parseFloat(this.maxPrice.toFixed(2));
    }
    return noUiSlider.create(this.$slider, {
      start: [0, this.maxPrice],
      connect: true,
      range: {
        'min': 0,
        'max': this.maxPrice || 10
      },
      format: sliderFormat
    });
  }

  sliderChangeEvent() {
    const priceModal = this;
    return this.$slider.noUiSlider.on('update', function(values, handle) {
      const value = values[handle];
      // 0 - left handle, 1 - right handle
      if (handle) {
        if (parseFloat(value) === priceModal.$slider.noUiSlider.options.range.max) {
          return priceModal.changePriceValues('.max-price', value + " +", '');
        } else {
          return priceModal.changePriceValues('.max-price', value);
        }
      } else {
        return priceModal.changePriceValues('.min-price', value);
      }
    });
  }

  changePriceValues(klass, textValue, inputValue) {
    if (inputValue == null) { inputValue = textValue; }
    this.$form.find(`.price ${klass}`).text(textValue);
    return this.$form.find(`.price input${klass}`).val(inputValue);
  }

  setInputsMaxValue() {
    return this.$form.find('.price-range input').attr({
      'max': this.maxPrice
    });
  }

  saveInputs() {
    const that = this;
    const $modal = this.$form.find('.price-modal');
    $modal.find('.apply-changes').on('click', function() {
      $modal.find('input[name=clicked_submit]').val($(this).attr('value'));
      return $modal.find('input').each(function() {
        return $(this).data('amount', $(this).val());
      });
    });
    
    $(document).on('hide.bs.modal', '.price-modal', function(e) {
      if (that.stopSlider === 1) {
        e.preventDefault();
        return that.stopSlider = 0;
      }
    });

    return $(document).on('show.bs.modal', '.price-modal', function() {
      const {
        maxPrice
      } = that;
      that.$slider.noUiSlider.set([0, maxPrice]);

      const prices = $.map($(this).find('input'), input => $(input).data('amount'));
      if (prices[1] === '') {
        prices[1] = maxPrice;
      } else if (prices[0] === '') {
        prices[0] = 0;
      }
      that.$slider.noUiSlider.set(prices);

      return that.$slider.noUiSlider.on('end', () => that.stopSlider = 1);
    });
  }

  clearInputs() {
    return this.$slider.noUiSlider.set([0, this.maxPrice]);
  }
};