/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const auth = (window.auth = {});

auth.loadForm = ($elem, $formContainer) => $.ajax({
  url: $elem.data('url'),
  element: $elem,
  success(data) {
    if (data.signed_in || data.sent) {
      return window.location.assign(data.redirect_to);
    } else {
      return $formContainer.html(data);
    }
  },
  error() {
    return $('.modal#invitation').hide();
  }
});

auth.popUp = function($elem) {
  const $formContainer = $elem.find('.devise-form');
  const formScope = `.modal#${$elem.attr('id')} form`;
  auth.loadForm($elem, $formContainer);
  $(document).on('ajax:success', formScope, function(event, body, xhr) {
    if (body['redirect_to']) {
      return window.location.href = body.redirect_to;
    } else {
      return $formContainer.html(body);
    }
  });
  return $(document).on('ajax:error', formScope, function(event, context) {
    if (context.responseJSON) {
      return $formContainer.html(context.responseJSON.html);
    } else {
      return toastr.error(context.responseText);
    }
  });
};

