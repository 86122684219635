/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  $('.community-rank-modal').on('shown.bs.modal', () => $.ajax({
    type: 'GET',
    url: $('#trader-space').data('rankingModal'),
    success(data) {
      $('.community-rank-modal .modal-body').append(data.html);
      const progressBar = new ProgressBar($('.community-rank-modal'));

      const $container = $('.community-rank-modal .badges-carousel');
      const left = $container.data('left');
      const right = $container.data('right');
      return $container.find('.owl-carousel').owlCarousel({
        loop: true,
        margin: 10,
        nav: true,
        navText: [left, right],
        dots: false,
        responsive: {
          1690: { items: 5
        },
          1200: { items: 4
        },
          1026: { items: 3
        }
        }
      });
    }
  }));

  return $('.community-rank-modal').on('hide.bs.modal', function() {
    return $(this).find('.modal-body').html('');
  });
});