/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const DASHBOARD_TABS = { 'dashboard': 1, 'conversations': 3, 'reviews': 4 };

  const activeDashboardTab = function(number) {
    $(`#dashboard .nav-tabs li:nth-child(${number})`).tab('show');
    $('#dashboard .tab-content .tab-pane.active').toggleClass('active');
    $(`#dashboard .tab-content .tab-pane:nth-child(${number})`).toggleClass('active');
    return false;
  };

  if ($('#dashboard').length > 0) {
    activeDashboardTab(DASHBOARD_TABS[$('#dashboard').data('controller')]);
  }

  $('a[data-toggle="tab"]').on('show.bs.tab', function(event) {
    const id = $(event.target).attr('href').substr(1);
    return window.location.hash = id;
  });

  return $('.nav-tabs a[href="' + window.location.hash + '"]').tab('show');
});
