/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
class TraderSpaceEditForm {
  constructor($container) {
    this.$container = $container;
  }

  run() {
    this.initPurchaseForm();
    this.setUpTraderSpaceForm();
    return this.initSubmitListener();
  }

  initFormElements() {
    this.previewTraderSpace = new PreviewCreator($('.trader-space-web-address'));
    this.traderSpaceWizardUploader = new FormImageUploader($('#edit-trader-space'));
    const stepTwoUploader = new FormImageUploader($('#edit-listings'));
    this.newItems = new ItemCreator($('.section-one'), $('.listings'), this);
    this.formSearch = new FormSearch($('.search'), $('.listings'));
    this.tsEditBannerMap = new BannerMap($('#trader-space-edit #ts-edit-banner'));
    if ($('.lending .nested-fields, .service .nested-fields')) {
      $('.nested-fields .item-datepicker').each(function() {
        const dateRangePicker = new DaterangePicker($(this));
        return dateRangePicker.build();
      });
    }
    this.$container.find('.trader-space-form-dropdown').each(function() {
      const dropdown = new InputDropDown($(this));
      return dropdown.run();
    });
    this.inputLimitations =
      new InputLimitations(this.$container
        .find('.nested-fields.items .listing-type .form-group input'), true);
    this.locationAutocompleter = new GoogleLocationAutocompleter($('#trader-space-location'));
    return this.initSubmitListener();
  }

  initMakeFeaturedButton($makeFeaturedContainer) {
    const traderSpaceEditForm = this;
    const $checkbox = $makeFeaturedContainer.find('.make-featured-checkbox');
    return $makeFeaturedContainer.find('.make-featured').on('click', function(e) {
      const featuredType = $(this).data('featuredType');
      const checkedLenght =
        traderSpaceEditForm.$container.find('.make-featured.checked[data-featured-type="' + featuredType + '"]').length;
      const availableEmptySlots =
        traderSpaceEditForm.slotsPurchaseForm.availableEmptySlots('featured_' + featuredType);
      if ((checkedLenght >= availableEmptySlots) && !$(this).hasClass('checked')) {
        toastr.error($(this).data('errorMessage'));
        return false;
      }
      $(this).toggleClass('checked');
      $checkbox.prop('checked', $(this).hasClass('checked'));
      return false;
    });
  }

  initPurchaseForm() {
    const traderSpaceEditForm = this;
    this.slotsPurchaseForm = new SlotsPurchaseForm($('#payments-subscriptions'));
    this.slotsPurchaseForm.afterSuccess(() => traderSpaceEditForm.sendForm());
    return this.slotsPurchaseForm.run();
  }

  rebuildTraderSpaceForms(forms) {
    this.$container.find('#edit-trader-space').html(forms.trader_space);
    this.$container.find('#edit-listings').html(forms.listings);
    this.$container.find('#notifications').html(forms.notifications);
    return this.setUpTraderSpaceForm();
  }

  setUpTraderSpaceForm() {
    const traderSpaceEditForm = this;
    this.$traderSpaceForm = $('#trader-space-form');
    this.initFormElements();
    return this.$container.find('.featured').each(function() {
      return traderSpaceEditForm.initMakeFeaturedButton($(this));
    });
  }

  initSubmitListener() {
    const traderSpaceEditForm = this;
    $('#submit-button').unbind('click');
    $('#submit-button').on('click', function() {
      $(this).attr('disabled', true);
      $(this).find('.enabled').hide();
      $(this).find('.disabled').show();
      return traderSpaceEditForm.slotsPurchaseForm.send();
    });

    this.$traderSpaceForm.unbind('ajax:success');
    this.$traderSpaceForm.on('ajax:success', function(event, data) {
      if (data.forms) {
        $('#submit-button').attr('disabled', false);
        $('#submit-button').find('.enabled').show();
        $('#submit-button').find('.disabled').hide();
        $('#live-mode a').attr('href', data.trader_space_href);
        toastr.success(data.message);
        return traderSpaceEditForm.rebuildTraderSpaceForms(data.forms);
      }
    });

    this.$traderSpaceForm.unbind('ajax:error');
    return this.$traderSpaceForm.on('ajax:error', function(event, jqXHR) {
      $('#submit-button').attr('disabled', false);
      $('#submit-button').find('.enabled').show();
      $('#submit-button').find('.disabled').hide();
      if (jqXHR.responseJSON.warning) { toastr.warning(jqXHR.responseJSON.warning); }
      toastr.error(jqXHR.responseJSON.message);
      return traderSpaceEditForm.rebuildTraderSpaceForms(jqXHR.responseJSON.forms);
    });
  }

  sendForm() {
    this.$container.find('#notifications-form :input').not(':submit').
      clone().hide().appendTo('#trader-space-form');
    this.$container.find('#listings-form :input').not(':submit').
      clone().hide().appendTo('#trader-space-form');
    return this.$traderSpaceForm.submit();
  }
}

$(document).on('turbolinks:load', function() {
  const $editFormContainer = $('#trader-space-edit-form');
  if ($editFormContainer.length > 0) {
    const traderSpaceEditForm = new TraderSpaceEditForm($editFormContainer);
    traderSpaceEditForm.run();
  }

  if ($('#trader-space-edit-form .faq').length > 0) {
    return $('#trader-space-edit-form .faq').on('click', '.remove_fields', function() {
      return $(this).closest('.nested-fields').hide();
    });
  }
});
