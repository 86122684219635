/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if (!$('.listing-page').length) { return; }
  const $listingBody = $('.listing-body');

  const left = $listingBody.find('.images-carousel').data('left');
  const right = $listingBody.find('.images-carousel').data('right');

  $listingBody.find('.images-carousel:not(.js-without-images-carousel)').owlCarousel({
    loop: true,
    nav: true,
    navText: [left, right],
    items: 1
  });
  return $listingBody.find('.images-carousel.js-without-images-carousel').owlCarousel({
    loop: false,
    nav: false,
    dots: false,
    navText: false,
    items: 1
  });
});
