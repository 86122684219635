var extend = function(child, parent) { for (var key in parent) { if (hasProp.call(parent, key)) child[key] = parent[key]; } function ctor() { this.constructor = child; } ctor.prototype = parent.prototype; child.prototype = new ctor(); child.__super__ = parent.prototype; return child; },
    hasProp = {}.hasOwnProperty;

window.SearchForm = (function(superClass) {
  var DATE_FORMAT, DEFAULT_ZOOM, SWITCHES_LENGTH;

  extend(SearchForm, superClass);

  DATE_FORMAT = 'YYYY-MM-DD';

  DEFAULT_ZOOM = 13;

  SWITCHES_LENGTH = {
    types: 2,
    shipping: 4,
    markets: 4
  };

  function SearchForm($mapContainer, navContainer, filterContainer, resultsContainer) {
    this.$navForm = $(navContainer).find('form');
    this.$filterForm = $(filterContainer).find('form');
    this.$mapContainer = $mapContainer;
    this.$resultsContainer = $(resultsContainer);
    this.$dateRangePicker = this.$filterForm.find('.date.datepicker');
    this.priceModal = new PriceModal(this.$filterForm);
    this.mapMoveCheckbox = $('#map-move-searching')
    SearchForm.__super__.constructor.call(this, this.$navForm);
  }

  SearchForm.prototype.init = function() {
    var $screen, that;
    this.initMap();
    this.unbindNavForm();
    this.aroundMeButton();
    this.typeSearchControl();
    this.mapMoveSearching();
    this.mapMovementListener();
    this.paginationHandler();
    this.formEvents();
    that = this;
    $screen = $(window).width();
    new Promise(function(resolve, reject) {
      var location, radius;
      location = that.$navForm.find('#location').val();
      if (location === void 0) {
        location = 'Australia';
        if ($screen > 1730) {
          radius = '500';
        } else if ($screen > 1200) {
          radius = '1000';
        } else {
          radius = '400';
        }
      }
      return that.geocodeLocation(that.$navForm, location, null, radius, that.mapMoveCheckbox.is(':checked')).then(function() {
        return resolve(that.searchRequest());
      });
    });
    this.initShowMapSlider();
    that = this;
    return SearchForm.__super__.init.call(this);
  };

  SearchForm.prototype.addNewMapMarkers = function(elements, isRadius) {
    if (isRadius == null) {
      isRadius = false;
    }
    this.map.setMarkers(elements);
    if (elements.length && !this.searchWithLocation && !this.map.map.isMoving) {
      this.map.fitMapResults(elements);
    }
    return this.cleanViewport();
  };

  SearchForm.prototype.initMap = function() {
    if (this.$mapContainer.length > 0) {
      this.map = new MapCreator(this.$mapContainer);
      return this.map.buildSearchMap();
    }
  };

  SearchForm.prototype.mapMoveSearching = function () {
    const that = this
    return this.mapMoveCheckbox.on('click', function(e, data) {
      that.map.movementAnalyzer(function(bounds, callback) {
        if (bounds === null || that.searchWithLocation) {
          return callback(that.map);
        }

        if (!that.mapMoveCheckbox.is(':checked') ) {
          that.map.map.isMoving = false;
          that.setNewSearchBounds(that.$navForm);
        } else {
          that.setNewSearchBounds(that.$navForm, bounds.getSouthWest().lat(), bounds.getSouthWest().lng(), bounds.getNorthEast().lat(), bounds.getNorthEast().lng());
        }
        that.resetPriceFilters();
        return that.search(callback);
      });
    });
  };

  SearchForm.prototype.mapMovementListener = function() {
    var that;
    if (this.map) {
      that = this;
      return this.map.onMovement(function(bounds, callback) {
        if (bounds === null || !that.mapMoveCheckbox.is(':checked') || that.searchWithLocation) {
          return callback(that.map);
        }
        that.setNewSearchBounds(that.$navForm, bounds.getSouthWest().lat(), bounds.getSouthWest().lng(), bounds.getNorthEast().lat(), bounds.getNorthEast().lng());
        that.resetPriceFilters();
        return that.search(callback);
      });
    }
  };

  SearchForm.prototype.resetPriceFilters = function() {
    this.$filterForm.find('input.max-price').val(null);
    this.$filterForm.find('input.max-price').text('');
    this.$filterForm.find('input.max-price').data('amount', '');
    this.$filterForm.find('input.min-price').val(null);
    this.$filterForm.find('input.min-price').text('');
    this.$filterForm.find('input.min-price').data('amount', '');
  }

  SearchForm.prototype.initShowMapSlider = function() {
    var that;
    that = this;
    return this.$filterForm.find('.show-map').on('change', function() {
      if ($(this).find('input').prop('checked')) {
        $('.map-section').show();
        $(this).closest('.search').find('.search-results').removeClass('no-map').addClass('with-map');
        return $('.images-carousel, .banner-section').removeAttr('style');
      } else {
        $('.map-section').hide();
        return $(this).closest('.search').find('.search-results').addClass('no-map').removeClass('with-map');
      }
    });
  };

  SearchForm.prototype.search = function(afterSuccess) {
    var that;
    that = this;
    if (this.$navForm.find('#location').val()) {
      return new Promise(function(resolve, reject) {
        return (that.geocodeLocation(that.$navForm, that.$navForm.find('#location').val(), null, that.mapMoveCheckbox.is(':checked'))).then(function() {
          return resolve(that.searchRequest(afterSuccess));
        });
      });
    } else {
      return this.searchRequest(afterSuccess);
    }
  };

  SearchForm.prototype.formEvents = function() {
    var that;
    that = this;
    return this.$filterForm.on('ajax:success', function(e, data) {
      var favorites, isRadius;
      if (data.invalid_csrf) {
        return window.location.replace(data.url);
      } else {
        that.$navForm.find('#search_form_owner').val('');
        that.$navForm.find('#search_form_traderspaces').val('');
        that.$navForm.find('#search_form_listings').val('');
        isRadius = !['', '50', '0km'].includes(that.$navForm.find('#search_form_radius').val());
        that.addNewMapMarkers(data.map_results, isRadius);
        that.$resultsContainer.find('.results-wrapper').html(data.results);
        if (data.histogram && data.paginated === 'false') {
          that.histogramSettings(data.histogram);
        }
        that.$filterForm.find('.price-modal').find('input[name=clicked_submit]').val('');
        if (that.afterSuccess !== void 0) {
          that.afterSuccess(that.map);
        }
        favorites = new ButtonSwitchEvents(that.$resultsContainer.find('.results-wrapper'), 'favourite');
        $(window).scrollTop(0);
        that.$filterForm.find('#paginated').val('false');
        return that.$filterForm.find('#page').val('');
      }
    });
  };

  SearchForm.prototype.histogramSettings = function(histogramData) {
    var $priceModal;
    if (histogramData.min === histogramData.max) {
      return;
    }
    $priceModal = this.$filterForm.find('.price-modal');
    this.priceModal.$slider.noUiSlider.destroy();
    $priceModal.find('.histogram-columns').html(histogramData.columns);
    $priceModal.find('input.max-price').data('amount', histogramData.max);
    $priceModal.find('input.max-price').prop('max', histogramData.max);
    $priceModal.find('span.max-price').html(histogramData.max);
    $priceModal.find('span.min-price').html(histogramData.min);
    $priceModal.find('input.min-price').data('amount', histogramData.min);
    $priceModal.find('input.min-price').prop('min', histogramData.min);
    $priceModal.find('.slider').data({
      maxPrice: histogramData.max
    });
    $priceModal.find('.average-price-value').html(histogramData.average);
    this.priceModal = new PriceModal(this.$filterForm);
    $priceModal.find('input.min-price').prop('value', histogramData.min);
    return $priceModal.find('input.max-price').prop('value', histogramData.max);
  };

  SearchForm.prototype.unbindNavForm = function() {
    return this.$navForm.removeAttr('action');
  };

  SearchForm.prototype.aroundMeButton = function() {
    var that;
    that = this;
    return this.$filterForm.siblings('.around-me').on('ajax:success', function(event, data) {
      var favorites;
      that.addNewMapMarkers(data.map_results);
      that.map.fitMapResults(data.map_results);
      that.$resultsContainer.find('.results-wrapper').html(data.results);
      that.parallelSection.horizontalScroll.recalculateScrollbar();
      that.initOwlCarousels();
      return favorites = new ButtonSwitchEvents(that.parallelSection.$container, 'favourite');
    });
  };

  SearchForm.prototype.initOwlCarousels = function() {
    var left, right;
    left = this.$resultsContainer.find('.images-carousel').data('left');
    right = this.$resultsContainer.find('.images-carousel').data('right');
    return this.$resultsContainer.find('.listing-result .images').owlCarousel({
      loop: true,
      nav: true,
      navText: [left, right],
      items: 1
    });
  };

  SearchForm.prototype.typeSearchControl = function() {
    this.$filterForm.find('#acc-types-listings').prop('checked', JSON.parse(this.$navForm.find('#search_form_listings').val()));
    this.$filterForm.find('#acc-types-traderspaces').prop('checked', JSON.parse(this.$navForm.find('#search_form_traderspaces').val()));
    this.$filterForm.find('#acc-types-listings').val(this.$navForm.find('#search_form_listings').val());
    this.$filterForm.find('#acc-types-traderspaces').val(this.$navForm.find('#search_form_traderspaces').val());
    return this.$filterForm.find('.filters-area input[type=checkbox]').not('#acc-types-trader_verified').on('click', function(event) {
      var $checkboxContainer, $checkboxes, containerKlass;
      $checkboxContainer = $(this).closest('.modal');
      containerKlass = $(this).closest('.modal').get(0).id;
      if ($checkboxContainer.find('.options input[type=checkbox]:checked').not('#acc-types-trader_verified').length > 0) {
        return;
      }
      $checkboxes = $checkboxContainer.not('.options input[type=checkbox]:checked').not('#acc-types-trader_verified');
      if ($checkboxes.length === SWITCHES_LENGTH[containerKlass.split('-')[0]]) {
        return $checkboxes.not(this).prop('checked', true);
      } else {
        return event.preventDefault();
      }
    });
  };

  SearchForm.prototype.cleanViewport = function() {
    var northEastLat, northEastLng, southWestLat, southWestLng;
    if (this.mapMoveCheckbox.is(':checked')) {
      this.$navForm.find('#location').val('');
    }
    southWestLat = southWestLng = northEastLat = northEastLng = null;
    this.setNewSearchBounds(this.$navForm, southWestLat, southWestLng, northEastLat, northEastLng);
    return this.searchWithLocation = false;
  };

  SearchForm.prototype.paginationHandler = function() {
    var paginationSelector;
    paginationSelector = '.results-section .search-results .pagination a';
    return new AjaxPaginator(this.$filterForm, paginationSelector);
  };

  SearchForm.prototype.searchRequest = function(afterSuccess) {
    this.$filterForm.find('#search_form_text').val(this.$navForm.find('#search_form_text').val());
    this.$filterForm.find('#search_form_location').val(this.$navForm.find('#location').val());
    this.$filterForm.find('#search_form_owner').val(this.$navForm.find('#search_form_owner').val());
    this.$filterForm.find('#search_form_radiu,.s').val(this.$navForm.find('#search_form_radius').val());
    this.$filterForm.find('#search_form_category').val(this.$navForm.find('#search_form_category').val());
    if (this.$filterForm.find('#paginated').val() === 'false' && this.$filterForm.find('.show-map').find('input').is(':checked')) {
      this.$filterForm.find('#south-west-lat').val(this.$navForm.find('#south-west-lat').val());
      this.$filterForm.find('#south-west-lng').val(this.$navForm.find('#south-west-lng').val());
      this.$filterForm.find('#north-east-lat').val(this.$navForm.find('#north-east-lat').val());
      this.$filterForm.find('#north-east-lng').val(this.$navForm.find('#north-east-lng').val());
    }
    this.afterSuccess = afterSuccess;
    return this.$filterForm.submit();
  };

  return SearchForm;

})(window.NavigationSearchForm);

// ---
// generated by coffee-script 1.9.2
