/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.CopyLink = class CopyLink {
  constructor($link, $container) {
    this.$link = $link;
    this.$container = $container[0];
    this.init();
  }

  init() {
    new Clipboard(`.${this.$link.attr('class')}`, {
      container: this.$container
    });
    return this.clickEvent();
  }

  clickEvent() {
    const copyLinkObj = this;
    return this.$link.on('click', function(e) {
      $(this).find('.copy-me').hide();
      $(this).find('.link-copied').show();
      setTimeout((function() {
        copyLinkObj.$link.find('.copy-me').show();
        return copyLinkObj.$link.find('.link-copied').hide();
      }), 5000);
      return e.preventDefault();
    });
  }
};
