/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(function() {
  if (!$('.trader-messenger').length) { return; }
  const textarea = $('.chat')[0];
  textarea.scrollTop = textarea.scrollHeight;
  return new MessagesBox($('.trader-messenger'));
});
