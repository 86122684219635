/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const $container = $('.listing-page');
  if ($('.js-see-all-reviews').length) {
    $('.js-see-all-reviews').click(function(e) {
      let $form;
      if ($(e.target).closest('form').length) {
        $form = $(e.target).closest('form');
        $form.find('.reviews-stars li').removeClass('clicked');
      } else {
        $form = $(e.target).parent('.reviews-stars-wrapper').siblings('form');
        $(e.target).siblings('.reviews-stars').find('li a').removeClass('clicked');
      }
      $form.find('input#stars-field').val(null);
      return $form.submit();
    });
  }
  if (!$container.length) { return; }

  const paginationSelector = '.listing-page .reviews .results .pagination a';

  const $reviewsForm = $container.find('#listing-reviews-search-form');
  $reviewsForm.find('.star').click(function(e) {
    e.preventDefault();
    $reviewsForm.find('input#page').val(1);
    $reviewsForm.find('.reviews-stars li').removeClass('clicked');
    $(this).closest('li').addClass('clicked');
    $reviewsForm.find('input#stars-field').val($(this).data('starsNumber'));
    return $reviewsForm.submit();
  });

  $reviewsForm.on('ajax:success', (_event, data) => $reviewsForm.parent().find('.results-wrapper').html(data.results));

  return new AjaxPaginator($reviewsForm, paginationSelector);
});
