/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
window.Area = class Area {
  constructor(address, map, bounds, $container) {
    this.map = map;
    this.$container = $container;
    this.address = address;
    this.bounds = bounds;
    this.geocoder = new google.maps.Geocoder();

    if (this.address['lat'] && this.address['lng']) {
      this.location = this.address;
      this.setMapForLocation();
    } else {
      this.codeAddress();
    }
  }

  codeAddress() {
    this.location = { lat: null, lng: null };
    const that = this;
    return this.geocoder.geocode({ 'address': this.address }, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        that.location.lat = results[0].geometry.location.lat();
        that.location.lng = results[0].geometry.location.lng();
        return that.setMapForLocation();
      } else {
        that.setMapWithoutAddress();
        return that.setLocationInputsValues(null, null);
      }
    });
  }

  addArea() {
    return this.area = new (google.maps.Circle)({
      strokeColor: '#0A7C88',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#8de0d9',
      fillOpacity: 0.4,
      map: this.map,
      radius: 550
    });
  }

  addMarker() {
    const icon = {
      url: this.$container.data('markerIcon'),
      scaledSize: new google.maps.Size(25, 33)
    };
    return this.marker = new (google.maps.Marker)({
      map: this.map,
      position: this.location,
      icon
    });
  }

  setLocationInputsValues(latitude, longitude) {
    $('#geocoder-latitude').val(latitude);
    return $('#geocoder-longitude').val(longitude);
  }

  setMapForLocation() {
    this.addMarker();
    this.addArea(this.map);
    this.area.bindTo('center', this.marker, 'position');
    this.map.setCenter(this.marker.position);
    this.map.setZoom(13);
    this.$container.parent().find('#info-box').hide();
    return this.setLocationInputsValues(this.location.lat, this.location.lng);
  }

  setMapWithoutAddress() {
    this.$container.parent().find('#info-box').show();
    this.map.setCenter({ lat: 50, lng: 50 });
    this.map.setZoom(7);
    return this.map.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true
    });
  }
};
