/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const onDateChange = function(startDate, endDate) {
  let url = $('#calendar-picker').data('modificationUrl');
  const startDateParam = `&start_date=${encodeURIComponent(startDate)}`;
  const endDateParam = `&end_date=${encodeURIComponent(endDate)}`;
  url = $('#calendar-picker').data('modificationUrl') + startDateParam + endDateParam;
  return $.ajax({
    type: 'PUT',
    url,
    success(data) {
      return replaceContentAndBindCalendar(data.html);
    }
  });
};

var replaceContentAndBindCalendar = function(html) {
  $('.cart-details').replaceWith(html);
  return bindCalendar();
};

const replaceEditAddressPath = function(path) {
  if (path.length) {
    $('.js-edit-address').removeClass('hidden');
    return $('.js-edit-address').attr('href', path);
  }
};

var bindCalendar = () => new CalendarPicker($('.cart'), [onDateChange]);

$(document).on('turbolinks:load', function() {
  const $cart = $('.cart');
  if (!$cart.length) { return; }
  $cart.find('form.cart_item').on('submit', function(e) {
    if ($(this).find('input[type=image]').hasClass('js-active')) { return e.preventDefault(); }
  });

  $(document).on('click', '.payment-delivery img', function() {
    $('.payment-delivery img').not(this).popover('hide');
    return $(this).popover('toggle');
  });

  $(document).on('shown.bs.popover', '.payment-type img, .delivery-type img', function() {
    $('form.cart_item input[type=image]').on('click', function(e) {
      if ($(this).hasClass('js-active')) { e.preventDefault(); }
      return $('.popover').popover('hide');
    });
    return $('form.cart_item').on('ajax:success', function(event, body) {
      if (body.has_no_pick_up) {
        $('.address-select').removeClass('hidden-address');
      } else {
        $('.address-select').addClass('hidden-address');
      }
      return $('.cart-details').replaceWith(body.html);
    });
  });

  $(document).on('ajax:success', '.add-item, .minus-item', (event, body) => replaceContentAndBindCalendar(body.html));

  $(document).on('ajax:success', '.js-address-action', function(_event, response) {
    const modalInvoker = new ModalInvoker(response.data);
    const $modal = $(modalInvoker.modal);
    listenModalEvents($modal);
    $modal.on('shown.bs.modal', () => initAutocomplete($modal));
    return bindCalendar();
  });

  $(document).on('ajax:error', '.js-address-action', (_event, response) => toastr.error(response.responseText));

  bindCalendar();

  return $(document).on('change', '#order_address_id', function(event) {
    const url = this.dataset.updateUrl;
    return $.ajax({
      type: 'GET',
      data: {
        address_id: this.value
      },
      url,
      success(data) {
        replaceContentAndBindCalendar(data.html);
        return replaceEditAddressPath(data.edit_address_path);
      }
    });
  });
});

var listenModalEvents = function($modal) {
  $modal.on('ajax:success', '.js-address-form', (_event, response) => location.reload());
  $modal.on('ajax:error', '.js-address-form', function(_event, response) {
    $modal[0].remove();
    const newModalInvoker = new ModalInvoker(response.responseJSON.data);
    const $newModal = $(newModalInvoker.modal);
    return listenModalEvents($newModal);
  });
  $modal.find('button.js-back-button').click(() => $modal[0].remove());
  return $modal.find('button.js-clear-button').click(() => $modal.find('form.js-address-form input, form.js-address-form select').not(':input[type=hidden]').val(''));
};

var initAutocomplete = function($container) {
  const streetAutocomplete = new google.maps.places.Autocomplete($container.find('#space-address')[0]);
  streetAutocomplete.addListener('place_changed', function() {
    const place = this.getPlace();
    const components = place.address_components;
    $('#space-country').val(findInAddress(components, 'country'));
    $('input#space-address').val(findInAddress(components, 'route') + ' ' + findInAddress(components, 'street_number'));
    $('input#space-town').val(findInAddress(components, 'locality'));
    $('input#space-state').val(findInAddress(components, 'administrative_area_level_1'));
    return $('input#space-post-code').val(findInAddress(components, 'postal_code'));
  });

  const townAutocomplete = new google.maps.places.Autocomplete(
      $container.find('#space-town')[0], { types: ['(cities)'] }
  );
  streetAutocomplete.addListener('place_changed', function() {
    const place = this.getPlace();
    const components = place.address_components;
    return $('input#space-town').val(findInAddress(components, 'locality'));
  });

  const stateAutocomplete = new google.maps.places.Autocomplete(
      $container.find('#space-state')[0], { types: ['(regions)'] }
  );
  return streetAutocomplete.addListener('place_changed', function() {
    const place = this.getPlace();
    const components = place.address_components;
    return $('input#space-state').val(findInAddress(components, 'administrative_area_level_1'));
  });
};

var findInAddress = function(address, type) {
  const found = _.find(address, component => Array.from(component.types).includes(type));
  if (found) { return found.long_name; } else { return ''; }
};
