/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// window.disableNavForm = ($form) ->
//   return false unless $form.hasClass('not-active')
//   $form.find('input, button').attr('disabled', true)
//   $form.css('opacity', '0.5')
//   return true
$(document).on('turbolinks:load', function() {
  const $container = $('.home');
  if (!$container.length) { return; }

  // disableNavForm($('.nav-search-form'))

  /* displaying choosen language/currency */
  $('.currency .dropdown-menu a').on('click', function(e) {
    e.preventDefault();
    const currency = $(this).text();
    $('.currency .btn span').html(currency);
  });
  $('.language .dropdown-menu a').on('click', function(e) {
    e.preventDefault();
    const language = $(this).text();
    $('.language .btn span').html(language);
  });

  if ($('body').data('modalType') !== '') {
    setTimeout(function() {
      $('#' + $('body').data('modalType')).modal('show');
      return $('body').data('modalType', '').removeAttr('data-modal-type');
    }
    , 500);
  }


  /* closing active modal when opening new one */
  const close = function() {
    $('.modal .close').trigger('click');
    $('.modal-backdrop').remove();
  };

  $('.modal').on('click', '.toggle-modal', function() {
    close();
    return $('body').addClass('open-modal');
  });

  // logo = ->
  //   if window.innerWidth < 1281
  //     $('.navbar-brand img').attr 'src', $('.navbar-brand').data('mobileLogo')
  //     $('.navbar-brand img').css { 'display': 'block' }
  //   else if window.innerWidth < 1691
  //     $('.navbar-brand img').attr 'src', $('.navbar-brand').data('logoMini')
  //     $('.navbar-brand img').css { 'display': 'block' }
  //   else
  //     $('.navbar-brand img').attr 'src', $('.navbar-brand').data('logo')
  //     $('.navbar-brand img').css { 'display': 'block' }
  //   return

  if ($('main').hasClass('home')) {
    // logo()
    if ($(window).width() >= 1024) { const featuredSection = new FeaturedSection($('.featured-items')); }
  }
    // $(window).on 'resize', ->
    //   logo()

  $('.featured-elements .find-out-more').on('click', function(e) {
    e.preventDefault();
    return $('html, body').animate({ scrollTop: $('#find-out-more').offset().top }, 350);
  });

  /* getting started section - toggling popups */
  $('#trades-and-messages-modal .step .button').on('click', function() {
    $('#trades-and-messages-modal .popover-custom').addClass('unvisible').removeClass('visible');
    return $(this).siblings('.popover-custom').addClass('visible').removeClass('unvisible');
  });

  $('.infinity-sign .step .button').on('click', function() {
    if ($(this).siblings('.popover-custom').hasClass('always-visible')) {
      $('.infinity-sign .popover-custom.visible').addClass('unvisible').removeClass('visible');
      return false;
    }
    $('.infinity-sign .popover-custom.visible').addClass('unvisible').removeClass('visible');
    return $(this).siblings('.popover-custom').addClass('visible').removeClass('unvisible');
  });

  /* LOCATION AUTOCOMPLETER */
  const locationAutocompleter =
    new GoogleLocationAutocompleter($('#nav-search-form #location'));

  const languageMenu = new ListMenu($('#language-currency-modal'), 'languages');
  const currencyMenu = new ListMenu($('#language-currency-modal'), 'currencies');

  let insideModal = false;
  $('body').on('mouseenter', '#language-currency-modal.in', () => insideModal = true);
  $('body').on('mouseleave', '#language-currency-modal.in', () => insideModal = false);
  $(document).on('click', '.modal-open', function() {
    if (!insideModal) {
      return $('#language-currency-modal').modal('hide');
    }
  });

  return;

  if ($('#reactivate-account')) {
    return $('#reactivate-account').modal({
      backdrop: 'static',
      keyboard: false
    });
  }
});

