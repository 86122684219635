/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  if (!$('.ts-wizard .step-2').length) { return; }

  const $container = $('.ts-wizard .step-2');
  const left = $container.find('.images-carousel').data('left');
  const right = $container.find('.images-carousel').data('right');
  return $container.find('.images-carousel .images').owlCarousel({
    loop: true,
    nav: true,
    touchDrag: false,
    navText: [left, right],
    items: 1
  });
});