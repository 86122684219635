/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
$(document).on('turbolinks:load', function() {
  const $chatButton = $('.trade-page .chat-button');
  const $mainContent = $('.trade-page .main-content');
  $chatButton.on('click', function() {
    $mainContent.addClass('main-content-visible');
    return $('body').css({
      height: '100vh',
      overflow: 'hidden'
    });
  });

  $mainContent.find('.close-chat').on('click', function() {
    $mainContent.removeClass('main-content-visible');
    return $('body').removeAttr('style');
  });

  const ua = navigator.userAgent;
  const iOS = /iPad|iPhone|iPod/.test(ua);
  let num = 90;
  if (iOS) {
    num = 105;
  }

  $(window).on('scroll', function() {
    if (!($(window).width() <= 480)) { return; }
    const $pos = $(this).scrollTop();
    if ($pos >= ($('body').height() - $(window).height() - num)) {
      return $chatButton.addClass('chat-button-on-bottom');
    } else {
      return $chatButton.removeClass('chat-button-on-bottom');
    }
  });

  return $(window).on('resize', function() {
    if ($(this).width() > 480) {
      return $chatButton.removeClass('chat-button-on-bottom');
    }
  });
});